import React, { Fragment, useEffect, useState } from 'react';
import axios from "axios";
import DigitalSignature from 'components/DigitalSignature';
import { ExampleWrapperSimple } from '../../layout-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import DigitalSignatureIconImage from 'assets/images/DigitalSignatureIconImage.png';
import UpdateIconImage from 'assets/images/UpdateIconImage.png';
import { PageTitle } from '../../layout-components';
import { useHistory } from "react-router-dom";

import {
    TableBody,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Tabs,
    Tab,
    Checkbox,
    Input,
    Modal,
    Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#3D4977",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

export default function SubmitNew(props) {
    const [values, setValues] = React.useState({
        instructionId: "",
        relevantEntityId: props.relevantEntityId != null ? props.relevantEntityId : "",
        fromMintingManagement: props.fromMintingManagement != null ? props.fromMintingManagement : false,
        collateralMovementId: "",
        vaultLocation: "",
        assets: [],
        approvers: [],
        collateralMovementState: props.collateralMovementState != null ? props.collateralMovementState : "SUPPLYCHAIN_WORKER_APPROVED",
        certification: null,
        employeeFullName: "",
        isOpenSignatureModal: false,
        signatureURL: null,
        proofImages: [],
        returnedLinksToProofImages: new Map(),

        // Validation
        showAssetsValidationErrorMessage: false,
        showEmployeeFullNameValidationErrorMessage: false,
        showSignatureValidationErrorMessage: false,
        showProofsValidationErrorMessage: false,
        showCertificationValidationErrorMessage: false,

        approvedAssets: [],

        chosenNftStatusesIds: props.chosenNftStatusesIds,
        chosenCollectionsIds: props.chosenCollectionsIds,
        chosenCollectionsIdsDisabled: props.chosenCollectionsIdsDisabled,
        chosenNftStatusIdsDisabled: props.chosenNftStatusIdsDisabled
    });

    const onChangeCheckbox = value => event => {
        let isContains = false;
        let approvedAssets = values.approvedAssets;

        for (let i = 0; i < approvedAssets.length; i++) {
            if (approvedAssets[i] == value) {
                approvedAssets.splice(i);
                isContains = true;
            }
        }

        if (!isContains) {
            approvedAssets.push(value);
        }

        setValues({ ...values, ['approvedAssets']: approvedAssets });
    }

    function isCheckboxChecked(value) {
        const approvedAssetsAsString = JSON.stringify(values.approvedAssets);
        setTimeout(() => {
            if (approvedAssetsAsString.includes(value)) {
                console.log(true); //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                return true;
            } else {
                console.log(false); //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                return false;
            }
        }, 900);
    }

    const handleChangeEmployeeFullName = event => {
        setValues({ ...values, ['employeeFullName']: event.target.value });
    }

    const openSignatureModal = () => {
        setValues({ ...values, ['isOpenSignatureModal']: true });
    };

    const closeSignatureModal = () => {
        setValues({ ...values, ['isOpenSignatureModal']: false });
    };

    const onChangeSignature = data => {
        setValues({ ...values, ['signatureURL']: data.signatureURL, ['isOpenSignatureModal']: !data.closeModal });
    }

    const updateProofImages = (e) => {
        let newArr = values.proofImages;
        newArr.push(e.target.files[0]);

        setValues({ ...values, ['proofImages']: newArr });
    }

    useEffect(() => {
        getDetails();
    }, []);
    async function getDetails() {
        const jwtToken = localStorage.getItem('jwtToken');
        const config = {
            headers: { Authorization: `Bearer ${jwtToken}` }
        };
        const URL = process.env.REACT_APP_SAFELOAN_API_URL + "/MintingAsset/GetMovementIdByNftId/" + values.relevantEntityId;
        const response = await axios.get(URL, config);

        const URL2 = process.env.REACT_APP_SAFELOAN_API_URL +
            '/LoanCollateralMovement/GetLoanCollateralMovementDetails/'
            + await response.data + "/" + values.relevantEntityId + "/" + "SUPPLYCHAIN_WORKER_APPROVED";

        axios.get(URL2, config)
            .then(res => {
                setValues({ ...values, ['assets']: res.data.collaterals, ['approvers']: res.data.approvers, ['collateralMovementId']: response.data, ['certification']: res.data.certification });
            }).catch(
                function (error) {
                    console.log("Error !!!!!!! " + error);
                    alert("Ops, something went wrote please try again");
                }
            )
    }

    let history = useHistory();
    async function submit() {
        let validationOk = isValidationOk();

        if (validationOk) {
            const URL = process.env.REACT_APP_SAFELOAN_API_URL + '/LoanCollateralMovement/updateCollateralMovementDetails';

            const jwtToken = localStorage.getItem('jwtToken');
            const config = {
                headers: { Authorization: `Bearer ${jwtToken}` }
            };

            let convertedFile;
            await urltoFile(values.signatureURL, 'hello.png', 'image/png')
                .then(function (file) {
                    convertedFile = file;
                    const formData = new FormData();
                    formData.append('movementId', values.collateralMovementId);
                    formData.append('loanId', values.relevantEntityId);
                    formData.append('employeeFullName', values.employeeFullName);
                    formData.append('signature', convertedFile);
                    Array.from(values.proofImages).forEach(image => {
                        formData.append('proofImages', image);
                    });
                    formData.append('collateralMovementState', values.collateralMovementState);
                    formData.append('certification', values.certification);

                    const dataForNextPage = {
                        chosenNftStatusesIds: values.chosenNftStatusesIds,
                        chosenCollectionsIds: values.chosenCollectionsIds,
                        chosenCollectionsIdsDisabled: values.chosenCollectionsIdsDisabled,
                        chosenNftStatusIdsDisabled: values.chosenNftStatusIdsDisabled
                    }

                    axios.post(URL, formData, config)
                        .then(res => {
                            setValues({ ...values, ['returnedLinksToProofImages']: res.data });
                            if (values.fromMintingManagement) {
                                history.push("/MintingManagement", dataForNextPage);
                                document.location.reload();
                            } else {
                                history.push("/MintingManagement");
                                document.location.reload();
                            }
                        }).catch(
                            function (error) {
                                console.log("Error !!!!!!! " + error);
                            }
                        )
                }).catch(
                    function (error) {
                        console.log(error);
                        alert("failed to update");
                    }
                );

            // sendRemind();
        } else {
            alert("Make sure all fields are filled out");
        }
    }

    function urltoFile(url, filename, mimeType) {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const updateCertificationImage = (e) => {
        alert("Certification uploaded!")
        setValues({ ...values, ['certification']: e.target.files[0] });
    }

    function isAddCertificationBlockHidden() {
        if (values.collateralMovementState === "PENDING_APPROVAL") {
            return false;
        } else {
            return true;
        }
    }

    function isValidationOk() {
        let showAssetsValidationErrorMessage = false;
        let showEmployeeFullNameValidationErrorMessage = false;
        const regex = new RegExp(/^[a-zA-Z\s0-9]+$/);

        let showSignatureValidationErrorMessage = false;
        let showProofsValidationErrorMessage = false;
        let showCertificationValidationErrorMessage = false;

        if (values.approvedAssets.length == values.assets.length && values.assets.length > 0) {
            showAssetsValidationErrorMessage = false;
        } else {
            showAssetsValidationErrorMessage = true;
        }

        if (values.employeeFullName.length > 5 && regex.test(values.employeeFullName)) {
            showEmployeeFullNameValidationErrorMessage = false;
        } else {
            showEmployeeFullNameValidationErrorMessage = true;
        }

        if (values.signatureURL != null) {
            showSignatureValidationErrorMessage = false;
        } else {
            showSignatureValidationErrorMessage = true;
        }

        if (values.proofImages.length > 0) {
            showProofsValidationErrorMessage = false;
        } else {
            showProofsValidationErrorMessage = true;
        }

        if (values.certification != null) {
            showCertificationValidationErrorMessage = false;
        } else if (isAddCertificationBlockHidden()) {
            showCertificationValidationErrorMessage = false;
        } else {
            showCertificationValidationErrorMessage = true;
        }

        setValues({
            ...values,
            ['showAssetsValidationErrorMessage']: showAssetsValidationErrorMessage,
            ['showEmployeeFullNameValidationErrorMessage']: showEmployeeFullNameValidationErrorMessage,
            ['showSignatureValidationErrorMessage']: showSignatureValidationErrorMessage,
            ['showProofsValidationErrorMessage']: showProofsValidationErrorMessage,
            ['showCertificationValidationErrorMessage']: showCertificationValidationErrorMessage
        });

        if (!showAssetsValidationErrorMessage &&
            !showEmployeeFullNameValidationErrorMessage &&
            !showSignatureValidationErrorMessage &&
            !showProofsValidationErrorMessage &&
            !showCertificationValidationErrorMessage) {
            return true;
        } else {
            return false;
        }
    }

    function isAddCertificationBlockHidden() {
        if (values.collateralMovementState === "PENDING_APPROVAL") {
            return false;
        } else {
            return true;
        }
    }

    return (
        <div>
            <ExampleWrapperSimple sectionHeading="Submit" headerColor="#3D4977" headerTextColor="white">
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <p><b>Instruction Id:</b> {values.instructionId != null ? values.instructionId : ""}</p>
                        <p><b>Relevant entity:</b> {values.relevantEntityId != null ? values.relevantEntityId : ""}</p>
                        <p><b>Vault Location:</b> {values.vaultLocation != null ? values.vaultLocation : ""}</p>
                    </Grid>
                    <Grid item xs={8}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Product Name</TableCell>
                                    <TableCell align="right">Vendor</TableCell>
                                    <TableCell align="right">Asset Id</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {values.assets != null ? values.assets.map(row => (
                                    <StyledTableRow >
                                        <TableCell>{row}</TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"><Checkbox checked={isCheckboxChecked(row)} onChange={onChangeCheckbox(row)}></Checkbox></TableCell>
                                    </StyledTableRow>
                                )) : ""}
                            </TableBody>
                        </Table>
                        <p hidden={!values.showAssetsValidationErrorMessage} style={{ color: "red" }}>You should have at least one asset! All assets should be approved!</p>
                    </Grid>
                </Grid>

                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <Input
                            style={{ width: "100%" }}
                            value={values.employeeFullName}
                            placeholder="Type full name to sign"
                            onChange={handleChangeEmployeeFullName}>
                        </Input>
                        <p hidden={!values.showEmployeeFullNameValidationErrorMessage} style={{ color: "red" }}>Name Should Be At Least 5 Characters! All characters should be latin!</p>
                        <p hidden={!values.showSignatureValidationErrorMessage} style={{ color: "red" }}>Please add a signiture</p>
                    </Grid>
                    <Grid item xs={2}>
                        <img style={{ cursor: "pointer" }} src={DigitalSignatureIconImage} onClick={openSignatureModal} />
                    </Grid>
                </Grid>

                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <p>Please upload the scan of collateral proof with serial number</p>
                        <p hidden={!values.showProofsValidationErrorMessage} style={{ color: "red" }}>You Should Load At Least One Proof, make sure that all file mame should be unique!</p>
                    </Grid>
                    <Grid item xs={2}>
                        <label for="file-input">
                            <img style={{ cursor: "pointer" }} src={UpdateIconImage} />
                        </label>
                        <input id="file-input" type="file" hidden={true} onChange={updateProofImages} />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            color="secondary"
                            size="large"
                            variant="contained"
                            onClick={submit}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>

                <Grid container spacing={4} hidden={isAddCertificationBlockHidden()}>
                    <Grid item xs={4}>
                        <p>Please load the certification of ownership</p>
                        <p hidden={!values.showCertificationValidationErrorMessage} style={{ color: "red" }}>please provide Certification of ownership</p>
                    </Grid>
                    <Grid item xs={2}>
                        <label for="file-input2">
                            <img style={{ cursor: "pointer" }} src={UpdateIconImage} />
                        </label>
                        <input id="file-input2" type="file" hidden={true} onChange={updateCertificationImage} />
                    </Grid>
                </Grid>

                <Grid container spacing={4} hidden={values.certification == null || Object.entries(values.certification).length == 0} style={{ marginTop: "2%" }}>
                    <Grid item xs={2}>
                        <h6>Certification</h6>
                    </Grid>
                    <Grid item xs={2}>
                        {values.certification != null ? Object.entries(values.certification).map(([key, value]) => (
                            <div>
                                <a onClick={() => window.open(value, "_blank")} style={{ color: "blue", cursor: "pointer" }}>{key}</a>
                            </div>
                        )) : ""}
                    </Grid>
                </Grid>

                <Grid hidden={values.approvers.length < 1} className='row' style={{ marginTop: "2%" }}>
                    <Grid item xs={6}>
                        {values.approvers != null ?
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                        <TableCell align="right">Approved By</TableCell>
                                        <TableCell align="right">Link To Image Proof</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {values.approvers != null ? values.approvers.map(row => (
                                        <StyledTableRow >
                                            <TableCell>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(row.operationDate)}</TableCell>
                                            <TableCell align="right">{row.collateralMovementState}</TableCell>
                                            <TableCell align="right">{row.approverFullName}</TableCell>
                                            <TableCell align="right">
                                                {row.linksToImageProfs != null ? Object.entries(row.linksToImageProfs).map(([key, value]) => (
                                                    <div>
                                                        <a onClick={() => window.open(value, "_blank")} style={{ color: "blue", cursor: "pointer" }}>{key}</a>
                                                    </div>
                                                )) : ""}
                                            </TableCell>
                                        </StyledTableRow>
                                    )) : ""}
                                </TableBody>
                            </Table>
                            : ""}
                    </Grid>
                </Grid>
            </ExampleWrapperSimple>

            <Modal
                open={values.isOpenSignatureModal}
                onClose={closeSignatureModal}
                style={{
                    //position: "absolute",
                    top: "30%",
                    left: "25%"
                }}
            >
                <DigitalSignature
                    onChange={onChangeSignature} />
            </Modal>
        </div>
    )
}