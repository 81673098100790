import React, { Fragment } from 'react';
import axios from "axios";
import { PageTitle } from '../../layout-components';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { ExampleWrapperSimple } from '../../layout-components';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';


import {
  Grid,
  Input,
  Card,
  Button,
  InputAdornment,
  TextField,
  Tabs,
  FormControl,
  Checkbox,
  FormHelperText,
  Box,
  Tab,
  CardContent,
  InputLabel,
  Container,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControlLabel,
  Typography,
  MenuItem
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import RegularTables1Example1 from '../../components/RegularTables1/RegularTables1Example1';
import { withStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '6px',
    '& > div': {
      maxWidth: 40,
      height: '4px',
      borderRadius: '25px',
      width: '100%',
      backgroundColor: '#000'
    }
  }
}
)(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: theme.palette.primary[900],
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1
    }
  }
}))(props => <Tab disableRipple {...props} />);

// TODO: necessary?
function createData(loanid, date, amount, period, colatteral, castodianLocation) {
  return { loanid, date, amount, period, colatteral, castodianLocation };
}
//TODO: this is temp remove once there will be partner user menegment.

const AssetAllocation = () => {


  const [values, setValues] = React.useState({
    guid: "",
    partnerAssetOwnerId: "",
    subAccountId: ""

  });
  const [valuesAssign, setAssignedValues] = React.useState({
    gui: "",
    partnerAssetOwnerId: "",
    subAccountId: ""

  });

  async function handleAssinedChangeChange(prop, newValue) {
    console.log('handleAssinedChangeChange, prop + newValue:' + prop + ' ' + newValue);
    setAssignedValues({ ...valuesAssign, [prop]: newValue });
  }


  async function handleChange(prop, newValue) {

    console.log('handleChange' + prop + ' ' + newValue);
   
    console.log('handleChange values' + values );
    var authArr = [];
    const user = authArr[0];
    const session = authArr[1];
    const token = authArr[2];

    console.log('AssetAllocation, user: ' + user + ', session: ' + session + ', token: ' + token);
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    
var subAccountId;
var partnerAssetOwnerId;
    if(prop ===  'subAccountId'){
      subAccountId = newValue;
      partnerAssetOwnerId =  values.partnerAssetOwnerId;
    }
    else  if(prop ===  'partnerAssetOwnerId'){
      partnerAssetOwnerId = newValue;
      subAccountId =  values.subAccountId;
    }

   
    
    axios.get(process.env.REACT_APP_SAFELOAN_API_URL+`/AssetOwner/GetAssignedAssets?partnerAssetOwnerId=` +partnerAssetOwnerId + `&subAccountId=` +subAccountId, config)
      .then(res => {
        if (res.data === null) {
          alert("Sorry, you are denied :(");
        }
        if (res.data !== null) {

          console.log('--------- res.data' + res.data);
          setsearchResults(res.data);
          console.log('res setSearcResults' + res.data);

        }
      }).catch(
        function (error) {
          console.log('Problem in GetAssignedAssets, handleChange');
          alert("Problem in GetAssignedAssets, handleChange");
        }
      )
      setValues({ ...values, [prop]: newValue });
  };
  const [optionsSubAccounts, setOptionsSubAccounts] = React.useState(null);
  const [optionsGlobalAsset, setOptionsGlobalAsset] = React.useState(null);
  const [optionsPartnerAssetOwner, setoptionsPartnerAssetOwner] = React.useState(null);

  const [searchResults, setsearchResults] = React.useState([]);


  useEffect(() => {
    var authArr = [];
    //authUserSessionAndToken(authArr);
    const user = authArr[0];
    const session = authArr[1];
    const token = authArr[2];

    console.log('AssetAllocation, user: ' + user + ', session: ' + session + ', token: ' + token);
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };


    axios.get(process.env.REACT_APP_SAFELOAN_API_URL+`/PartnerAdmin/GetPartnerAssetSubAccounts?partnerId=` + partnerId, config)
      .then(res => {
        if (res.data === null) {
          alert("Sorry, you are denied :(");
        }
        if (res.data !== null) {

          setOptionsSubAccounts(res.data);
          console.log('res GetPartnerAssetSubAccounts' + res.data);

        }
      }).catch(
        function (error) {
          console.log('Problem in GetPartnerAssetSubAccounts');
          alert("Problem in GetPartnerAssetSubAccounts");
        }
      )

    axios.get(process.env.REACT_APP_SAFELOAN_API_URL+'PartnerAdmin/GetPartnerGUAIs' , config)
      .then(res => {
        if (res.data === null) {
          alert("Sorry, you are denied :(");
        }
        if (res.data !== null) {

          setOptionsGlobalAsset(res.data);
          console.log('res optionsGlobalAsset' + res.data[0]);

        }
      }).catch(
        function (error) {
          console.log('Problem in GetPartnerGUAIs');
          alert("Problem in GetPartnerGUAIs");
        }
      )
    axios.get(process.env.REACT_APP_SAFELOAN_API_URL+'/PartnerAdmin/GetPartnerAssetOwnerIDs', config)
      .then(res => {
        if (res.data === null) {
          alert("Sorry, you are denied :(");
        }
        if (res.data !== null) {

          setoptionsPartnerAssetOwner(res.data);
          console.log('res PartnerAssetOwner:' + res.data);
          console.log('res PartnerAssetOwner. ' + res.data.partnerAssetOwnerId);
        }
      }).catch(
        function (error) {
          console.log('Problem in GetPartnerAssetSubAccounts');
          alert("Problem in getPartnerAssetOwnerIDs");
        }
      )

    axios.get(process.env.REACT_APP_SAFELOAN_API_URL+`/AssetOwner/GetAssignedAssets?partnerId=` + partnerId, config)
      .then(res => {
        if (res.data === null) {
          alert("Sorry, you are denied :(");
        }
        if (res.data !== null) {

          setsearchResults(res.data);
          console.log('res PartnerAssetOwner' + res.data);

        }
      }).catch(
        function (error) {
          console.log('Problem in GetAssignedAssets');
          alert("Problem in GetAssignedAssets");
        }
      )


  }, []);



  async function handleAssignment() {
   
    const token = localStorage.getItem('jwtToken');


    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    const partnerId = 'partnerID221'
    const data = {
      partnerId: partnerId,
      //TODO : partnerId: user.accountID,
      partnerAssetOwnerID: valuesAssign.partnerAssetOwnerId,
      gui: valuesAssign.gui,
      subAccount: valuesAssign.subAccountId


    }

    const formData = new FormData();
    formData.append('partnerId', partnerId);
    formData.append('partnerAssetOwnerId', valuesAssign.partnerAssetOwnerId);
    formData.append('gui', valuesAssign.gui);
    formData.append('subAccountId', valuesAssign.subAccountId);
    axios.post(process.env.REACT_APP_SAFELOAN_API_URL+`/PartnerAdmin/AssignAssets`, formData, config)
      .then(res => {
        if (res.data === null) {
          alert("Sorry, you are denied :(");
        }
        if (res.data !== null) {
          console.log('res.data !== null' + res.data);
          const pushData = {
            //   optionsSubAccounts: res.data,

          }

          // history.push("/AdditionalInformation", pushData);
        }
      }).catch(
        function (error) {
          console.log('Problem in assigning assets');
          alert("Problem in assigning assets");
        }
      )
      handleAssinedChangeChange('partnerAssetOwnerId', values.partnerAssetOwnerId);

  }

  
  // TODO: upload MultipartFile
  function urltoFile(url, filename, mimeType) {
    return (fetch(url)
        .then(function (res) { return res.arrayBuffer(); })
        .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  ////////////////////////////////////
  
  // Upload from CSV file
  async function handleCsvUpload() {
  
    const token = localStorage.getItem('jwtToken');


    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let convertedFile;
    let fileName = 'C:\Git\safeloanapi\src\main\resources\PartnerAssetDTOs.csv';
    //urltoFile(values.signatureURL, 'hello.png', 'image/png')
    urltoFile(fileName, 'hello.png', 'image/png')
        .then(function (file) {

          const formData = new FormData();
          formData.append('file', fileName);
          axios.post(process.env.REACT_APP_SAFELOAN_API_URL+`/PartnerAdmin/UploadCsvFile`, formData, config)
            .then(res => {
              if (res.data === null) {
                alert("Sorry, you are denied :(");
              }
              if (res.data !== null) {
                console.log('res.data !== null' + res.data);
                const pushData = {
                  //   optionsSubAccounts: res.data,

                }

                // history.push("/AdditionalInformation", pushData);
              }
            }).catch(
              function (error) {
                console.log('Problem in uploading csv file');
                alert('Problem in uploading csv file');
              }
            )

            console.log('File csv uploaded');
        });
               
  }
  ////////////////////////////////////
    
  

  return (
    <Fragment>
      <PageTitle
        titleHeading="Manage Assets"
        titleDescription="Allocate to Assets Owners"
      />

      <ExampleWrapperSimple sectionHeading="">
        <Grid container spacing={3} className="d-flex align-items-center">
          <Grid item xs>
            <Card className="card-box mb-4">
              <h3 className="font-size-lg font-weight-bold mt-5 mb-4">
                Assets Owner's Assets list
              </h3>

              <p className="card-text px-4 mb-4">
                Can be used to assign assets
              </p>

              <Container maxWidth="lg">

                <div className="mb-3">
                  <Autocomplete
                    id="grouped-demo"
                    options={optionsSubAccounts}
                    // groupBy={option => option.firstLetter}

                    onChange={(event, newValue) => {
                      console.log('handleChange1', newValue);
                      const y = newValue !== null ? newValue.assetSubAccountId : null;
                      handleChange('subAccountId', y)
                    }}
                    getOptionLabel={optionsSubAccounts => optionsSubAccounts.assetSubAccountLocation}
                    getOptionSelected={optionsSubAccounts => optionsSubAccounts.assetSubAccountId}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label= "location" //"sub account"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />

                </div>
                <div className="mb-4">
                  <Autocomplete
                    id="grouped-demo"
                    options={optionsPartnerAssetOwner}
                    onChange={(event, newValue) => {
                      console.log('handleChange2' + newValue);
                      const x = newValue !== null ? newValue.partnerAssetOwnerId : null;
                      handleChange('partnerAssetOwnerId', x)
                    }}
                    getOptionLabel={optionsPartnerAssetOwner => optionsPartnerAssetOwner.description}
                    getOptionSelected={optionsPartnerAssetOwner => optionsPartnerAssetOwner.partnerAssetOwnerId}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label= "sub account" //"partner asset owner"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </div>

                <div className="">

                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">Global Asset ID</TableCell>
                        <TableCell align="right">Asset Owner ID</TableCell>
                        <TableCell align="right">Asset Type</TableCell>
                        <TableCell align="right">Asset Unit</TableCell>
                        <TableCell align="right">Sub Account</TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {searchResults.map((row) => (
                        <TableRow>

                          <TableCell align="right">{row.gui}</TableCell>
                          <TableCell align="right"> {row.assetOwnerID}\{row.assetOwnerEmail} </TableCell>
                          <TableCell align="right"> {row.assetType}</TableCell>
                          <TableCell align="right"> {row.assetUnit}</TableCell>
                          <TableCell align="right"> {row.subAccountID}\{row.subAccountLocation}</TableCell>


                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                </div>

              </Container>

            </Card>
          </Grid>
          <Grid item xs >
            <Card className="card-box mb-4">
              <h3 className="font-size-lg font-weight-bold mt-5 mb-4">
                Asset Affiliation
              </h3>
              <p className="card-text px-4 mb-4">
              Assign Asset to the specified Asset Owner, in a specifc sub account 
              </p>
              <Container maxWidth="lg">
                <div className="mb-3">
                  <Autocomplete
                    id="grouped-demo"
                    options={optionsSubAccounts}
                    onChange={(event, newValue) => {
                      const x = newValue !== null ? newValue.assetSubAccountId : null;
                      handleAssinedChangeChange('subAccountId', x)
                    }
                    }
                    getOptionLabel={optionsSubAccounts => optionsSubAccounts.assetSubAccountLocation}
                    getOptionSelected={optionsSubAccounts => optionsSubAccounts.assetSubAccountId}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label= "location"  //"sub account"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />

                </div>
                <div className="mb-4">
                  <Autocomplete
                    id="grouped-demo"
                    options={optionsPartnerAssetOwner}

                    onChange={(event, newValue) => {
                      const x = newValue !== null ? newValue.partnerAssetOwnerId : null;
                      handleAssinedChangeChange('partnerAssetOwnerId', x)
                    }
                    }
                    getOptionLabel={optionsPartnerAssetOwner => optionsPartnerAssetOwner.description}
                    getOptionSelected={optionsPartnerAssetOwner => optionsPartnerAssetOwner.partnerAssetOwnerId} //.Id}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label= "sub account" //"partner asset owner"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </div>




                <div className="mb-5">
                  <Autocomplete
                    id="grouped-demo"
                    options={optionsGlobalAsset}
                    onChange={(event, newValue) => {
                      const x = newValue !== null ? newValue.gui : null;
                      handleAssinedChangeChange('gui', x)
                    }
                    }
                    getOptionLabel={optionsGlobalAsset => optionsGlobalAsset.assetType + ' ' + optionsGlobalAsset.assetUnit + ' ' + optionsGlobalAsset.gui}
                    getOptionSelected={optionsGlobalAsset => optionsGlobalAsset.gui}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="global Asset Id"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </div>

                <div className="text-black-50 d-block" style={{ textAlign: 'right' }}  >
                  <Button
                    style={{ marginTop: "15%", marginBottom: "2%", marginRight: "2%" }}
                    color="secondary"
                    size="large"
                    variant="contained"
                    onClick={handleAssignment}
                  >Assignment</Button>
                </div>

                <div className="text-black-50 d-block" style={{ textAlign: 'right' }}  >
                  <Button
                    style={{ marginTop: "15%", marginBottom: "2%", marginRight: "2%" }}
                    color="secondary"
                    size="large"
                    variant="contained"
                    onClick={handleCsvUpload}
                  >csv upload</Button>
                </div>

              </Container>
            </Card>
          </Grid>
        </Grid>
      </ExampleWrapperSimple>
    </Fragment>
  );


};
export default AssetAllocation;
