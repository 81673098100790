import React, { Fragment, useEffect, useState } from 'react';
import AvatarEditor from "components/AvatarEditor";
import { useHistory } from "react-router-dom";
import { Card } from '@material-ui/core';

// import { Auth } from 'aws-amplify';
import axios from "axios";

import {
    TextField,
    Paper,
    Tabs,
    Tab,
    Button,
    Grid,
    Modal
} from '@material-ui/core';
import defaultImage from 'assets/images/defaultImage.png';
import DigitalSignature from 'pages/DigitalSignature';

import FormsWizardBasic from '../../components/FormsWizard/FormsWizardBasic';
import { textAlign } from '@mui/system';

const styles = {
    img: {
        width: "25%",
        height: "25%",
        display: "block"
    },
    label: {
        marginTop: "1%",
        fontSize: "180%"
    },
    input: {
        marginTop: "1%",
        width: "100%"
    },
    select: {
        appearance: "menulist",
        width: "100%",
        height: "4.2em",
        appearance: "menulist",
        borderRadius: "5px 5px 5px 5px",
        borderWidth: "1px 1px 1px 1px",
        borderColor: "#D3D3D3",
    },
    selectMarketplace: {
        marginTop: "1%",
        appearance: "menulist",
        width: "49%",
        height: "4.2em",
        appearance: "menulist",
        borderRadius: "5px 5px 5px 5px",
        borderWidth: "1px 1px 1px 1px",
        borderColor: "#D3D3D3",
    },
    selectCollection: {
        marginTop: "1%",
        float: "right",
        appearance: "menulist",
        width: "49%",
        height: "4.2em",
        appearance: "menulist",
        borderRadius: "5px 5px 5px 5px",
        borderWidth: "1px 1px 1px 1px",
        borderColor: "#D3D3D3",
    },
    tabs: {
        marginTop: "3%"
    },
    selectOneInTab: {
        marginTop: "1%",
        appearance: "menulist",
        width: "100%",
        height: "4.2em",
        appearance: "menulist",
        borderRadius: "5px 5px 5px 5px",
        borderWidth: "1px 1px 1px 1px",
        borderColor: "#D3D3D3",
    },
    selectTwoInTab: {
        marginTop: "1%",
        appearance: "menulist",
        width: "10%",
        height: "4.2em",
        appearance: "menulist",
        borderRadius: "5px 5px 5px 5px",
        borderWidth: "1px 1px 1px 1px",
        borderColor: "#D3D3D3"
    },
    inputStartingPrice: {
        marginTop: "1.5%",
        width: "60%",
        float: "right"
    },
    inputDuration: {
        marginTop: "1.5%",
        width: "100%"
    },
    Button: {
        marginTop: "1%",
        float: "right"
    },
    BackButton: {
        marginTop: "1%",
        marginRight: "1%",
        float: "right"
    },
    validationMessage: {
        marginTop: "2%",
        color: "red",
        textAlign: "right"
    }
}



const ListAsset = (props) => {

    const [values, setValues] = React.useState({
        nftRequestId: props.location.state != null ? props.location.state.nftRequestId : "",

        mintedAmount: 0,
        period: 0,
        itemName: props.location.state != null ? props.location.state.chosenAsset.productName : "",
        fileChanged: false,

        externalLink: props.location.state != null ? props.location.state.externalLink : "",
        chosenCollection: "",
        chosenCategory: "Painting",
        chosenMarketplace: "",
        chosenCurrency: 'ETHEREUM',
        itemDescription: props.location.state != null ? props.location.state.chosenAsset.description : "",
        file: props.location.state == null ? null : new File(["default"], "default.png", {
            type: "image/png",
        }),
        fileSrc: props.location.state != null && props.location.state.chosenAsset != null ? props.location.state.chosenAsset.imageUrl : defaultImage,
        collateralAssets: props.location.state != null ? props.location.state.collateralAssets : "",
        vaultAccountId: props.location.state != null ? props.location.state.vaultAccountId : "",
        accountId: props.location.state != null ? props.location.state.accountId : "",
        nftCollections: [],

        endingDatePeriod: props.location.state != null ? props.location.state.endingDatePeriod : "",

        listingMethod: "FIXED",
        sellingStrategy: 0,
        // Validation
        showNameValidationMessage: false,
        showPriceValidationMessage: false,
        showDurationValidationMessage: false,
        showSelectsValidationMessage: false,
        showPictureValidationMessage: false,
        showPictureSizeValidationMessage: false,

        nftId: props.location.state != null ? props.location.state.chosenAsset.id : "HARDCODED!",
        assetGuid: props.location.state != null ? props.location.state.chosenAsset.itemGuid : null,
        nftType: props.location.state != null ? 'PHYSICAL' : 'PURE_DIGITAL'
    });

    async function setDefaultData() {
        // const URL = process.env.REACT_APP_SAFELOAN_API_URL + '/PartnerAdmin/getAllCollections';
        const jwtToken = localStorage.getItem('jwtToken');
        const config = {
            headers: { Authorization: `Bearer ${jwtToken}` }
        };
        //     const response = await axios.get(URL, config);

        const URL2 = process.env.REACT_APP_SAFELOAN_API_URL + '/MintingAsset/CreateMintRequest';
        const dataForRequest2 = {
            vaultAccountId: null,
            collateralAssets: null
        }
        const response2 = await axios.post(URL2, dataForRequest2, config);
        const nftRequestId = values.nftRequestId == "" ? response2.data.nftRequestId : values.nftRequestId;

        console.log('response2' + response2);
        setValues({ ...values, ['nftCollections']: response2.data.nftCollections, ['nftRequestId']: nftRequestId });
    }

    React.useEffect(() => {
        setDefaultData();
    }, []);

    const handleChangeTab = (event, newValue) => {
        setValues({
            ...values, ['listingMethod']: newValue
        });

    }

    const setSellingStrategy = (event, newValue) => {
        setValues({
            ...values, ['sellingStrategy']: newValue
        });
    }


    const handleChangeForInputs = prop => event => {
        setValues({ ...values, [prop]: event.target.value });
    };


    const onChangeSelectCollection = (e) => {
        setValues({ ...values, ['chosenCollection']: e.target.value });
    }

    const onChangeSelectMarketplace = (e) => {
        setValues({ ...values, ['chosenMarketplace']: e.target.value });
    }

    const onChangeSelectCategory = (e) => {
        setValues({ ...values, ['chosenCategory']: e.target.value });
    }

    const onChangeSelectCurrency = (e) => {
        setValues({ ...values, ['chosenCurrency']: e.target.value });
    }

    const onChangeMintedAmount = (e) => {
        setValues({ ...values, ['mintedAmount']: e.target.value });
    }

    const onChangePeriod = (event) => {
        setValues({ ...values, ['period']: event.target.value });
    }

    const onChangeItemName = (e) => {

        setValues({ ...values, ['itemName']: e.target.value });
    }

    const onChangeItemDescription = (e) => {
        setValues({ ...values, ['itemDescription']: e.target.value });
    }

    const onChangeExternalLink = (e) => {
        setValues({ ...values, ['externalLink']: e.target.value });
    }


    async function submitMintingAsset() {
        let validationOk = await isValidationOk();

        if (!validationOk) {
            alert("Please check that all required fields are filled");
        }

        if (validationOk) {
            const token = localStorage.getItem('jwtToken');
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            const formData = new FormData();
            formData.append('nftRequestId', values.nftRequestId);
            formData.append('assetGuid', values.assetGuid); // Should be id of collateralAsset itemGuid
            formData.append('mintedAmount', values.mintedAmount);
            formData.append('itemName', values.itemName);
            formData.append('externalLink', values.externalLink);
            formData.append('collectionId', values.chosenCollection);
            formData.append('categoryId', 'Painting');
            formData.append('marketplaceId', values.chosenMarketplace);
            formData.append('nftCurrency', values.chosenCurrency);
            formData.append('Description', values.itemDescription);
            formData.append('endingDatePeriod', values.period);
            formData.append('nftType', values.nftType);
            formData.append('file', values.file);
            formData.append('listingMethod', values.listingMethod)
            console.log('formData' + JSON.stringify(formData));
            await axios.post(process.env.REACT_APP_SAFELOAN_API_URL + '/MintingAsset/SubmitMintRequest', formData, config)
                .then(res => {
                    const dataForNextPage = {
                        relevantEntityId: res.data[0].id
                    }
                    if (values.nftType === "PHYSICAL") {
                        localStorage.setItem("relevantEntityId", res.data[0].id);
                        history.push("/MintingSubmit", dataForNextPage);
                    }
                    if (values.nftType === "PURE_DIGITAL") {
                        history.push("/MintingManagement");
                    }
                }).catch(
                    function (error) {
                        console.log(error);
                        alert("Sorry, ERROR in SubmitMintRequest");
                    }
                )
        }
    }

    const history = useHistory();

    const handleOnFileChange = (e) => {
        let file = values.file;
        let fileSrc = values.fileSrc;
        let fileChanged = false;
        let isSizeMoreThan5Mb = false;

        try {
            if (e.target.files[0].size <= 5000000) {
                file = e.target.files[0];
                fileSrc = URL.createObjectURL(file);
            } else {
                isSizeMoreThan5Mb = true;
            }
        } catch (err) {
            console.log(err);
        }

        //e.prop("value", "");

        setValues({
            ...values,
            ['file']: file,
            ['fileSrc']: fileSrc,
            ["fileChanged"]: fileChanged,
            ["showPictureSizeValidationMessage"]: isSizeMoreThan5Mb
        });
    }

    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    function isValidationOk() {
        let showNameValidationMessage = false;
        let showPriceValidationMessage = false;
        let showDurationValidationMessage = false;
        let showSelectsValidationMessage = false;
        let showPictureValidationMessage = false;

        if (values.itemName.length < 1) {
            showNameValidationMessage = true;
        } else {
            showNameValidationMessage = false;
        }

        if (values.mintedAmount.length > 0) {
            showPriceValidationMessage = false;
        } else {
            showPriceValidationMessage = true;
        }

        if (values.period != 0) {
            showDurationValidationMessage = false;
        } else {
            showDurationValidationMessage = true;
        }

        // if (values.chosenCategory.length > 0 && values.chosenCollection.length > 0 && values.chosenMarketplace.length > 0) {
        //     showSelectsValidationMessage = false;
        // } else {
        //     showSelectsValidationMessage = true;
        // }

        if (values.file != null || values.nftT) {
            showPictureValidationMessage = false;
        } else {
            showPictureValidationMessage = true;
        }

        setValues({
            ...values,
            ['showNameValidationMessage']: showNameValidationMessage,
            ['showPriceValidationMessage']: showPriceValidationMessage,
            ['showDurationValidationMessage']: showDurationValidationMessage,
            ['showSelectsValidationMessage']: showSelectsValidationMessage,
            ['showPictureValidationMessage']: showPictureValidationMessage
        });

        if (
            showPriceValidationMessage === false &&
            showDurationValidationMessage === false &&
            showSelectsValidationMessage === false &&
            showPictureValidationMessage === false
        ) {
            return true;
        } else {
            return false;
        }
    }
    return (

        <div>
            <h1>List the NFT</h1>
            <Grid container spacing={4} style={{ marginTop: "2%" }}>
                <Grid item lg={4} style={{ overflow: "hidden" }}>
                    <p style={{ color: "gray" }}><p style={{ display: "inline", color: "red" }}>*</p> Required fields</p>
                    <h6>Please upload additional images of the item <p style={{ display: "inline", color: "red" }}>*</p></h6>
                    <p style={{ color: "gray" }}>File types supported: JPG, PNG, GIF, SVG</p>
                    <img onClick={handleClick} src={values.fileSrc} style={{ maxWidth: "100%", height: "auto", border: "2px solid #555", cursor: 'pointer' }}></img>
                    <input type="file" onChange={handleOnFileChange} style={{ display: 'none' }} ref={hiddenFileInput}></input>
                    <p hidden={!values.showPictureValidationMessage} style={{ marginTop: "2%", color: "red" }}>Picture should be uploaded!</p>
                    <p hidden={!values.showPictureSizeValidationMessage} style={{ marginTop: "2%", color: "red" }}>Picture size should be less than 5mb</p>
                </Grid>
            </Grid>


            <Grid container spacing={4}>
                <Grid item lg={9}>
                    <div style={{ marginTop: "2%" }}>
                        <h6>Name <p style={{ display: "inline", color: "red" }}>*</p></h6>
                        <TextField

                            style={styles.input}
                            placeholder="Product name"
                            variant="outlined"
                            fullWidth
                            type="text"
                            onChange={onChangeItemName}
                            value={values.itemName}
                        >
                        </TextField>
                        <p hidden={!values.showNameValidationMessage} style={styles.validationMessage}>Name should not be empty</p>

                        <h6 style={{ marginTop: "3%" }}>Add link to additional information page</h6>
                        <p style={{ color: "gray" }}>Cyberw3 will include a link to this URL on this items detail page, so that users can click to learn more about it. You are welcome to link to your own webpage with more details.</p>
                        <TextField
                            //onChange={onChange('itemAddress')}
                            style={{ width: "100%" }}
                            placeholder="external link"
                            variant="outlined"
                            fullWidth
                            type="text"
                            onChange={onChangeExternalLink}
                        >
                        </TextField>

                        <h6 style={{ marginTop: "3%" }}>Description</h6>
                        <p style={{ color: "gray" }}>The description will be included on the item's detail page underneath its image.</p>
                        <TextField
                            style={{ width: "100%" }}
                            fullWidth
                            id="outlined-multiline-static"
                            multiline
                            rows="10"
                            variant="outlined"
                            value={values.itemDescription}
                            placeholder="Provide a detail description of your item"
                            onChange={onChangeItemDescription}
                        >
                        </TextField>
                    </div>

                    <h6 style={{ marginTop: "3%" }}>Collection <p style={{ display: "inline", color: "red" }}>*</p></h6>
                    <p style={{ color: "gray" }}>There you choice collection, where your item will appear and item category.</p>
                    <select style={styles.select}
                        onChange={onChangeSelectCollection}>
                        <option value="">Collection</option>
                        {values.nftCollections != null ? values.nftCollections.map(row => (
                            <option value={row.id} >Collection {row.description}</option>
                        ))
                            : ""
                        }
                    </select>


                    <select style={styles.selectMarketplace} onChange={onChangeSelectMarketplace}>
                        <option value="">Marketplace</option>
                        <option value="OpenSea">Open Sea</option>
                        <option value="Rarible">Rarible</option>
                        <option value="Internal">Internal( white lable)</option>
                    </select>

                    {/* <select style={styles.selectCollection} onChange={onChangeSelectCategory}>
                        <option value="">Category</option>
                        <option value="Watches">Watches</option>
                        <option value="High_jewelry">High Jewelry</option>
                        <option value="Fine_jewelry">Fine Jewelry</option>
                        <option value="BAGS">Bags</option>
                        <option value="Painting">Painting</option>
                        <option value="Sculpture">Sculpture</option>
                        <option value="Wine">Wine</option>
                    </select> */}

                    <p hidden={!values.showSelectsValidationMessage} style={styles.validationMessage}>Collection, marketplace and category should not be empty.</p>

                    <div hidden={values.chosenMarketplace != "OpenSea" && values.chosenMarketplace != "Rarible"}>
                        <h6 style={{ marginTop: "3%" }}>List your NFT <p style={{ display: "inline", color: "red" }}>*</p></h6>
                        <p style={{ color: "gray" }}>There you choice currency, price and duration.</p>
                        <Paper square style={styles.tabs}>
                            <Tabs
                                value={values.listingMethod}
                                onChange={handleChangeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab label={"Fixed"} value={"FIXED"} />
                                <Tab label={"Timed Auction "} value={"AUCTION"} />
                            </Tabs>
                        </Paper>


                        <select hidden={values.sellingStrategy == 0} style={styles.selectOneInTab} onChange={setSellingStrategy} >
                            <option>Sell to highest bidder</option>
                        </select>

                        <select style={{
                            marginTop: "1%",
                            appearance: "menulist",
                            width: "15%",
                            height: "4.2em",
                            appearance: "menulist",
                            borderRadius: "5px 5px 5px 5px",
                            borderWidth: "1px 1px 1px 1px",
                            borderColor: "#D3D3D3"
                        }} onChange={onChangePeriod}>
                            <option value={0}>Duration in days</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={30}>30</option>
                        </select>

                        <select style={{
                            marginLeft: "2%",
                            marginTop: "1%",
                            appearance: "menulist",
                            width: "10%",
                            height: "4.2em",
                            appearance: "menulist",
                            borderRadius: "5px 5px 5px 5px",
                            borderWidth: "1px 1px 1px 1px",
                            borderColor: "#D3D3D3"
                        }} onChange={onChangeSelectCurrency}>
                            <option value={"ETHEREUM"}>Ethereum</option>
                            <option value={"POLYGON"}>Polygon</option>
                        </select>
                        <TextField
                            //onChange={onChange("price")}
                            style={{
                                marginTop: "1.5%",
                                width: "71%",
                                float: "right"
                            }}
                            fullWidth
                            id="outlined-multiline-static"
                            multiline
                            variant="outlined"
                            placeholder="Starting price"
                            onChange={onChangeMintedAmount}
                        >
                        </TextField>
                        <p hidden={!values.showDurationValidationMessage} style={styles.validationMessage}>Duration cant be empty</p>
                        <p hidden={!values.showPriceValidationMessage} style={styles.validationMessage}>Price cant be empty</p>
                    </div>
                    <Card className="rounded-sm card-box p-3 m-3">
                        <div >
                            <Button
                                hidden={values.chosenMarketplace != "OpenSea" && values.chosenMarketplace != "Rarible"}
                                style={{
                                    marginTop: "1%",
                                    float: "right"
                                }}
                                onClick={submitMintingAsset}
                                color="secondary"
                                size="large"
                                variant="contained"
                            >List your item
                            </Button>

                            <Button
                                style={{
                                    marginTop: "1%",
                                    marginRight: "1%",
                                    float: "right"
                                }}
                                onClick={() => history.push("/MintingManagement")}
                                color="secondary"
                                size="large"
                                variant="outlined"
                            >Back
                            </Button>
                        </div>
                    </Card>

                </Grid>
            </Grid>

            <Grid container spacing={4} hidden={values.nftType === "PURE_DIGITAL"}>
                <Grid item lg={9}>
                    <FormsWizardBasic activeStep={1} />
                </Grid>
            </Grid>
        </div >
    )
    // }  //ending of function ListAsset() 

}
export default ListAsset;
