import React, { Component } from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import Routes from './Routes';
import ScrollToTop from './utils/ScrollToTop';
import './assets/base.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import { library } from '@fortawesome/fontawesome-svg-core';
// import { Amplify, Auth } from 'aws-amplify';
// import { AmplifyAuthenticator, AmplifyGreetings, AmplifySignUp } from '@aws-amplify/ui-react';
// import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
// import awsconfig from './aws-exports';
import { IconButton, Box } from '@material-ui/core';
import CookieConsent from "react-cookie-consent";
import ReactDOM from "react-dom";
import LogIn from 'pages/LogIn';
import ChangePassword from 'pages/ChangePassword';
import queryString from 'query-string';
import axios from "axios";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import {
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
} from '@fortawesome/free-brands-svg-icons';
import {
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
} from '@fortawesome/free-regular-svg-icons';
import {
  fas,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
} from '@fortawesome/free-solid-svg-icons';
// import { SignIn } from 'aws-amplify-react';
import FraudTable from 'pages/FraudTable';
library.add(
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
);
library.add(
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
);
library.add(
  fas,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
);

const store = configureStore();
// Amplify.configure(awsconfig);



const AuthStateApp = () => {


  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  // React.useEffect(() => {
  //   return onAuthUIStateChange((nextAuthState, authData) => {
  //     setAuthState(nextAuthState);
  //     setUser(authData);
  //   });
  // }, [])

  // CALLED BY DEFAULT !!!!!!!!!!!!!!!!!!!!!
  let jwtToken = localStorage.getItem("jwtToken");
  if (jwtToken) {
    const decodedJwt = parseJwt(jwtToken);

    if (decodedJwt != null) {

      if (decodedJwt.exp * 1000 < Date.now()) {
        console.log('decodedJwt expiered, reset')
        jwtToken = null;
        localStorage.removeItem("jwtToken")
      }
    }
  }

  function parseJwt(token) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  }

  if (jwtToken != null) {
    return (
      <Provider store={store}>
        <BrowserRouter basename="/Cyberw3/">
          <CssBaseline />
          <ScrollToTop>

            <Routes />
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    )
  } else {
    return (
      <Provider store={store}>
        <BrowserRouter basename="/Cyberw3/">
          <CssBaseline />
          <ScrollToTop>
            {/* {window.location.href.includes("ChangePassword") ? <ChangePassword /> : <LogIn />} */}
            <Switch>
              <Route exact path="/">
                {window.location.href.includes("ChangePassword") ? <ChangePassword /> : <LogIn />}
              </Route>
              

            </Switch>
            <CookieConsent
              location="bottom"
              buttonText="Accept cookies"
              cookieName="CyberW3Cookie"
              style={{ background: "#090d14" }}
              buttonStyle={{ color: "#ffffff", background: "#d1983b", fontSize: "18px" }}
              expires={150}
            >
              This website uses cookies to enhance the user experience.{" "}

              <a style={{ color: "#d1983b", fontSize: "13px" }} href="https://www.Cyberw3.com/privacy.html" > see our privacy </a>

            </CookieConsent>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>

    )
  }

}

export default AuthStateApp;
