import React from 'react';
import axios from "axios";
// import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";

import PrecioLogoDark from 'assets/images/Precio_Logo_Dark.png'

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
    Grid,
    Button
} from '@material-ui/core';

export default function ChangePassword() {
    const [values, setValues] = React.useState({
        username: "",
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        partnerId: "",

        // Validation
        showUsernameValidationErrorMessage: false,
        showCurrentPasswordValidationErrorMessage: false,
        showNewPasswordValidationErrorMessage: false,
        showConfirmNewPasswordValidationErrorMessage: false,
        showPartnerIdValidationErrorMessage: false,
        showNewPasswordAndConfirmPasswordAreNotEqualValidationErrorMessage: false,

        //Hidden or not text in inputs
        currentPasswordHidden: true,
        newPasswordHidden: true,
        confirmNewPasswordHidden: true,
        partnerIdHidden: true,
    });

    const styles = {
        label: {
            fontSize: "150%"
        },
        input: {
            width: "50%",
            float: "right"
        },
        validationMessage: {
            color: "red",
            textAlign: "right"
        }
    }

    const onChangeInputs = variableName => event => {
        setValues({ ...values, [variableName]: event.target.value });
    };

    function onClickChangePassword() {
        let validationOk = isValidationOk();

        if (validationOk === true) {
            sendChangePasswordRequest();
        }
    }

    function isValidationOk() {
        let showUsernameValidationErrorMessage = false;
        let showCurrentPasswordValidationErrorMessage = false;
        let showNewPasswordValidationErrorMessage = false;
        let showConfirmNewPasswordValidationErrorMessage = false;
        let showPartnerIdValidationErrorMessage = false;
        let showNewPasswordAndConfirmPasswordAreNotEqualValidationErrorMessage = false;

        if (values.username.length > 0) {
            showUsernameValidationErrorMessage = false;
        } else {
            showUsernameValidationErrorMessage = true;
        }

        if (values.currentPassword.length > 0) {
            showCurrentPasswordValidationErrorMessage = false;
        } else {
            showCurrentPasswordValidationErrorMessage = true;
        }

        if (values.newPassword.length > 0) {
            showNewPasswordValidationErrorMessage = false;
        } else {
            showNewPasswordValidationErrorMessage = true;
        }

        if (values.confirmNewPassword.length > 0) {
            showConfirmNewPasswordValidationErrorMessage = false;
        } else {
            showConfirmNewPasswordValidationErrorMessage = true;
        }

        if (values.partnerId.length > 0) {
            showPartnerIdValidationErrorMessage = false;
        } else {
            showPartnerIdValidationErrorMessage = true;
        }

        if (values.newPassword === values.confirmNewPassword) {
            showNewPasswordAndConfirmPasswordAreNotEqualValidationErrorMessage = false;
        } else {
            showNewPasswordAndConfirmPasswordAreNotEqualValidationErrorMessage = true;
        }

        setValues({
            ...values,
            ['showUsernameValidationErrorMessage']: showUsernameValidationErrorMessage,
            ['showCurrentPasswordValidationErrorMessage']: showCurrentPasswordValidationErrorMessage,
            ['showNewPasswordValidationErrorMessage']: showNewPasswordValidationErrorMessage,
            ['showConfirmNewPasswordValidationErrorMessage']: showConfirmNewPasswordValidationErrorMessage,
            ['showPartnerIdValidationErrorMessage']: showPartnerIdValidationErrorMessage,
            ['showNewPasswordAndConfirmPasswordAreNotEqualValidationErrorMessage']: showNewPasswordAndConfirmPasswordAreNotEqualValidationErrorMessage
        });

        if (
            showUsernameValidationErrorMessage === false &&
            showCurrentPasswordValidationErrorMessage === false &&
            showNewPasswordValidationErrorMessage === false &&
            showConfirmNewPasswordValidationErrorMessage === false &&
            showPartnerIdValidationErrorMessage === false &&
            showNewPasswordAndConfirmPasswordAreNotEqualValidationErrorMessage === false
        ) {
            return true;
        } else {
            return false;
        }
    }

    let history = useHistory();

    async function sendChangePasswordRequest() {
        const formData = new FormData();
        formData.append('username', values.username);
        formData.append('currentPassword', values.currentPassword);
        formData.append('newPassword', values.newPassword);
        formData.append('partnerId', values.partnerId);

        axios.post(process.env.REACT_APP_SAFELOAN_API_URL + '/PartnerAdmin/changePassword', formData)
            .then(res => {
                history.push("/LogIn");
                history.go("/LogIn");
            }).catch(
                function (error) {
                    console.log("Error !!!!!!! " + error);
                    if (error == "Error: Request failed with status code 400") {
                        alert("Please, make sure that your username, password and partner id correct");
                    }
                    else if (
                        error == "Error: Request failed with status code 401" ||
                        error == "Error: Request failed with status code 403" ||
                        error == "Error: Request failed with status code 407") {
                        alert("Sorry, you are denied");
                    }
                    else if (error == "Error: Network Error") {
                        alert("Please make sure your internet connection stable.");
                    }
                    else {
                        alert("Something wet wrong. Please try again or write to support team");
                        console.log("!!!!!!" + error);
                    }
                }
            )
    }

    function onClickEye(variableName) {
        let currentPasswordHidden = values.currentPasswordHidden;
        let newPasswordHidden = values.newPasswordHidden;
        let confirmNewPasswordHidden = values.confirmNewPasswordHidden;
    
        if (variableName === "currentPasswordHidden") {
            currentPasswordHidden = !currentPasswordHidden;
        }
        if (variableName === "newPasswordHidden") {
            newPasswordHidden = !newPasswordHidden;
        }
        if (variableName === "confirmNewPasswordHidden") {
            confirmNewPasswordHidden = !confirmNewPasswordHidden;
        }

        setValues({
            ...values,
            ['currentPasswordHidden']: currentPasswordHidden,
            ['newPasswordHidden']: newPasswordHidden,
            ['confirmNewPasswordHidden']: confirmNewPasswordHidden
        });
    }

    return (
        <div>
            <img style={{ width: "30%", height: "30%" }} src={PrecioLogoDark} />
            <Grid container spacing={0}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    <h4 style={{ marginBottom: "3%" }}>You Should To Change Your Password!</h4>
                    <div>
                        <label style={styles.label}>Username</label>
                        <input onChange={onChangeInputs("username")} style={styles.input}></input>
                        <p hidden={!values.showUsernameValidationErrorMessage} style={styles.validationMessage}>Username cant be empty</p>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    <div>
                        <label style={styles.label}>Current Password</label>
                        <input type={values.currentPasswordHidden ? "password" : "text"} onChange={onChangeInputs("currentPassword")} style={styles.input}></input>
                        <p hidden={!values.showCurrentPasswordValidationErrorMessage} style={styles.validationMessage}>Current Password cant be empty</p>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <VisibilityIcon hidden={!values.currentPasswordHidden} style={{ marginLeft: "1%", cursor: "pointer" }} onClick={() => onClickEye("currentPasswordHidden")} />
                    <VisibilityOffIcon hidden={values.currentPasswordHidden} style={{ marginLeft: "1%", cursor: "pointer" }} onClick={() => onClickEye("currentPasswordHidden")} />
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    <div>
                        <label style={styles.label}>New Password</label>
                        <input type={values.newPasswordHidden ? "password" : "text"} onChange={onChangeInputs("newPassword")} style={styles.input}></input>
                        <p hidden={!values.showNewPasswordValidationErrorMessage} style={styles.validationMessage}>New Password cant be empty</p>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <VisibilityIcon hidden={!values.newPasswordHidden} style={{ marginLeft: "1%", cursor: "pointer" }} onClick={() => onClickEye("newPasswordHidden")} />
                    <VisibilityOffIcon hidden={values.newPasswordHidden} style={{ marginLeft: "1%", cursor: "pointer" }} onClick={() => onClickEye("newPasswordHidden")} />
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    <div>
                        <label style={styles.label}>Confirm New Password</label>
                        <input type={values.confirmNewPasswordHidden ? "password" : "text"} onChange={onChangeInputs("confirmNewPassword")} style={styles.input}></input>
                        <p hidden={!values.showConfirmNewPasswordValidationErrorMessage} style={styles.validationMessage}>Confirmed New Password cant be empty</p>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <VisibilityIcon hidden={!values.confirmNewPasswordHidden} style={{ marginLeft: "1%", cursor: "pointer" }} onClick={() => onClickEye("confirmNewPasswordHidden")} />
                    <VisibilityOffIcon hidden={values.confirmNewPasswordHidden} style={{ marginLeft: "1%", cursor: "pointer" }} onClick={() => onClickEye("confirmNewPasswordHidden")} />
                </Grid>
            </Grid>
           
            <Grid container spacing={0}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    <Button onClick={onClickChangePassword} style={{ float: "right", marginTop: "1%" }} size="large" variant="outlined">Change Password</Button>
                </Grid>
            </Grid>
        </div>
    );
}
