import React, { useState, Fragment } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';

// import { Box, Button, Container, TextField, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  tableRightBorder: {
    borderRightWidth: 1,
    borderRightColor: '#000000',
    borderRightStyle: 'solid',
    width: '10%'
  } // or borderTop: '1px solid red'
}));
function TableNew({ data }) {
  // const {nfts, setNfts} = useState(data);
  // const {scoreAsc, setScoreAsc} = useState(false);
  const classes = styles();
  const [nfts, setNfts] = useState(data);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [lastSortingVariableName, setLastSortingVariableName] =
    React.useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function sortNfts(variableName) {
    const isAscSorting = lastSortingVariableName != variableName;
    const isBigInt = variableName == 'tokenId';
    const isScore = variableName == 'score';
    const byDate = variableName == 'created';
    const isNumber = false;
    nfts.sort(function (a, b) {
      if (byDate)
        return isAscSorting
          ? new Date(a[variableName]).getTime() -
              new Date(b[variableName]).getTime()
          : new Date(b[variableName]).getTime() -
              new Date(a[variableName]).getTime();
      if (isBigInt)
        return isAscSorting
          ? Number(BigInt(a[variableName]) - BigInt(b[variableName]))
          : Number(BigInt(b[variableName]) - BigInt(a[variableName]));
      if (isScore)
        return isAscSorting
          ? Math.abs(1 - a[variableName]) - Math.abs(1 - b[variableName])
          : Math.abs(1 - b[variableName]) - Math.abs(1 - a[variableName]);
      if (isNumber)
        return isAscSorting
          ? a[variableName] - b[variableName]
          : b[variableName] - a[variableName];

      let aString = a[variableName];
      let bString = b[variableName];

      return isAscSorting
        ? aString.localeCompare(bString)
        : bString.localeCompare(aString);
    });
    setNfts(nfts);
    setLastSortingVariableName(isAscSorting ? variableName : '');
  }
  return (
    <>
      <Fragment>
        <TablePagination
          component="div"
          count={nfts.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <TableContainer component={Paper} sx={{ width: '100%' }}>
          <Table
            sx={{
              minWidth: 650
            }}
            aria-label="fraud table">
            <TableHead sx={{ backgroundColor: '#3D4977', color: 'white' }}>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="center" sx={{ color: 'white' }}>
                  Fraud NFT
                </TableCell>
                <TableCell></TableCell>
                <TableCell
                  className={classes.tableRightBorder}
                  sx={{ width: '10%' }}></TableCell>
                <TableCell></TableCell>
                <TableCell align="center" sx={{ color: 'white' }}>
                  Real NFT
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow sx={{ color: 'white' }}>
                <TableCell align="left" sx={{ width: '10%', color: 'white' }}>
                  <TableSortLabel
                    direction={
                      lastSortingVariableName == '' ||
                      lastSortingVariableName != 'created'
                        ? 'asc'
                        : 'desc'
                    }
                    style={{ color: 'white' }}
                    onClick={() => sortNfts('created')}>
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center" sx={{ width: '10%', color: 'white' }}>
                  Fake NFT
                </TableCell>
                <TableCell align="center" sx={{ width: '10%', color: 'white' }}>
                  Description
                </TableCell>
                <TableCell align="center" sx={{ color: 'white' }}>
                  <TableSortLabel
                    direction={
                      lastSortingVariableName == '' ||
                      lastSortingVariableName != 'score'
                        ? 'asc'
                        : 'desc'
                    }
                    style={{ color: 'white' }}
                    onClick={() => sortNfts('score')}>
                    Scores
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.tableRightBorder}
                  sx={{ width: '10%', color: 'white' }}>
                  Links
                </TableCell>
                <TableCell align="center" sx={{ color: 'white' }}>
                  Real NFT
                </TableCell>
                <TableCell align="center" sx={{ color: 'white' }}>
                  Description
                </TableCell>
                <TableCell align="center" sx={{ color: 'white' }}>
                  Conclusions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nfts &&
                nfts
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => (
                    <>
                      <TableRow
                        key={row?.id}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0
                          }
                        }}>
                        <TableCell component="th" scope="row" align="left">
                          <p>{new Date(row.created).toDateString()}</p>
                        </TableCell>
                        <TableCell
                          align="center"
                          className="OpenseaLinkStyle"
                          sx={{ '& img': { width: '120px' } }}>
                          <a href={row.fakeImageurl} target="_blank">
                            <img src={row.fakeImageurl} alt="" />
                          </a>
                        </TableCell>
                        <TableCell align="center">
                          <p>{row.fakeName}</p>
                          <p></p>
                          <p>{row?.fakeDescription}</p>
                        </TableCell>
                        <TableCell align="center">
                          <p style={{ fontWeight: 'bold' }}>
                            Total:{row?.score?.toFixed(4)}
                          </p>
                          <p></p>
                          <p>
                            Image: {row?.image_similarity_score?.toFixed(4)}
                          </p>
                          <p>Name: {row?.name_similarity_score?.toFixed(4)}</p>
                          <p>
                            Description:{' '}
                            {row?.description_similarity_score?.toFixed(4)}
                          </p>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`OpenseaLinkStyle ${classes.tableRightBorder}`}>
                          {row.fakeOpenseaUrl && (
                            <p>
                              {' '}
                              <a
                                href={row.fakeOpenseaUrl}
                                target="_blank"
                                alt="">
                                Opensea
                              </a>
                              <OpenInNewIcon
                                sx={{
                                  fontSize: '15px',
                                  mt: '-2px',
                                  ml: '3px',
                                  color: '#0b00b7'
                                }}
                              />
                            </p>
                          )}
                          <p></p>
                          <p>
                            {' '}
                            <a href={row.etherscanTrx} target="_blank" alt="">
                              Etherscan
                            </a>
                            <OpenInNewIcon
                              sx={{
                                fontSize: '15px',
                                mt: '-2px',
                                ml: '3px',
                                color: '#0b00b7'
                              }}
                            />
                          </p>
                          <p>TokenId: {row.fakeTokenId}</p>
                        </TableCell>
                        <TableCell
                          align="center"
                          className="OpenseaLinkStyle"
                          sx={{ '& img': { width: '120px' } }}>
                          <a href={row.imageBlockChainUrl} target="_blank">
                            <img src={row.imageBlockChainUrl} alt="" />
                          </a>
                        </TableCell>

                        <TableCell align="center" className="OpenseaLinkStyle">
                          <>
                            <p style={{ fontWeight: 'bold' }}>
                              Total:{row?.contractName}
                            </p>
                            <p></p>
                            <p>
                              {' '}
                              <a href={row.openseaUrl} target="_blank">
                                Opensea URI
                              </a>
                              <OpenInNewIcon
                                sx={{
                                  fontSize: '15px',
                                  mt: '-2px',
                                  ml: '3px',
                                  color: '#0b00b7'
                                }}
                              />
                            </p>
                            <p>TokenId: {row.tokenId}</p>
                          </>
                        </TableCell>
                        <TableCell>
                          <p style={{ fontWeight: 'bold' }}>
                            Is Fraud:{' '}
                            {row?.alchemyIsSpam ? 'True' : 'Suspected'}
                          </p>
                          <p></p>
                          {row?.ownerName && (
                            <p>
                              Fraud Owner Name:{' '}
                              <span style={{ fontWeight: 'bold' }}>
                                {row?.ownerName}
                              </span>
                            </p>
                          )}
                          <p>
                            Spam Classifications:{' '}
                            <span style={{ fontWeight: 'bold' }}>
                              {row?.alchemySpamClassifications
                                ? 'True'
                                : 'False'}
                            </span>
                          </p>
                          <p>
                            Account Verified:{' '}
                            <span style={{ fontWeight: 'bold' }}>
                              {row?.accountVerified ? 'True' : 'False'}
                            </span>
                          </p>
                          <p>
                            wasBlockedByMarketPlace:{' '}
                            <span style={{ fontWeight: 'bold' }}>
                              {row?.wasBlockedByMarketPlace ? 'True' : 'False'}
                            </span>
                          </p>
                          <p>
                            washTradingActivities:{' '}
                            <span style={{ fontWeight: 'bold' }}>
                              {row?.washTradingActivities ? 'True' : 'False'}
                            </span>
                          </p>
                          {row.socialNetwork && (
                            <p>
                              {' '}
                              <a
                                href={row.socialNetwork}
                                target="_blank"
                                alt="">
                                Social
                              </a>
                              <OpenInNewIcon
                                sx={{
                                  fontSize: '15px',
                                  mt: '-2px',
                                  ml: '3px',
                                  color: '#0b00b7'
                                }}
                              />
                            </p>
                          )}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={nfts.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Fragment>
    </>
  );
}

export default React.memo(TableNew, (prevProps, nextProps) => {
  // Only re-render if the 'data' prop has changed
  return prevProps.data === nextProps.data;
});
