import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';

import { ExampleWrapperSimple } from '../../layout-components';

import RegularTables1Example1 from '../../components/RegularTables1/RegularTables1Example1';

export default function FinancialSummary() {
  return (
    <Fragment>
      <PageTitle
        titleHeading="KPI - Financial activity and summary"
        titleDescription=""
      />

      <ExampleWrapperSimple sectionHeading="Basic">
        <RegularTables1Example1 />
      </ExampleWrapperSimple>
    </Fragment>
  );
}
