import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyC8_DwJtvzwJaOfz4C-2QbmE5N94sx0edY',
  authDomain: 'cyberweb3.firebaseapp.com'
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
