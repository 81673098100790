import React from 'react';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import LocalFloristOutlinedIcon from '@material-ui/icons/LocalFloristOutlined';

import {
    TextField,
    Paper,
    Tabs,
    Tab,
    Button,
    Grid
} from '@material-ui/core';

export default function MintingConfirmation(props) {
    const [values, setValues] = React.useState({
        id: props.location.state.id,
        productName: props.location.state.name,
        contractAddress: props.location.state.contractAddress,
        description: props.location.state.description,
        collectionId: props.location.state.collectionId,
        marketplaceId: props.location.state.marketplaceId,
        categoryId: props.location.state.categoryId,
        bidderChoice: props.location.state.listingMethod,
        monyType: props.location.state.monyType,
        mintedValue: props.location.state.mintedValue,
        nftCurrency: props.location.state.nftCurrency,
        price: props.location.state.ItemPrice,
        days: props.location.state.period
    });

    const styles = {
        icon: {
            alignContent: "center",
            width: "25%",
            height: "25%",
            marginTop: "5%",
            marginLeft: "35%"
        },
        h1: {
            // color: "green",
            marginLeft: "30%"
        },
        p: {
            textAlign: "center",
            fontSize: "150%"
        },
        b: {
            float: "right",
            fontSize: "150%"
        },
        pre: {
            fontSize: "150%",
            margin: "2px",
            padding: "2px"
        },
        vals: {
            display: "inline",
            marginLeft: "5%",
            fontSize: "100%"
        }
    }
    const mainColor = "secondary";

    console.log('\n------------------------------- in MintingConfirmation page, values are: ' + JSON.stringify(values))

    return (
        <div>
            <CheckCircleOutlineRoundedIcon
                color={mainColor}
                size={'large'}
                style={styles.icon} />
            <h1 style={styles.h1}>
                <LocalFloristOutlinedIcon color={mainColor} />
                Your NFT was created
                <LocalFloristOutlinedIcon color={mainColor} />
            </h1>

            <div style={{ marginTop: "2%" }}>
                <p style={styles.p}><b>NFT Id: </b> {values.id} </p>
                <p style={styles.p}><b>Item name:</b> {values.productName}</p>
                <hr></hr>
                <p style={styles.p}><b>Contract Address:</b> {values.contractAddress}</p>
                <hr></hr>
                <p style={styles.p}><b>Collection Id:</b> {values.collectionId}</p>
                <hr></hr>
                <p style={styles.p}><b>Marketplace:</b> {values.marketplaceId}</p>
                <hr></hr>
                <p style={styles.p}><b>Category:</b> {values.categoryId}</p>
                <hr></hr>
                <p style={styles.p}><b>Bidder Choice:</b> {values.bidderChoice}</p>
                <hr></hr>
                <p style={styles.p}><b>Price:</b> {values.mintedValue}  {values.nftCurrency}</p>
                <hr></hr>
                <p style={styles.p}><b>Days:</b> {values.days}</p>
                <hr></hr>

                <p style={styles.p}><b>Description:</b></p>
                <p style={styles.p}>{values.description}</p>
                <hr></hr>
            </div>
        </div>
    )
}
