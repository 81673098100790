import React, { Fragment, useEffect, useState } from 'react';
import axios from "axios";
import DigitalSignature from 'components/DigitalSignature';
import { ExampleWrapperSimple } from '../../layout-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import DigitalSignatureIconImage from 'assets/images/DigitalSignatureIconImage.png';
import UpdateIconImage from 'assets/images/UpdateIconImage.png';
import { PageTitle } from '../../layout-components';

import {
    TableBody,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Tabs,
    Tab,
    Checkbox,
    Input,
    Modal
} from '@material-ui/core';
import { withStyles } from '@material-ui/core';

export default function CollateralManagement(props) {
    // This version of state is full of bugs. Use ech variable state in future
    const [values, setValues] = React.useState({
        isTabPanelAndVaultLocationsHidden: false,
        tabValue: 0,
        collateralMovementState: "PENDING_APPROVAL",
        isTab1Hidden: false,

        storageLocations: [],
        chosenStorageLocation: "",
        vaultId: "",

        movements: [{}],
        chosenLoan: { collaterals: [] },

        isSubmittionHidden: true,
        showModalSubmit: false,

        isOpenSignatureModal: false,
        employeeFullName: "",
        signatureURL: null,

        proofImages: [],
        certification: null,
        returnedLinksToProofImages: new Map(),

        showAssetsValidationErrorMessage: false,
        showEmployeeFullNameValidationErrorMessage: false,
        showSignatureValidationErrorMessage: false,
        showProofsValidationErrorMessage: false,
        showCertificationValidationErrorMessage: false,

        approvedAssets: []
    });

    // This state version is more preferable.   const [values, setValues] = React.useState  is full of bugs
    const [cancellationReasons, setCancellationReasons] = useState([]);
    const [chosenCancellationReason, setChosenCancellationReason] = useState([]);

    const handleChangeTab = (event, newValue) => {
        console.log("in handleChangeTab: newValue" + newValue);

        if (newValue === 0) {
            setValues({
                ...values, ['tabValue']: newValue, ['collateralMovementState']: 'PENDING_APPROVAL'
            });
        }
        else if (newValue === 2) {
            setValues({
                ...values, ['tabValue']: newValue, ['collateralMovementState']: 'SUPPLYCHAIN_WORKER_APPROVED'
            });
        }
        

        else if (newValue === 4) {
            setValues({
                ...values, ['tabValue']: newValue, ['collateralMovementState']: 'ALLOCATED'
            });

        }
        else if (newValue === 6) {
            setValues({
                ...values, ['tabValue']: newValue, ['collateralMovementState']: 'PENDING_RELEASE'
            });
        }
        else if (newValue === 8) {
            setValues({
                ...values, ['tabValue']: newValue, ['collateralMovementState']: 'SUPPLYCHAIN_WORKER_DE_APPROVED'
            });
        }
        else if (newValue === 10) {
            setValues({
                ...values, ['tabValue']: newValue, ['collateralMovementState']: 'SUPPLYCHAIN_MANAGER_DE_APPROVED'
            });
        }
    };

    const styles = {
        vaultLocationSelect: {
            width: "20%",
            height: "2.5em",
            appearance: "menulist",
            marginBottom: "2%"
        },
        cancelationReasonSelect: {
            width: "100%",
            height: "2.5em",
            appearance: "menulist",
            cursor: "pointer"
        },
        input: {
            width: "100%"
        }
    }

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: "#3D4977",
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    useEffect(() => {
        async function fetchData() {
            await getVaults();
            getLoans();
         //   getCancellationReasons();
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            getLoans();
        }
        fetchData();
    }, [values.vaultId]);

    async function getCancellationReasons() {

        const jwtToken = localStorage.getItem('jwtToken');
        const config = {
            headers: { Authorization: `Bearer ${jwtToken}` }
        };
        const URL = process.env.REACT_APP_SAFELOAN_API_URL + '/LoanCollateralMovement/GetCancellationReason';


        axios.get(URL, config)
            .then(res => {
                if (res.data != null) {
                    setCancellationReasons(res.data);
                    setChosenCancellationReason(res.data[0]);
                }
            })
    }

    async function getVaults() {
        const URL = process.env.REACT_APP_SAFELOAN_API_URL + `/Vault/getAllVaults`;

        const jwtToken = localStorage.getItem('jwtToken');
        const config = {
            headers: { Authorization: `Bearer ${jwtToken}` }
        };


        await axios.get(URL, config)
            .then(res => {
                if (res.data != null) {


                    setValues({
                        ...values, ['storageLocations']: res.data, ['vaultId']: res.data[0].vaultId
                    });
                }
            })
    }

    async function getLoans() {
        const URL = process.env.REACT_APP_SAFELOAN_API_URL + '/LoanCollateralMovement/GetLoanCollateralMovements/' + values.vaultId;

        const jwtToken = localStorage.getItem('jwtToken');
        const config = {
            headers: { Authorization: `Bearer ${jwtToken}` }
        };

        axios.get(URL, config)
            .then(res => {
                if (res.data != null) {
                    setValues({ ...values, ['movements']: res.data });
                }
            })
    }

    async function getLoanDetails(chosenLoan) {

        const jwtToken = localStorage.getItem('jwtToken');
        const config = {
            headers: { Authorization: `Bearer ${jwtToken}` }
        };
        const URL = process.env.REACT_APP_SAFELOAN_API_URL + "/LoanCollateralMovement/GetLoanCollateralMovementDetails/" + chosenLoan.movementId + "/" + chosenLoan.relevantObjectId + "/" + values.collateralMovementState;

        axios.get(URL, config)
            .then(res => {
                if (res.data != null) {
                    setValues({
                        ...values, ['chosenLoan']: res.data, ['isTab1Hidden']: true, ['isSubmittionHidden']: false,
                        ['isTabPanelAndVaultLocationsHidden']: true, ['returnedLinksToProofImages']: res.data.proofImagesPreSignedUrl
                    });
                }
            }).catch(
                function (error) {
                    console.log("Error !!!!!!! " + error);
                    alert("Ops, something went wrote please try again");
                }
            )
    }

    function urltoFile(url, filename, mimeType) {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    function getNewMovementStatus() {
        if (values.collateralMovementState === "PENDING_APPROVAL") {
            return "SUPPLYCHAIN_WORKER_APPROVED";
        }
        else if (values.collateralMovementState === "SUPPLYCHAIN_WORKER_APPROVED") {
            return "ALLOCATED";
        }
       

        else if (values.collateralMovementState === "ALLOCATED") {
            return "PENDING_RELEASE";
        }

        else if (values.collateralMovementState === "PENDING_RELEASE") {
            return "SUPPLYCHAIN_WORKER_DE_APPROVED";
        }
        else if (values.collateralMovementState === "SUPPLYCHAIN_WORKER_DE_APPROVED") {
            return "SUPPLYCHAIN_MANAGER_DE_APPROVED";
        }
       
    }

    function isValidationOk() {
        let showAssetsValidationErrorMessage = false;
        let showEmployeeFullNameValidationErrorMessage = false;
        let showSignatureValidationErrorMessage = false;
        let showProofsValidationErrorMessage = false;
        let showCertificationValidationErrorMessage = false;

        if (approvedAssets.length == values.chosenLoan.collaterals.length) {
            showAssetsValidationErrorMessage = false;
        } else {
            showAssetsValidationErrorMessage = true;
        }

        if (values.employeeFullName.length > 5) {
            showEmployeeFullNameValidationErrorMessage = false;
        } else {
            showEmployeeFullNameValidationErrorMessage = true;
        }

        if (values.signatureURL != null) {
            showSignatureValidationErrorMessage = false;
        } else {
            showSignatureValidationErrorMessage = true;
        }

        if (values.proofImages.length > 0) {
            showProofsValidationErrorMessage = false;
        } else {
            showProofsValidationErrorMessage = true;
        }

        if (values.certification != null) {
            showCertificationValidationErrorMessage = false;
        } else if (isAddCertificationBlockHidden()) {
            showCertificationValidationErrorMessage = false;
        } else {
            showCertificationValidationErrorMessage = true;
        }



        setValues({
            ...values,
            ['showAssetsValidationErrorMessage']: showAssetsValidationErrorMessage,
            ['showEmployeeFullNameValidationErrorMessage']: showEmployeeFullNameValidationErrorMessage,
            ['showSignatureValidationErrorMessage']: showSignatureValidationErrorMessage,
            ['showProofsValidationErrorMessage']: showProofsValidationErrorMessage,
            ['showCertificationValidationErrorMessage']: showCertificationValidationErrorMessage
        });

        if (!showAssetsValidationErrorMessage &&
            !showEmployeeFullNameValidationErrorMessage &&
            !showSignatureValidationErrorMessage &&
            !showProofsValidationErrorMessage &&
            !showCertificationValidationErrorMessage) {
            return true;
        } else {
            return false;
        }
    }

    async function submit() {
        let validationOk = isValidationOk();

        if (validationOk) {
            const URL = process.env.REACT_APP_SAFELOAN_API_URL + '/LoanCollateralMovement/updateCollateralMovementDetails';

            const jwtToken = localStorage.getItem('jwtToken');
            const config = {
                headers: { Authorization: `Bearer ${jwtToken}` }
            };

            let convertedFile;
            await urltoFile(values.signatureURL, 'hello.png', 'image/png')
                .then(function (file) {
                    convertedFile = file;
                    const formData = new FormData();
                    formData.append('movementId', values.chosenLoan.movementId);
                    formData.append('loanId', values.chosenLoan.relevantObjectId);
                    formData.append('employeeFullName', values.employeeFullName);
                    formData.append('signature', convertedFile);
                    Array.from(values.proofImages).forEach(image => {
                        formData.append('proofImages', image);
                    });
                    formData.append('collateralMovementState', getNewMovementStatus());
                    formData.append('certification', values.certification);
                    console.log("---collateralMovementState, getNewMovementStatus: " + getNewMovementStatus)

                    axios.post(URL, formData, config)
                        .then(res => {
                            setValues({ ...values, ['returnedLinksToProofImages']: res.data });
                            alert("Successfully Approved");
                        }).catch(
                            function (error) {
                                console.log("Error !!!!!!! " + error);
                                alert("Ops, something went wrote please try again");
                            }
                        )
                }).catch(
                    function (error) {
                        console.log(error);
                        alert("failed to update");
                    }
                );

            sendRemind();
        } else {
            alert("Make sure all fields are filled out");
        }
    }

    async function sendRemind() {
        const URL = process.env.REACT_APP_SAFELOAN_API_URL + `/SendMail/RemindEmployeesWithChosenStatus/` + values.collateralMovementState;

        const jwtToken = localStorage.getItem('jwtToken');
        const config = {
            headers: { Authorization: `Bearer ${jwtToken}` }
        };

        axios.get(URL, config)
            .then(res => {
                if (res.data == true) {

                }
            }).catch(
                function (error) {
                    console.log("Error !!!!!!! " + error);
                    alert("Ops, something went wrote please try again");
                }
            )
    }

    async function cancellation() {
        if (values.employeeFullName.length > 5 && values.signatureURL != null) {
            const URL = process.env.REACT_APP_SAFELOAN_API_URL + '/LoanCollateralMovement/cancelCollateralMovementDetails';

            const formData = new FormData();
            formData.append('movementId', values.chosenLoan.movementId);
            formData.append('loanId', values.chosenLoan.relevantObjectId);
            formData.append('employeeFullName', values.employeeFullName);
            formData.append('cancelationReason', chosenCancellationReason);
            formData.append('collateralMovementState', values.collateralMovementState);

            const jwtToken = localStorage.getItem('jwtToken');
            const config = {
                headers: { Authorization: `Bearer ${jwtToken}` }
            };

            axios.put(URL, formData, config)
                .then(res => {
                    if (res.data != null) {
                        alert("Successfully Canceled");
                        hideSubmittion();
                    }
                }).catch(
                    function (error) {
                        console.log("Error !!!!!!! " + error);
                        alert("Ops, something went wrote please try again");
                    }
                )
        }

        let showEmployeeFullNameValidationErrorMessage = false;
        let showSignatureValidationErrorMessage = false;

        if (values.employeeFullName.length > 5) {
            showEmployeeFullNameValidationErrorMessage = false;
        } else {
            showEmployeeFullNameValidationErrorMessage = true;
        }

        if (values.signatureURL != null) {
            showSignatureValidationErrorMessage = false;
        } else {
            showSignatureValidationErrorMessage = true;
        }

        setValues({
            ...values,
            ['showEmployeeFullNameValidationErrorMessage']: showEmployeeFullNameValidationErrorMessage,
            ['showSignatureValidationErrorMessage']: showSignatureValidationErrorMessage
        });
    }

    function hideSubmittion() {
        const URL = process.env.REACT_APP_SAFELOAN_API_URL + '/LoanCollateralMovement/GetLoanCollateralMovements/' + values.vaultId;

        const jwtToken = localStorage.getItem('jwtToken');
        const config = {
            headers: { Authorization: `Bearer ${jwtToken}` }
        };

        axios.get(URL, config)
            .then(res => {
                if (res.data != null) {
                    setValues({
                        ...values,
                        ['isTab1Hidden']: false,
                        ['isSubmittionHidden']: true,
                        ['isTabPanelAndVaultLocationsHidden']: false,
                        ['employeeFullName']: "",
                        ['signatureURL']: null,
                        ['proofImages']: [],
                        ['certification']: null,
                        ['showAssetsValidationErrorMessage']: false,
                        ['showEmployeeFullNameValidationErrorMessage']: false,
                        ['showSignatureValidationErrorMessage']: false,
                        ['showProofsValidationErrorMessage']: false,
                        ['showCertificationValidationErrorMessage']: false,
                        ['movements']: res.data
                    });
                }
            })
    }

    const openSignatureModal = () => {
        setValues({ ...values, ['isOpenSignatureModal']: true });
    };

    const closeSignatureModal = () => {
        setValues({ ...values, ['isOpenSignatureModal']: false });
    };

    const onChangeSignature = data => {
        setValues({ ...values, ['signatureURL']: data.signatureURL, ['isOpenSignatureModal']: !data.closeModal });
    }

    const handleChangeFullNameOfApprover = event => {
        setValues({ ...values, ['employeeFullName']: event.target.value });
    }

    const updateProofImages = (e) => {
        let newArr = values.proofImages;
        console.log('updateProofImages' + JSON.stringify(e.target.files[0]))
        newArr.push(e.target.files[0]);
        console.log('updateProofImages newArr' + JSON.stringify(newArr))

        setValues({ ...values, ['proofImages']: newArr });
    }

    const updateCertificationImage = (e) => {
        alert("Certification uploaded!")
        setValues({ ...values, ['certification']: e.target.files[0] });
    }

    const onChangeSelect = (e) => {
        console.log(' e.target.value ' + e.target.value);
        setValues({ ...values, ['vaultId']: e.target.value });
    }

    let approvedAssets = [];
    const onChangeCheckbox = value => event => {
        let isContains = false;

        for (let i = 0; i < approvedAssets.length; i++) {
            if (approvedAssets[i] == value) {
                approvedAssets.splice(i);
                isContains = true;
            }
        }

        if (!isContains) {
            approvedAssets.push(value);
        }
        console.log(approvedAssets);
    }

    function isCheckboxChecked(value) {
        const approvedAssetsAsString = JSON.stringify(approvedAssets);
        setTimeout(() => {
            if (approvedAssetsAsString.includes(value)) {
                console.log(true); //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                return true;
            } else {
                console.log(false); //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                return false;
            }
        }, 9900);
    }

    const onChangeSelectCancellationReason = e => {
        setChosenCancellationReason(e.target.value);
    }

    function getMovementsQuantity(movementStatus) {
        let movementsQuantity = 0;

        for (let index = 0; index < values.movements.length; index++) {
            if (values.movements[index].collateralMovementState == movementStatus) {
                movementsQuantity++;
            }
        }

        return movementsQuantity;
    }

    function isAddCertificationBlockHidden() {
        if (values.collateralMovementState === "PENDING_APPROVAL") {
            return false;
        } else {
            return true;
        }
    }

    return (
        <div>
            <PageTitle
                titleHeading="Logistics Management"
                titleDescription=""
            />
            <div hidden={values.isTabPanelAndVaultLocationsHidden}>
                <p style={{ marginTop: "0.5%", margin: "0", padding: "0" }}>Vault location</p>
                <select className="dropdown" style={styles.vaultLocationSelect} onChange={onChangeSelect}>
                    {values.storageLocations.map(row => (
                        <option value={row.vaultId}>{row.vaultLocation}</option>
                    ))}
                </select>

            </div>


            <Paper square hidden={values.isTabPanelAndVaultLocationsHidden} >
                <Tabs
                    value={values.tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChangeTab}
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label={"Asset Allocation"} />
                    <span className="badge badge-primary">{getMovementsQuantity("PENDING_APPROVAL")}</span>
                    <Tab label={"Allocate Confirmation"} />
                    <span class="badge badge-primary">{getMovementsQuantity("SUPPLYCHAIN_WORKER_APPROVED")}</span>
                    <Tab label={"Allocated"} />
                    <span className="badge badge-primary"> {getMovementsQuantity("ALLOCATED")}</span>
                    <Tab label={"De allocate Worker "} />
                    <span class="badge badge-primary">{getMovementsQuantity("PENDING_RELEASE")}</span>
                    <Tab label={"De allocate Manager "} />
                    <span class="badge badge-primary">{getMovementsQuantity("SUPPLYCHAIN_WORKER_DE_APPROVED")}</span>
                   
                </Tabs>
            </Paper>

            <div hidden={values.isTab1Hidden}>
                <ExampleWrapperSimple>
                    <Fragment>
                        <TableContainer className="mb-4" component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Instruction Id</StyledTableCell>
                                        <StyledTableCell align="right">related asset</StyledTableCell>
                                        <StyledTableCell align="right">Vault Location</StyledTableCell>
                                        <StyledTableCell align="right">Submitted Date</StyledTableCell>
                                        <StyledTableCell align="right">Allocated Assets</StyledTableCell>
                                        <StyledTableCell align="right"></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {values.movements.filter(movement => (String("ALLOCATED").valueOf() === new String(movement.collateralMovementState).valueOf()) &&
                                        (String(movement.collateralMovementState).valueOf() === String(values.collateralMovementState).valueOf()))
                                        .sort((a, b) => {
                                            return new Date(b.submittedDate).getTime() -
                                                new Date(a.submittedDate).getTime()
                                        })
                                        .map(row => (
                                            <StyledTableRow key={row.movementId}>
                                                <TableCell>{row.collateralMovementType} \ {row.movementId}</TableCell>
                                                <TableCell align="right">{row.relevantObjectId}</TableCell>
                                                <TableCell align="right">{row.vaultLocation}</TableCell>
                                                <TableCell align="right">{new Date(row.submittedDate).toLocaleDateString("en-US")}</TableCell>
                                                <TableCell align="right">{row.collaterals}</TableCell>

                                                <TableCell align="right">

                                                    <Button disabled
                                                        color="secondary"
                                                        size="large"
                                                        variant="contained"
                                                        onClick={() => getLoanDetails(row)}
                                                    >Submit
                                                    </Button>

                                                </TableCell>

                                            </StyledTableRow>
                                        ))}

                                    {values.movements.filter(movement => (String(movement.collateralMovementState).valueOf() === String(values.collateralMovementState).valueOf()) &&
                                        String("ALLOCATED").valueOf() !== String(movement.collateralMovementState).valueOf())
                                        .sort((a, b) => {
                                            return new Date(b.submittedDate).getTime() -
                                                new Date(a.submittedDate).getTime()
                                        })
                                        .map(row => (
                                            <StyledTableRow key={row.movementId}>
                                                <TableCell>{row.collateralMovementType} \ {row.movementId}</TableCell>
                                                <TableCell align="right">{row.relevantObjectId}</TableCell>
                                                <TableCell align="right">{row.vaultLocation}</TableCell>
                                                <TableCell align="right">{new Date(row.submittedDate).toLocaleDateString("en-US")} {new Date(row.submittedDate).toLocaleTimeString("en-US")}</TableCell>
                                                <TableCell align="right">{row.collaterals}</TableCell>

                                                <TableCell align="right">

                                                    <Button
                                                        color="secondary"
                                                        size="large"
                                                        variant="contained"
                                                        onClick={() => getLoanDetails(row)}
                                                    >Submit
                                                    </Button>

                                                </TableCell>

                                            </StyledTableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Fragment>
                </ExampleWrapperSimple>
            </div>

            <div hidden={values.isSubmittionHidden}>
                <Button
                    size="large"
                    variant="contained"
                    onClick={hideSubmittion}>
                    Return To Search Results
                </Button>
                <div style={{ marginTop: "2%" }}>
                    <ExampleWrapperSimple sectionHeading="Asset Allocation" headerColor="#3D4977" headerTextColor="white">
                        <div className="row">
                            <div className="col-sm-12, col-md-4, col-lg-4, col-xl-4">
                                <p><b>Instruction Id:</b> {values.chosenLoan.movementId}</p>
                                <p><b>Relevant entity:</b> {values.chosenLoan.relevantObjectId}</p>
                                <p><b>Vault Location:</b> {values.chosenLoan.vaultLocation}</p>
                            </div>
                            <div className="col-sm-12, col-md-8, col-lg-8, col-xl-8">
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Product Name</TableCell>
                                            <TableCell align="right">Vendor</TableCell>
                                            <TableCell align="right">Asset Id</TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {values.chosenLoan.collaterals.map(row => (
                                            <StyledTableRow >
                                                <TableCell>{row}</TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"><Checkbox checked={isCheckboxChecked(row)} onChange={onChangeCheckbox(row)}></Checkbox></TableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <p hidden={!values.showAssetsValidationErrorMessage} style={{ color: "red" }}>All assets should be approved!</p>
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: "2%" }}>
                            <div className="col-4">
                                <Input
                                    style={styles.input}
                                    value={values.employeeFullName}
                                    placeholder="Type full name to sign"
                                    onChange={handleChangeFullNameOfApprover}>
                                </Input>
                                <p hidden={!values.showEmployeeFullNameValidationErrorMessage} style={{ color: "red" }}>Name Should Be At Least 5 Characters!</p>
                                <p hidden={!values.showSignatureValidationErrorMessage} style={{ color: "red" }}>Please add a signiture</p>
                            </div>
                            <div className="col-2">
                                <img style={{ cursor: "pointer" }} src={DigitalSignatureIconImage} onClick={openSignatureModal} />
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: "2%" }}>
                            <div className="col-4">
                                <p>Please upload the scan of calotteral proof with serial number</p>
                            </div>
                            <div className="col-2">
                                <label for="file-input">
                                    <img style={{ cursor: "pointer" }} src={UpdateIconImage} />
                                </label>
                                <input id="file-input" type="file" hidden={true} onChange={updateProofImages} />
                            </div>

                            <div className="col-6">
                                <Button
                                    color="secondary"
                                    size="large"
                                    variant="contained"
                                    onClick={submit}>
                                    Submit
                                </Button>
                            </div>
                            <p hidden={!values.showProofsValidationErrorMessage} style={{ color: "red" }}>You Should Load At Least One Proof, make sure that all file mame should be unique!</p>
                        </div>

                        <div hidden={isAddCertificationBlockHidden()} className="row" style={{ marginTop: "2%" }}>
                            <div className="col-4">
                                <p>Please load the certification of ownership</p>
                            </div>
                            <div className="col-2">
                                <label for="file-input2">
                                    <img style={{ cursor: "pointer" }} src={UpdateIconImage} />
                                </label>
                                <input id="file-input2" type="file" hidden={true} onChange={updateCertificationImage} />
                            </div>

                            <p hidden={!values.showCertificationValidationErrorMessage} style={{ color: "red" }}>please provide Certification of ownership</p>
                        </div>

                        <div hidden={values.chosenLoan == null || values.chosenLoan.certification == null || Object.entries(values.chosenLoan.certification).length == 0} className='row' style={{ marginTop: "2%" }}>
                            <div className="col-2">
                                <h6>Certification</h6>
                            </div>
                            <div className="col-2">
                                {values.chosenLoan != null && values.chosenLoan.certification != null ? Object.entries(values.chosenLoan.certification).map(([key, value]) => (
                                    <div>
                                        <a onClick={() => window.open(value, "_blank")} style={{ color: "blue", cursor: "pointer" }}>{key}</a>
                                    </div>
                                )) : ""}
                            </div>
                        </div>


                        <div hidden={values.chosenLoan.approvers == null || values.chosenLoan.approvers.length == 0} className='row' style={{ marginTop: "2%" }}>
                            <div className="col-6">
                                {values.chosenLoan.approvers != null ?
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Date</TableCell>
                                                <TableCell align="right">Status</TableCell>
                                                <TableCell align="right">Approved By</TableCell>
                                                <TableCell align="right">Link To Image Proof</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {values.chosenLoan.approvers != null ? values.chosenLoan.approvers.map(row => (
                                                <StyledTableRow >
                                                    <TableCell>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(row.operationDate)}</TableCell>
                                                    <TableCell align="right">{row.collateralMovementState}</TableCell>
                                                    <TableCell align="right">{row.approverFullName}</TableCell>
                                                    <TableCell align="right">
                                                        {row.linksToImageProfs != null ? Object.entries(row.linksToImageProfs).map(([key, value]) => (
                                                            <div>
                                                                <a onClick={() => window.open(value, "_blank")} style={{ color: "blue", cursor: "pointer" }}>{key}</a>
                                                            </div>
                                                        )) : ""}
                                                    </TableCell>
                                                </StyledTableRow>
                                            )) : ""}
                                        </TableBody>
                                    </Table>
                                    : ""}
                            </div>
                        </div>
                    </ExampleWrapperSimple>
                </div>

                <Modal
                    open={values.isOpenSignatureModal}
                    onClose={closeSignatureModal}
                    style={{
                        //position: "absolute",
                        top: "30%",
                        left: "25%"
                    }}
                >
                    <DigitalSignature
                        onChange={onChangeSignature} />
                </Modal>
            </div>

        </div>
    )
}
