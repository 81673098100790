import React, { Fragment, useEffect, useState } from "react";
import { PageTitle } from '../../layout-components';
import { useHistory } from "react-router-dom";
import axios from "axios";
import CreatePhysicalNftPopup from "components/CreatePhysicalNftPopup";

import {
    Button,
    Checkbox,
    Grid,
    Card,
    TableBody,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Divider,
    Paper,
    withStyles
} from '@material-ui/core';
import { ro } from "date-fns/locale";

import FormsWizardBasic from '../../components/FormsWizard/FormsWizardBasic';

const styles = {
    vaultLocationSelect: {
        width: "20%",
        height: "2.5em",
        appearance: "menulist",
        borderRadius: "5px 5px 5px 5px",
        borderWidth: "1px 1px 1px 1px"
    },
    table: {
        marginTop: "5%"
    },
    metalQuantitySelect: {
        appearance: "menulist"
    },
    Button: {
        float: "right"
    },
    subAccountsSelect: {
        height: "2.5em",
        appearance: "menulist"
    },
    metalQuantitySelect: {
        appearance: "menulist"
    }
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#3D4977",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const MintingAsset = (props) => {

    const [subAccounts, setSubAccounts] = useState([]);
    const [chosenSubAccountId, setChosenSubAccountId] = useState('');
    const [eligibleAssets, setEligibleAssets] = useState([]);

    let history = useHistory();




    useEffect(() => {
        if (chosenSubAccountId === '') {
            setPartnerSubAccountsAndEligabilities();
        }
    }, [chosenSubAccountId]);

    async function getParsedAssets(row) {
        var parsedAssets = eligibleAssets.reduce(function (map, obj) {
            if (obj.itemGuid == row.itemGuid) {
                map[obj.productId] = 1; // 
            }

            return map;
        }, {});

        return parsedAssets;
    }

    async function nextMintingAsset(row) {

        console.log('nextMintingAsset');
        const token = localStorage.getItem('jwtToken');

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        // Data For Request
        const data = {
            //assetOwnerID: user.username,           
            vaultAccountId: chosenSubAccountId,
            collateralAssets: await getParsedAssets(row)
        }

        if (Object.keys(data.collateralAssets).length < 1) {
            alert("Value must be greater then 0 !");
            return;
        }
        else if (Object.keys(data.collateralAssets).length > 1) {
            alert("Choose only 1 category!");
            return;
        }
        console.log('send CreateMintRequest ' + JSON.stringify(row));

        // TODO: validation is required!
        axios.post(process.env.REACT_APP_SAFELOAN_API_URL + '/MintingAsset/CreateMintRequest', data, config)
            .then(res => {

                const pushData = {
                    nftRequestId: res.data.nftRequestId,
                    mintedAmount: null,
                    period: 0,
                    itemName: null,
                    externalLink: null,
                    categoryId: null,
                    marketplaceId: null,
                    itemDescription: null,
                    vaultAccountId: chosenSubAccountId,
                    collateralAssets: data.collateralAssets,
                    nftCollections: res.data.nftCollections,

                    endingDatePeriod: null,
                    accountId: null,
                    chosenAsset: row
                }

                history.push("/ListAsset", pushData);
            }).catch(
                function (error) {
                    alert("Sorry, ERROR in CreateMintRequest " + error);
                }
            )
    }

    const handleChangeCollateralQuantity = assetId => event => {

        let newCollateralQuantity = event.target.value;
        let assets = eligibleAssets;

        for (let index = 0; index < assets.length; index++) {
            if (assets[index].productId === assetId) {
                // Rony said it should be always 1
                assets[index].collateralQuantity = 1; // newCollateralQuantity;
            }
        }


        setEligibleAssets(assets);
    }


    function getOptionsForSelectQuantity(quantity) {
        let optionsForSelectQuantity = [];


        for (let index = 0; index <= quantity; index++) {
            optionsForSelectQuantity.push(index);
        }

        return optionsForSelectQuantity;
    }

    async function setPartnerSubAccountsAndEligabilities() {

        const token = localStorage.getItem('jwtToken');
        console.log('REACT_APP_SAFELOAN_API_URL' + process.env.REACT_APP_SAFELOAN_API_URL);


        const URL = process.env.REACT_APP_SAFELOAN_API_URL + '/PartnerAdmin/GetPartnerAssetSubAccounts';

        console.log(URL);
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        await axios.get(URL, config)
            .then(res => {
                if (res.data != null) {

                    console.log('data selected raw, after calling GetPartnerAssetSubAccounts: ' + JSON.stringify(res.data));
                    setSubAccounts(res.data);
                    setChosenSubAccountId(res.data[0].vaultAccountId);
                    setEligibleAssetsData(res.data[0].vaultAccountId);
                    localStorage.setItem("vaultAccountId", res.data[0].vaultAccountId);
                }

            })
    }


    const onChangeSelect = (e) => {


        console.log('e.target.value' + e.target.value);
        setChosenSubAccountId(e.target.value);
        setEligibleAssetsData(e.target.value);
        localStorage.setItem("vaultAccountId", e.target.value)
    }

    async function setEligibleAssetsData(vaultAccountId) {

        const token = localStorage.getItem('jwtToken');
        const urlAssets = process.env.REACT_APP_SAFELOAN_API_URL + "/PartnerAdmin/GetPartnerEligibleAssets/" + vaultAccountId;
        const configAssets = {
            headers: { Authorization: `Bearer ${token}` }
        };

        axios.get(urlAssets, configAssets)
            .then(res => {
                if (res.data != null) {
                    setEligibleAssets(res.data);
                }
            })
    }

    return (
        <div>
            <PageTitle
                titleHeading="Physical inventory"
                titleDescription=""
            />

            <Grid container spacing={4}>
                <Grid item lg={6}>
                    <div className="card-header--actions">
                        <select className="dropdown" onChange={onChangeSelect} style={styles.subAccountsSelect}>
                            {subAccounts.map(row => (
                                <option value={row.vaultAccountId} >{row.vaultAccountLocation}</option>
                            ))}
                        </select>
                    </div>
                </Grid>
                <Grid item lg={2}>
                    <CreatePhysicalNftPopup />
                </Grid>
                <Grid item lg={2}>
                    <Button
                        color="primary"
                        size="large"
                        variant="outlined"
                    >Read From File</Button>
                </Grid>
                <Grid item lg={2}>
                    <a style={{ color: "blueviolet", cursor: "pointer" }}>download template</a>
                </Grid>
            </Grid>


            <Grid container spacing={4}>
                <Grid item lg={9}>
                    <TableContainer className="mb-4" component={Paper} style={styles.table}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Category</StyledTableCell>
                                    <StyledTableCell>Product Name</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>Quantity</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {eligibleAssets.map(row => (
                                    <StyledTableRow >
                                        <TableCell> {row.productCategory}</TableCell>
                                        <TableCell>

                                            {row.productName} </TableCell>
                                        <TableCell>
                                            <img src={row.imageUrl} width="60" height="60" />
                                        </TableCell>
                                        <TableCell>
                                            {row.quantity}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                style={styles.Button}
                                                color="secondary"
                                                size="large"
                                                variant="contained"
                                                onClick={() => nextMintingAsset(row)}
                                            >Choose To Mint
                                            </Button>
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Divider className="my-1" />
            <div className="card-header">
                <div className="card-header--title font-weight-bold">
                </div>
            </div>

            <Grid container spacing={4}>
                <Grid item lg={9}>
                    <FormsWizardBasic activeStep={0} />
                </Grid>
            </Grid>
        </div>
    )
}
export default MintingAsset;
