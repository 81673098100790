import React, { useEffect } from 'react';
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Button,
    TableBody,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    withStyles,
    Paper,
    Checkbox
} from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#3D4977",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

export default function UserManagement() {
    const [values, setValues] = React.useState({
        users: [],

        // New User
        newUserEmail: "",
        newUserMobile: "",
        newUserFirstName: "",
        newUserLastName: "",
        newUserRole: "",
        checkboxCollateralManagementChecked: false,
        checkboxCollateralApproverChecked: false,
        checkboxViewerChecked: false,
        checkboxAdminChecked: false,
        checkboxUserManagementPagePermissionChecked: false,
        checkboxFinancialActivitiesPagePermissionChecked: false,
        checkboxRefferalNewCustomerPagePermissionChecked: false,
        checkboxAssetAllocationPagePermissionChecked: false,

        checkboxLenderPortalPagePermissionChecked: false,
        checkboxCastodianDashboardPagePermissionChecked: false,
        checkboxCollateralManagementPagePermissionChecked: false,
        checkboxMintingPagePermissionChecked:false,
        checkboxMintingManagementPagePermissionChecked:false,
        showValidationErrorMessage: false,

        id: "", // For update
        username: "", // For update
        isSaveMode: true // if false updateMode
    });

    const styles = {
        inputForCreateUser: {
            width: "70%",
            marginLeft: "1%",
            float: "right"
        },
        label: {
            fontSize: "150%"
        }
    }

    useEffect(() => {
        getAllUsers();
    }, []);
    useEffect(() => {
        setUserRoles();
    },
        [
            values.checkboxCollateralManagementChecked,
            values.checkboxCollateralApproverChecked,
            values.checkboxViewerChecked,
            values.checkboxAdminChecked,
            values.checkboxUserManagementPagePermissionChecked,
            values.checkboxFinancialActivitiesPagePermissionChecked,
            values.checkboxRefferalNewCustomerPagePermissionChecked,
            values.checkboxAssetAllocationPagePermissionChecked,

            values.checkboxLenderPortalPagePermissionChecked,
            values.checkboxCastodianDashboardPagePermissionChecked,
            values.checkboxCollateralManagementPagePermissionChecked,
            values.checkboxMintingPagePermissionChecked,
            values.checkboxMintingManagementPagePermissionChecked

        ]);

    async function getAllUsers() {
        const jwtToken = localStorage.getItem('jwtToken');
        

        const URL = process.env.REACT_APP_SAFELOAN_API_URL + '/UserManagement/getAll/';
        const config = {
            headers: { Authorization: `Bearer ${jwtToken}` }
        };

        axios.get(URL, config)
            .then(res => {
                setValues({ ...values, ['users']: res.data });
            }).catch(
                function (error) {
                    console.log("Error !!!!!!! " + error);
                    if (error == "Error: Request failed with status code 400") {
                        alert("Please, make sure that your username, password and partner id correct");
                    }
                    else if (
                        error == "Error: Request failed with status code 401" ||
                        error == "Error: Request failed with status code 403" ||
                        error == "Error: Request failed with status code 407" ||
                        error == "Error: Request failed with status code 500") {
                        alert("Sorry, you are denied");
                    }
                    else {
                        alert("Please make sure your internet connection stable. If yes, write to support team.");
                    }
                })
    }

    async function createUser() {
        if (values.newUserEmail != "" && values.newUserFirstName != "" && values.newUserLastName
            && values.newUserMobile != "" && values.newUserRole.length != 0) {
            const jwtToken = localStorage.getItem('jwtToken');
            const config = {
                headers: { Authorization: `Bearer ${jwtToken}` }
            };
            const URL = process.env.REACT_APP_SAFELOAN_API_URL + `/UserManagement/create`;

            const newUser = {
                
                email: values.newUserEmail,
                mobile: values.newUserMobile,
                firstName: values.newUserFirstName,
                lastName: values.newUserLastName,
                roles: values.newUserRole
            }

            axios.post(URL, newUser, config)
                .then(res => {
                    getAllUsers();

                    setValues({
                        ...values,
                        ["showValidationErrorMessage"]: false,
                        ['id']: "", ['newUserEmail']: "", ['newUserMobile']: "",
                        ['newUserFirstName']: "", ['newUserLastName']: "",
                        ['newUserRole']: [], ['isSaveMode']: true,
                        ['checkboxCollateralManagementChecked']: false,
                        ['checkboxCollateralApproverChecked']: false,
                        ['checkboxViewerChecked']: false,
                        ['checkboxAdminChecked']: false,
                        ['checkboxUserManagementPagePermissionChecked']: false,
                        ['checkboxFinancialActivitiesPagePermissionChecked']: false,
                        ['checkboxRefferalNewCustomerPagePermissionChecked']: false,
                        ['checkboxAssetAllocationPagePermissionChecked']: false,

                        ['checkboxLenderPortalPagePermissionChecked']: false,
                        ['checkboxCastodianDashboardPagePermissionChecked']: false,
                        ['checkboxCollateralManagementPagePermissionChecked']: false,

                        ["showValidationErrorMessage"]: false
                    });
                    alert("New user successfully saved");
                }).catch(
                    function (error) {
                        console.log("Error !!!!!!! " + error);
                        if (error == "Error: Request failed with status code 400") {
                            alert("Please, make sure that your username, password and partner id correct");
                        }
                        else if (
                            error == "Error: Request failed with status code 401" ||
                            error == "Error: Request failed with status code 403" ||
                            error == "Error: Request failed with status code 407" ||
                            error == "Error: Request failed with status code 500") {
                            alert("Sorry, you are denied");
                        }
                        else {
                            alert("Please make sure your internet connection stable. If yes, write to support team.");
                        }
                    })
        } else {
            setValues({ ...values, ["showValidationErrorMessage"]: true });
        }
    }

    function updateUser() {
        if (values.newUserEmail != "" && values.newUserFirstName != "" && values.newUserLastName
            && values.newUserMobile != "" && values.newUserRole.length != 0) {
            const URL = process.env.REACT_APP_SAFELOAN_API_URL + `/UserManagement/update`;
            const jwtToken = localStorage.getItem('jwtToken');

            const config = {
                headers: { Authorization: `Bearer ${jwtToken}` }
            };

            const userForUpdate = {
                id: values.id,
                email: values.newUserEmail,
                mobile: values.newUserMobile,
                firstName: values.newUserFirstName,
                lastName: values.newUserLastName,
                roles: values.newUserRole,
                username: values.username
            }

            axios.post(URL, userForUpdate, config)
                .then(res => {
                    getAllUsers();

                    setValues({
                        ...values,
                        ["showValidationErrorMessage"]: false,
                        ['id']: "", ['newUserEmail']: "", ['newUserMobile']: "",
                        ['newUserFirstName']: "", ['newUserLastName']: "",
                        ['newUserRole']: [], ['isSaveMode']: true,
                        ['checkboxCollateralManagementChecked']: false,
                        ['checkboxCollateralApproverChecked']: false,
                        ['checkboxViewerChecked']: false,
                        ['checkboxAdminChecked']: false,
                        ['checkboxUserManagementPagePermissionChecked']: false,
                        ['checkboxFinancialActivitiesPagePermissionChecked']: false,
                        ['checkboxRefferalNewCustomerPagePermissionChecked']: false,
                        ['checkboxAssetAllocationPagePermissionChecked']: false,

                        ['checkboxLenderPortalPagePermissionChecked']: false,
                        ['checkboxCastodianDashboardPagePermissionChecked']: false,
                        ['checkboxCollateralManagementPagePermissionChecked']: false,
                        ['checkboxMintingPagePermissionChecked']:false,
                        ['checkboxMintingManagementPagePermissionChecked']:false,
                        ["showValidationErrorMessage"]: false
                    });
                    alert("User successfully updated");
                }).catch(
                    function (error) {
                        console.log(error);
                        if (error == "Error: Request failed with status code 400") {
                            alert("Please, make sure that your username, password and partner id correct");
                        }
                        else if (
                            error == "Error: Request failed with status code 401" ||
                            error == "Error: Request failed with status code 403" ||
                            error == "Error: Request failed with status code 407" ||
                            error == "Error: Request failed with status code 500") {
                            alert("Sorry, you are denied");
                        }
                        else {
                            alert("Please make sure your internet connection stable. If yes, write to support team.");
                        }
                    })
        } else {
            setValues({ ...values, ["showValidationErrorMessage"]: true });
        }
    }

    function backToSaveMode() {
        setValues({ ...values, ['isSaveMode']: true });
        setValues({
            ...values,
            ['id']: "", ['newUserEmail']: "", ['newUserMobile']: "",
            ['newUserFirstName']: "", ['newUserLastName']: "",
            ['newUserRole']: [], ['isSaveMode']: true,
            ['checkboxCollateralManagementChecked']: false,
            ['checkboxCollateralApproverChecked']: false,
            ['checkboxViewerChecked']: false,
            ['checkboxAdminChecked']: false,
            ['checkboxUserManagementPagePermissionChecked']: false,
            ['checkboxFinancialActivitiesPagePermissionChecked']: false,
            ['checkboxRefferalNewCustomerPagePermissionChecked']: false,
            ['checkboxAssetAllocationPagePermissionChecked']: false,

            ['checkboxLenderPortalPagePermissionChecked']: false,
            ['checkboxCastodianDashboardPagePermissionChecked']: false,
            ['checkboxCollateralManagementPagePermissionChecked']: false,
            ['checkboxMintingPagePermissionChecked']:false,
            ['checkboxMintingManagementPagePermissionChecked']:false,

            ["showValidationErrorMessage"]: false
        }
        );
    }

    const onChangeInputs = variableName => event => {
        setValues({ ...values, [variableName]: event.target.value });
    };

    const onChangeCheckBoxes = variableName => event => {
        setValues({ ...values, [variableName]: event.target.checked });
    }

    const setUserRoles = () => {
        let userRoles = [];

        if (values.checkboxCollateralManagementChecked) {
            userRoles.push("COLLATERAL_MANAGER");
        }
        if (values.checkboxCollateralApproverChecked) {
            userRoles.push("COLLATERAL_APPROVER");
        }
        if (values.checkboxViewerChecked) {
            userRoles.push("VIEWER");
        }
        if (values.checkboxAdminChecked) {
            userRoles.push("ADMIN");
        }
        if (values.checkboxUserManagementPagePermissionChecked) {
            userRoles.push("USER_MANGEMENT_PAGE_PERMISSION");
        }
        if (values.checkboxFinancialActivitiesPagePermissionChecked) {
            userRoles.push("FINANCIAL_ACTIVITIES_PAGE_PERMISSION");
        }
        if (values.checkboxRefferalNewCustomerPagePermissionChecked) {
            userRoles.push("REFFERAL_NEW_CUSTOMER_PAGE_PERMISSION");
        }
        if (values.checkboxAssetAllocationPagePermissionChecked) {
            userRoles.push("ASSET_ALLOCATION_PAGE_PERMISSION");
        }
        
        if (values.checkboxLenderPortalPagePermissionChecked) {
            userRoles.push("LENDER_PORTAL_PAGE_PERMISSION");
        }
        if (values.checkboxCastodianDashboardPagePermissionChecked) {
            userRoles.push("CASTODIAN_DASHBOARD_PAGE_PERMISSION");
        }
        if (values.checkboxCollateralManagementPagePermissionChecked) {
            userRoles.push("COLLATERAL_MANAGEMENT_PAGE_PERMISSION");
        }
        if(values.checkboxMintingPagePermissionChecked){
            userRoles.push("CREATE_MINT_PAGE_PERMISSION");
        }
        if(values.checkboxMintingManagementPagePermissionChecked){
            userRoles.push("MINT_MANAGEMENT_PAGE_PERMISSION");
        }
        setValues({ ...values, ["newUserRole"]: userRoles });
    }

    return (
        <div>
            <div className="row" style={{ marginTop: "1%" }}>
                <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                    <TableContainer className="mb-4" component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>User Id</StyledTableCell>
                                    <StyledTableCell align="right">First Name</StyledTableCell>
                                    <StyledTableCell align="right">Last Name</StyledTableCell>
                                    <StyledTableCell align="right">Roles/Permissions</StyledTableCell>
                                    <StyledTableCell align="right">Email</StyledTableCell>
                                    <StyledTableCell align="right">Mobile</StyledTableCell>
                                    <StyledTableCell align="right"></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {values.users != null ? values.users.map(row => (
                                    <StyledTableRow>
                                        <TableCell align="right">{row.id}</TableCell>
                                        <TableCell align="right">{row.firstName}</TableCell>
                                        <TableCell align="right">{row.lastName}</TableCell>
                                        <TableCell align="right">
                                            {(row.roles != null
                                                ? row.roles.map(row1 => (
                                                    <p>{row1}</p>
                                                ))
                                                : '')}
                                        </TableCell>
                                        <TableCell align="right">{row.email}</TableCell>
                                        <TableCell align="right">{row.mobile}</TableCell>
                                        <TableCell align="right">
                                            <Button color="secondary" size="large" variant="outlined" onClick={() => {
                                                let checkboxCollateralManagementChecked = false;
                                                let checkboxCollateralApproverChecked = false;
                                                let checkboxViewerChecked = false;
                                                let checkboxAdminChecked = false;
                                                let checkboxReferCustomer = false;
                                                let checkboxUserManagementPagePermissionChecked = false;
                                                let checkboxFinancialActivitiesPagePermissionChecked = false;
                                                let checkboxRefferalNewCustomerPagePermissionChecked = false;
                                                let checkboxAssetAllocationPagePermissionChecked = false;

                                                let checkboxLenderPortalPagePermissionChecked = false;
                                                let checkboxCastodianDashboardPagePermissionChecked = false;
                                                let checkboxMintingPagePermissionChecked= false;
                                                let checkboxMintingManagementPagePermissionChecked= false;
                                                let checkboxCollateralManagementPagePermissionChecked = false;

                                                if (row.roles != null) {
                                                    for (let i = 0; i < row.roles.length; i++) {
                                                        if (row.roles[i] == "COLLATERAL_MANAGER") {
                                                            checkboxCollateralManagementChecked = true;
                                                        }
                                                        if (row.roles[i] == "COLLATERAL_APPROVER") {
                                                            checkboxCollateralApproverChecked = true;
                                                        }
                                                        if (row.roles[i] == "VIEWER") {
                                                            checkboxViewerChecked = true;
                                                        }
                                                        if (row.roles[i] == "ADMIN") {
                                                            checkboxAdminChecked = true;
                                                        }
                                                        if (row.roles[i] == "REFER_CUSTOMER") {
                                                            checkboxReferCustomer = true;
                                                        }
                                                        if (row.roles[i] == "USER_MANGEMENT_PAGE_PERMISSION") {
                                                            checkboxUserManagementPagePermissionChecked = true;
                                                        }
                                                        if (row.roles[i] == "FINANCIAL_ACTIVITIES_PAGE_PERMISSION") {
                                                            checkboxFinancialActivitiesPagePermissionChecked = true;
                                                        }
                                                        if (row.roles[i] == "REFFERAL_NEW_CUSTOMER_PAGE_PERMISSION") {
                                                            checkboxRefferalNewCustomerPagePermissionChecked = true;
                                                        }
                                                        if (row.roles[i] == "ASSET_ALLOCATION_PAGE_PERMISSION") {
                                                            checkboxAssetAllocationPagePermissionChecked = true;
                                                        }
                                                        if (row.roles[i] == "CREATE_MINT_PAGE_PERMISSION") {
                                                            checkboxMintingPagePermissionChecked= true;
                                                        }
                                                        if (row.roles[i] == "MINT_MANAGEMENT_PAGE_PERMISSION") {
                                                            checkboxMintingManagementPagePermissionChecked= true;
                                                        }
                                                        if (row.roles[i] == "LENDER_PORTAL_PAGE_PERMISSION") {
                                                            checkboxLenderPortalPagePermissionChecked = true;
                                                        }
                                                        if (row.roles[i] == "CASTODIAN_DASHBOARD_PAGE_PERMISSION") {
                                                            checkboxCastodianDashboardPagePermissionChecked = true;
                                                        }
                                                        if (row.roles[i] == "COLLATERAL_MANAGEMENT_PAGE_PERMISSION") {
                                                            checkboxCollateralManagementPagePermissionChecked = true;
                                                        }
                                                    }
                                                }

                                                setValues({
                                                    ...values,
                                                    ['id']: row.id, ['username']: row.username, ['newUserEmail']: row.email, ['newUserMobile']: row.mobile,
                                                    ['newUserFirstName']: row.firstName, ['newUserLastName']: row.lastName,
                                                    ['newUserRole']: row.roles, ['isSaveMode']: false,
                                                    ['checkboxCollateralManagementChecked']: checkboxCollateralManagementChecked,
                                                    ['checkboxCollateralApproverChecked']: checkboxCollateralApproverChecked,
                                                    ['checkboxViewerChecked']: checkboxViewerChecked,
                                                    ['checkboxAdminChecked']: checkboxAdminChecked,
                                                    ['checkboxUserManagementPagePermissionChecked']: checkboxUserManagementPagePermissionChecked,
                                                    ['checkboxFinancialActivitiesPagePermissionChecked']: checkboxFinancialActivitiesPagePermissionChecked,
                                                    ['checkboxRefferalNewCustomerPagePermissionChecked']: checkboxRefferalNewCustomerPagePermissionChecked,
                                                    ['checkboxAssetAllocationPagePermissionChecked']: checkboxAssetAllocationPagePermissionChecked,

                                                    ['checkboxLenderPortalPagePermissionChecked']: checkboxLenderPortalPagePermissionChecked,
                                                    ['checkboxCastodianDashboardPagePermissionChecked']: checkboxCastodianDashboardPagePermissionChecked,
                                                    ['checkboxCollateralManagementPagePermissionChecked']: checkboxCollateralManagementPagePermissionChecked,
                                                    ['checkboxMintingPagePermissionChecked']: checkboxMintingPagePermissionChecked,
                                                    ['checkboxMintingManagementPagePermissionChecked']: checkboxMintingManagementPagePermissionChecked
                                                }
                                                );
                                            }}>
                                                Update
                                            </Button>
                                        </TableCell>
                                    </StyledTableRow>
                                )) : ''}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div>
                        <label style={styles.label}>email</label>
                        <input value={values.newUserEmail} style={styles.inputForCreateUser} onChange={onChangeInputs("newUserEmail")}></input>
                    </div>
                    <div>
                        <label style={styles.label}>mobile</label>
                        <input value={values.newUserMobile} style={styles.inputForCreateUser} onChange={onChangeInputs("newUserMobile")}></input>
                    </div>
                    <div>
                        <label style={styles.label}>first name</label>
                        <input value={values.newUserFirstName} style={styles.inputForCreateUser} onChange={onChangeInputs("newUserFirstName")}></input>
                    </div>
                    <div>
                        <label style={styles.label}>last name</label>
                        <input value={values.newUserLastName} style={styles.inputForCreateUser} onChange={onChangeInputs("newUserLastName")}></input>
                    </div>

                    <p hidden={!values.showValidationErrorMessage} style={{ color: "red" }}>All inputs and Role should be filled!</p>

                    <div style={{ marginTop: "5%" }}>
                        <label style={styles.label}>Roles</label>
                        <div>
                            <Checkbox checked={values.checkboxCollateralManagementChecked} onChange={onChangeCheckBoxes("checkboxCollateralManagementChecked")} />
                            <label style={styles.label}>Collateral Manager</label>
                        </div>
                        <div>
                            <Checkbox checked={values.checkboxCollateralApproverChecked} onChange={onChangeCheckBoxes("checkboxCollateralApproverChecked")} />
                            <label style={styles.label}>Collateral Approver</label>
                        </div>
                        <div>
                            <Checkbox checked={values.checkboxViewerChecked} onChange={onChangeCheckBoxes("checkboxViewerChecked")} />
                            <label style={styles.label}>Viewer</label>
                        </div>
                        <div>
                            <Checkbox checked={values.checkboxAdminChecked} onChange={onChangeCheckBoxes("checkboxAdminChecked")} />
                            <label style={styles.label}>Admin</label>
                        </div>
                    </div>
                    <div style={{ marginTop: "5%" }}>
                        <label style={styles.label}>Pages permissions</label>
                        <div>
                            <Checkbox checked={values.checkboxUserManagementPagePermissionChecked} onChange={onChangeCheckBoxes("checkboxUserManagementPagePermissionChecked")} />
                            <label style={styles.label}>User Management</label>
                        </div>
                        <div>
                            <Checkbox checked={values.checkboxFinancialActivitiesPagePermissionChecked} onChange={onChangeCheckBoxes("checkboxFinancialActivitiesPagePermissionChecked")} />
                            <label style={styles.label}>Financial Activities</label>
                        </div>
                        <div>
                            <Checkbox checked={values.checkboxRefferalNewCustomerPagePermissionChecked} onChange={onChangeCheckBoxes("checkboxRefferalNewCustomerPagePermissionChecked")} />
                            <label style={styles.label}>Refferal New Customer</label>
                        </div>
                        <div>
                            <Checkbox checked={values.checkboxAssetAllocationPagePermissionChecked} onChange={onChangeCheckBoxes("checkboxAssetAllocationPagePermissionChecked")} />
                            <label style={styles.label}>Asset Allocation</label>
                        </div>


                        <div>
                            <Checkbox checked={values.checkboxLenderPortalPagePermissionChecked} onChange={onChangeCheckBoxes("checkboxLenderPortalPagePermissionChecked")} />
                            <label style={styles.label}>Lender Portal</label>
                        </div>
                        <div>
                            <Checkbox checked={values.checkboxCastodianDashboardPagePermissionChecked} onChange={onChangeCheckBoxes("checkboxCastodianDashboardPagePermissionChecked")} />
                            <label style={styles.label}>Castodian Dashboard</label>
                        </div>
                        <div>
                            <Checkbox checked={values.checkboxCollateralManagementPagePermissionChecked} onChange={onChangeCheckBoxes("checkboxCollateralManagementPagePermissionChecked")} />
                            <label style={styles.label}>Logistics Management</label>
                        </div>
                        <div>
                            <Checkbox checked={values.checkboxMintingPagePermissionChecked} onChange={onChangeCheckBoxes("checkboxMintingPagePermissionChecked")} />
                            <label style={styles.label}>Minting</label>
                        </div>
                        <div>
                            <Checkbox checked={values.checkboxMintingManagementPagePermissionChecked} onChange={onChangeCheckBoxes("checkboxMintingManagementPagePermissionChecked")} />
                            <label style={styles.label}>Minting Management</label>
                        </div>
                    </div>
                    <Button hidden={!values.isSaveMode} onClick={createUser} style={{ float: "right" }} size="large" variant="contained">CREATE USER</Button>
                    <Button hidden={values.isSaveMode} onClick={updateUser} style={{ float: "right" }} size="large" variant="contained">UPDATE USER</Button>
                    <Button hidden={values.isSaveMode} onClick={backToSaveMode} style={{ float: "right", marginRight: "5%" }} size="large" variant="contained">Back To Save</Button>
                </div>
            </div>
        </div>
    )
}
