import React, { Fragment ,useEffect, useState} from 'react';
import axios from 'axios';
import { PageTitle } from '../../layout-components';
import PropTypes from 'prop-types';
import { ExampleWrapperSimple } from '../../layout-components';
import SimpleReactValidator from 'simple-react-validator';

import {
  Button,
  TextField,
  Tabs,
  Box,
  Tab,
  Typography,
  Paper
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import invitationsExample from 'assets/files/invitationsExample.csv';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '6px',
    '& > div': {
      maxWidth: 40,
      height: '4px',
      borderRadius: '25px',
      width: '100%',
      backgroundColor: '#000'
    }
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: theme.palette.primary[900],
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1
    }
  }
}))(props => <Tab disableRipple {...props} />);

const styles = {
  validationMessage: {
    color: "red"
  },
  vaultLocationSelect: {
    width: "20%",
    height: "2.5em",
    appearance: "menulist",
    marginBottom: "2%"
},
}

const CustomerReferral = (props) => {
  const [values, setValues] = React.useState({
    EmailAddress: "",
    FirstName: "",
    LastName: "",
    parterAssetOwnerId: "",
    subAccount: "",
    fileWithInvitations: null,
    tabValue: 0,
    isTab1Hidden: false,
    isTab2Hidden: true,

    //Validation
    showFirstNameValidationErrorMessage: false,
    showLastNameValidationErrorMessage: false,
    showEmailValidationErrorMessage: false,
    showPartnerAssetOwnerIdValidationErrorMessage: false,
    showSubAccountValidationErrorMessage: false,
    storageLocations : [],
    vaultId :''
  });


  useEffect(() => {
    async function fetchData() {
        await getVaults();
       
    }
    fetchData();
}, []);



  // Should be not in state becouse should dinamicly change depending state value
  // Link should be changed to actula RefferalPage URL !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  let invitationText = "Dear Mr. " + values.LastName + "\n\nAs a dear and valuable client of ours, we are happy to inform you that you were found entitled to a loan at preferred terms.  \nThe loan is provided through a 3rd party with whom we have a joint venture, and it is secured solely by your assets deposited in our safe. No physical withdrawal or transfer of your assets used as collateral is required. Furthermore, the loan process and approval is rather fast, completely automated, and highly confidential and secured.  \nYou are invited to get a quote for a loan  (balloon or regular loan) based on the value of the assets deposited in our custody. If you wish to do so, kindly follow the link below  [LINK] \n\nSincerely yours, \nClient Manager  Financial Services";

  const handleChangeForInputs = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  
  const onChangeSelect = (e) => {
    console.log('vaultId e.target.value ' + e.target.value);
    setValues({ ...values, ['vaultId']: e.target.value });
}

  const handleChangeEmailContent = event => {
    invitationText = event.target.value + "\n" + "\n"
      + process.env.REACT_APP_SAFELOAN_API_URL
      + "http://localhost:3000/precioAI/ReferralPage";
  }

  const handleOnFileChange = (e) => {
    setValues({ ...values, ['fileWithInvitations']: e.target.files[0] });
    console.log(values.fileWithInvitations);
  }

  const handleChangeTab = (event, newValue) => {
    if (newValue === 0) {
      setValues({ ...values, ['tabValue']: newValue, ['isTab1Hidden']: false, ['isTab2Hidden']: true });
    }
    if (newValue === 1) {
      setValues({ ...values, ['tabValue']: newValue, ['isTab1Hidden']: true, ['isTab2Hidden']: false });
    }
  };

  function handleClose(variableName) {
    setValues({ ...values, [variableName]: false });
  };

  async function onClickSendEmail() {
    let validationOk = await isValidationOk();

    if (validationOk === true) {
      SendEmail();
    }
  }

   async function getVaults() {
        const URL = process.env.REACT_APP_SAFELOAN_API_URL + `/Vault/getAllVaults`;

        const jwtToken = localStorage.getItem('jwtToken');
        const config = {
            headers: { Authorization: `Bearer ${jwtToken}` }
        };
       

        await axios.get(URL, config)
            .then(res => {
                if (res.data != null) {
                  
                    setValues({
                        ...values, ['storageLocations']: res.data, ['vaultId']: res.data[0].vaultId
                    });
                }
            })
    }

  function isValidationOk() {
    let showFirstNameValidationErrorMessage = false;
    let showLastNameValidationErrorMessage = false;
    let showEmailValidationErrorMessage = false;
    let showPartnerAssetOwnerIdValidationErrorMessage = false;
    let showSubAccountValidationErrorMessage = false;

    if (values.FirstName.length > 0) {
      showFirstNameValidationErrorMessage = false;
    } else {
      showFirstNameValidationErrorMessage = true;
    }

    if (values.LastName.length > 0) {
      showLastNameValidationErrorMessage = false;
    } else {
      showLastNameValidationErrorMessage = true;
    }

    if (values.EmailAddress.length > 0) {
      showEmailValidationErrorMessage = false;
    } else {
      showEmailValidationErrorMessage = true;
    }

    if (values.parterAssetOwnerId.length > 0) {
      showPartnerAssetOwnerIdValidationErrorMessage = false;
    } else {
      showPartnerAssetOwnerIdValidationErrorMessage = true;
    }

    if (values.subAccount.length > 0) {
      showSubAccountValidationErrorMessage = false;
    } else {
      showSubAccountValidationErrorMessage = true;
    }

    setValues({
      ...values,
      ['showFirstNameValidationErrorMessage']: showFirstNameValidationErrorMessage,
      ['showLastNameValidationErrorMessage']: showLastNameValidationErrorMessage,
      ['showEmailValidationErrorMessage']: showEmailValidationErrorMessage,
      ['showPartnerAssetOwnerIdValidationErrorMessage']: showPartnerAssetOwnerIdValidationErrorMessage,
      ['showSubAccountValidationErrorMessage']: showSubAccountValidationErrorMessage
    });

    if (
      showFirstNameValidationErrorMessage === false &&
      showLastNameValidationErrorMessage === false &&
      showEmailValidationErrorMessage === false &&
      showPartnerAssetOwnerIdValidationErrorMessage === false &&
      showSubAccountValidationErrorMessage === false
    ) {
      return true;
    } else {
      return false;
    }
  }


  // REQUESTS TO SERVER
  async function SendEmail() {
    const URL = process.env.REACT_APP_SAFELOAN_API_URL + `/SendMail/SendInvitation`;

    var InvitationDTO = {
      email: values.EmailAddress,
      firstName: values.FirstName,
      lastName: values.LastName,
      invitationText: invitationText,
      partnerAssetOwnerId: values.parterAssetOwnerId,
      subAccount: values.subAccount,
      vaultId :values.vaultId

    }

    async function getVaults() {
      const URL = process.env.REACT_APP_SAFELOAN_API_URL + `/Vault/getVaultLocation`;

      const jwtToken = localStorage.getItem('jwtToken');
      const config = {
          headers: { Authorization: `Bearer ${jwtToken}` }
      };
     

      await axios.get(URL, config)
          .then(res => {
              if (res.data != null) {
                
                  setValues({
                      ...values, ['storageLocations']: res.data, ['vaultId']: res.data[0].vaultId
                  });
              }
          })
  }

 


    const jwtToken = localStorage.getItem('jwtToken');
    const config = {
      headers: { Authorization: `Bearer ${jwtToken}` }
    };

    axios.post(URL, InvitationDTO, config)
      .then((res) => {
        alert("Successfully Sent");
      })
      .catch((error) => {
        console.log("Error !!!!!!! " + error);
        if (error == "Error: Request failed with status code 400") {
          alert("Please, make sure that your username, password and partner id correct");
        }
        else if (
          error == "Error: Request failed with status code 401" ||
          error == "Error: Request failed with status code 403" ||
          error == "Error: Request failed with status code 407" ||
          error == "Error: Request failed with status code 500") {
          alert("Sorry, you are denied");
        }
        else {
          alert("Please make sure your internet connection stable. If yes, write to support team.");
        }
      })
  };


  async function sendFileWithInvitations() {
    const URL = process.env.REACT_APP_SAFELOAN_API_URL + '/SendMail/SendInvitations';

    const formData = new FormData();
    formData.append('file', values.fileWithInvitations);

    const jwtToken = localStorage.getItem('jwtToken');
    const config = {
      headers: { Authorization: `Bearer ${jwtToken}` }
    };

    axios.post(URL, formData, config)
      .then((res) => {
        alert("Successfully Sent");
      })
      .catch((error) => {
        console.log("Error !!!!!!! " + error);
        alert("Sorry, you are denied");
      })
  }


  // VALIDATION
  const validator = new SimpleReactValidator();


  return (
    <Fragment>
      <PageTitle
        titleHeading="Customer Referral Program"
        titleDescription="Refer clients to get monitize thier assets"
      />

      <ExampleWrapperSimple sectionHeading="Send Invitations">
        <div>
          <Paper square>
            <Tabs
              value={values.tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChangeTab}
              aria-label="disabled tabs example"
            >
              <Tab label="Send Email" />
              <Tab label="Send Mass Email" />
            </Tabs>
          </Paper>
        </div>

        <div hidden={values.isTab1Hidden} style={{ marginTop: "2%" }}>
          <TextField
            variant="outlined"
            label="First name"
            fullWidth
            placeholder="Enter first name"
            onChange={handleChangeForInputs('FirstName')}
            value={values.FirstName}
            type="text"
          />
          <p hidden={!values.showFirstNameValidationErrorMessage} style={styles.validationMessage}>First name cant be empty</p>

          <TextField
            style={{ marginTop: "1%" }}
            variant="outlined"
            label="Last name"
            fullWidth
            placeholder="Enter last name"
            onChange={handleChangeForInputs('LastName')}
            value={values.LastName}
            type="text"
          />
          <p hidden={!values.showLastNameValidationErrorMessage} style={styles.validationMessage}>Last name cant be empty</p>

          <TextField
            style={{ marginTop: "1%" }}
            variant="outlined"
            label="Email address"
            fullWidth
            placeholder="Enter email address"
            onChange={handleChangeForInputs('EmailAddress')}
            value={values.EmailAddress}
            type="email"
          />
          <p hidden={!values.showEmailValidationErrorMessage} style={styles.validationMessage}>Email cant be empty</p>

          <TextField
            style={{ marginTop: "1%" }}
            variant="outlined"
            label="Partner Asset Owner Unique ID"
            fullWidth
            placeholder="Please generate a Patner's uique id"
            onChange={handleChangeForInputs('parterAssetOwnerId')}
            value={values.parterAssetOwnerId}
            type="text"
          />
          <p hidden={!values.showPartnerAssetOwnerIdValidationErrorMessage} style={styles.validationMessage}>Partner Id cant be empty</p>

          <TextField
            style={{ marginTop: "1%" }}
            variant="outlined"
            label="sub account"
            fullWidth
            placeholder="Please choose sub account"
            onChange={handleChangeForInputs('subAccount')}
            value={values.subAccount}
            type="text"
          />
        <p style={{ marginTop: "0.5%", margin: "0", padding: "0" }}>Vault location</p>
                <select className="dropdown" style={styles.vaultLocationSelect} onChange={onChangeSelect}>
                    {values.storageLocations.map(row => (
                        <option value={row.vaultId}>{row.vaultLocation}</option>
                    ))}
                </select>




          <p hidden={!values.showSubAccountValidationErrorMessage} style={styles.validationMessage}>Sub account cant be empty</p>

          <TextField
            style={{ marginTop: "1%" }}
            fullWidth

            id="outlined-multiline-static"
            label="Email content"
            multiline
            rows="20"
            onChange={handleChangeEmailContent}
            value={invitationText}
            variant="outlined"
          />

          <div style={{ textAlign: 'right', marginTop: "1%" }}  >
            <Button onClick={onClickSendEmail}
              color="secondary"
              size="large"
              variant="contained"
            >
              Send email
            </Button>
          </div>
        </div>

        <div hidden={values.isTab2Hidden} style={{ marginTop: "2%" }}>
          <div style={{ float: "right" }}>
            <a href={invitationsExample} download="Example.csv"> Example </a>
            <input type="file" onChange={handleOnFileChange} />
            <Button
              color="secondary"
              size="large"
              variant="contained"
              onClick={sendFileWithInvitations}
            >
              Upload From File
            </Button>
          </div>
          <p style={{ marginTop: "1%" }}>.</p>
        </div>
      </ExampleWrapperSimple>
    </Fragment>
  );
};
export default CustomerReferral;
