import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import jwt_decode from "jwt-decode";

var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon
};

export default [
  {
    label: '',
    content: JSON.parse(
      getSidebarItems(),
      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }
];
function getSidebarItems() {
  let items = [];
  let itemsFromPartnerPortal = getPartnerPortal();
  let itemsFromLenderPortal = getLenderPortal();

  if (itemsFromPartnerPortal.length > 0) {
    items.push(itemsFromPartnerPortal);
  }
  /*
  if (itemsFromLenderPortal.length > 0) {
    items.push(itemsFromLenderPortal);
  }
  */

  if (items.length > 0) {
    return "[" + items + "]";
  } else {
    return "[]";
  }
}

function getPartnerPortal() {
  let items = [];

  const jwtToken = localStorage.getItem("jwtToken");

  if (jwtToken != null && jwtToken != undefined) {
    const decodedToken = jwt_decode(jwtToken);
    const decodedTokenAsString = JSON.stringify(decodedToken);


    items.push(`
     {
       "label": "Minting Dashboard",
       "description": "Minting Management",
       "to": "/MintingManagement"
     }`
      )


      items.push(`
     {
       "label": "Fraud Dashboard",
       "description": "Fraud Dashboard",
       "to": "/FraudTable"
     }`
      )
      

/*
    if (decodedTokenAsString.includes("CREATE_MINT_PAGE_PERMISSION")) {
      items.push(`
     {
       "label": "Physical inventory",
       "description": "Physical inventory",
       "to": "/MintingAsset"
     }`
      )
    }

    if (decodedTokenAsString.includes("MINT_MANAGEMENT_PAGE_PERMISSION")) {
      items.push(`
     {
       "label": "Minting Dashboard",
       "description": "Minting Management",
       "to": "/MintingManagement"
     }`
      )
    }

    if (decodedTokenAsString.includes("USER_MANGEMENT_PAGE_PERMISSION")) {
      items.push(
        `{
          "label": "User Management",
          "description": "User Management",
          "to": "/UserManagement"   
        }`
      )
    }
    if (decodedTokenAsString.includes("REFFERAL_NEW_CUSTOMER_PAGE_PERMISSION")) {
      items.push(
        `{
          "label": "Refferal new customer",
          "description": "Customer Referral Program",
          "to": "/CustomerReferral"
        }`
      )
    }
    if (decodedTokenAsString.includes("COLLATERAL_MANAGEMENT_PAGE_PERMISSION")) {
      items.push(
        `{
          "label": "Logistics Management",
          "description": "Logistics Management",
          "to": "/CollateralManagement"
        }`
      )
    }
    */
  }

  if (items.length > 0) {
    return `{
      "label": "Partner Portal",
      "icon": "DashboardTwoToneIcon",
      "content": [` + items + `]}`;
  } else {
    return "";
  }
}

function getLenderPortal() {
  let items = [];

  const jwtToken = localStorage.getItem("jwtToken");

  if (jwtToken != null && jwtToken != undefined) {
    const decodedToken = jwt_decode(jwtToken);
    const decodedTokenAsString = JSON.stringify(decodedToken);

    if (decodedTokenAsString.includes("LENDER_PORTAL_PAGE_PERMISSION")) {
      items.push(`
      {
        "label": "Lender Portal",
        "description": "Lender Portal",
        "to": "/LenderPortal"
      }`
      )
    }


  }

  if (items.length > 0) {
    return `{
      "label": "Lender Portal",
      "icon": "SettingsIcon",
      "content": [` + items + `]}`;
  } else {
    return "";
  }
}
