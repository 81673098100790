import React from 'react';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import { Box, Button, Container, TextField, Typography } from '@mui/material';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import {
  ListItemText,
  Checkbox,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import axios from 'axios';
import { useState } from 'react';
import InnerTable from './InnerTable';
import { makeStyles } from '@material-ui/core/styles';
import { EnergySavingsLeafSharp } from '@mui/icons-material';

function createData(sl, name, contract, link) {
  return { sl, name, contract, link };
}

const sectionWrapper = {
  height: '100vh',

  alignItems: 'center',
  justifyContent: 'center',
  '& .MainHeading': {
    textAlign: 'center',
    mb: '32px',
    fontSize: '36px',
    fontWeight: '900'
  },
  '& .OpenseaLinkStyle': {
    '& a': {
      color: '#0b00b7',
      '& svg': { fontSize: '15px', ml: '8px', mt: '-3px' }
    }
  }
};

export default function FraudTable() {
  const [data, setData] = useState(null);
  const [value, setValue] = useState('initialState');
  const [error, setError] = useState('');
  const [nftContracts, setNftContracts] = useState([]);
  const [
    chosenCollectionsIdsDisabled,
    setChosenCollectionsIdsDisabled
  ] = useState(false);
  const [chosenCollectionsIds, setChosenCollectionsIds] = useState([]);
  const [isCollectionStatusOpen, setIsCollectionStatusOpen] = useState(true);

  const jwtToken = localStorage.getItem('jwtToken');
  const config = {
    headers: { Authorization: `Bearer ${jwtToken}` }
  };
  React.useEffect(() => {
    const t = 0;
    async function getData() {
      try {
        const URL_COLLECTIONS =
          process.env.REACT_APP_SAFELOAN_API_URL +
          '/PartnerAdmin/getAllCollections';
        const nftCollections = (await axios.get(URL_COLLECTIONS, config)).data;
        setNftContracts(nftCollections);
        const _chosenCollectionsIds = [];
        for (let i = 0; i < nftCollections.length; i++) {
          _chosenCollectionsIds.push(nftCollections[i].id);
        }
        setChosenCollectionsIds(_chosenCollectionsIds);
        await getFakeNfts('ALL');
      } catch (error) {
        console.log(error);
      }
    }
    getData();
  }, []);

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    nested: {
      paddingLeft: theme.spacing(4)
    }
  }));
  const getFakeNfts = async address => {
    const URL =
      process.env.REACT_APP_SAFELOAN_API_URL +
      `/MintingAsset/GetFakeByContractAddress/${address}`;

    const { data: resData } = await axios.get(URL, config);

    if (resData && resData.length == 0) {
      setError('No Record Found');
      return;
    }
    // else
    //   resData = resData.sort((a,b) =>
    //     Math.abs(a.score-1)-Math.abs(b.score-1));
    // console.log(resData)
    const nftData = resData.sort(
      (a, b) => Math.abs(a.score - 1) - Math.abs(b.score - 1)
    );

    setData(nftData);
  };
  const classes = useStyles();

  // const onChange = (e) =>{
  //     setValue(e.target.value);
  //   setError('')
  // }
  const handleOpenList = value => {
    // let isNftStatusOpen = values.isNftStatusOpen;
    let _isCollectionStatusOpen = isCollectionStatusOpen;

    // if (value === "isNftStatusOpen") {
    //     isNftStatusOpen = !isNftStatusOpen;
    // }
    if (value === 'isCollectionStatusOpen') {
      _isCollectionStatusOpen = !_isCollectionStatusOpen;
    }
    setIsCollectionStatusOpen(_isCollectionStatusOpen);
  };
  const onChangeAllChosenCollectionId = async event => {
    var _chosenCollectionsIds = [];
    var _chosenCollectionsIdsDisabled = false;

    if (event.target.checked) {
      for (let i = 0; i < nftContracts.length; i++) {
        _chosenCollectionsIds.push(nftContracts[i].id);
      }
      _chosenCollectionsIdsDisabled = true;
      await getFakeNfts('ALL');
    } else setData([]);
    setChosenCollectionsIdsDisabled(_chosenCollectionsIdsDisabled);
    setChosenCollectionsIds(_chosenCollectionsIds);
  };
  async function onClickSearch() {
    const URL =
      process.env.REACT_APP_SAFELOAN_API_URL +
      '/MintingAsset/GetFakeByContractAddress/' +
      value;
    const { data: resData } = await axios.get(URL, config);

    if (resData && resData.length == 0) {
      setError('No Record Found');
    }

    setData(resData);
  }

  const onChangeChosenCollections = async (event, value) => {
    if (event.target.checked) {
      var _chosenCollectionsIds = [value];
      setChosenCollectionsIds(_chosenCollectionsIds);
      await getFakeNfts(value);
    } else {
      setChosenCollectionsIds([]);
      setData([]);
    }

    // var removeMod = false;
    // for (let i = 0; i < _chosenCollectionsIds.length; i++) {
    //     if (_chosenCollectionsIds[i] == value) {
    //         _chosenCollectionsIds.splice(i, 1);
    //         removeMod = true;
    //     }
    // }
    // if (removeMod == false) {
    //     _chosenCollectionsIds.push(value);
    // }
    // setChosenCollectionsIds(_chosenCollectionsIds);
  };
  function getStringifiedCollectionIds() {
    return JSON.stringify(chosenCollectionsIds);
  }

  return (
    <>
      <Box component="section" sx={sectionWrapper}>
        <Container>
          <Typography component={'h2'} className="MainHeading">
            Fraud NFT's Table
          </Typography>
          <Box
            className="SearchFormWrapper"
            sx={{
              alignItems: 'stretch',
              '& .MuiFormControl-root': { width: '100%' },
              '& button': {
                ml: '15px',
                padding: '1px 40px 1px',
                fontSize: '18px'
              }
            }}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <List>
                  <ListItem
                    button
                    onClick={() => handleOpenList('isCollectionStatusOpen')}>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="COLLECTION" />
                    {isCollectionStatusOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    in={isCollectionStatusOpen}
                    timeout="auto"
                    unmountOnExit>
                    <List
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: 0
                      }}>
                      <ListItem button className={classes.nested}>
                        <Checkbox
                          onChange={onChangeAllChosenCollectionId}
                          checked={
                            chosenCollectionsIds.length == nftContracts.length
                          }
                        />
                        ALL
                      </ListItem>
                      {nftContracts.map(row => (
                        <ListItem button className={classes.nested}>
                          <Checkbox
                            disabled={chosenCollectionsIdsDisabled}
                            onChange={event =>
                              onChangeChosenCollections(event, row.id)
                            }
                            checked={getStringifiedCollectionIds().includes(
                              row.id
                            )}
                          />
                          {row.description}
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </List>
              </Grid>
              {false && (
                <Grid item xs={3}>
                  <Button
                    color="primary"
                    size="large"
                    variant="outlined"
                    onClick={onClickSearch}>
                    Search
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
          {error && (
            <Typography
              component={'h4'}
              className="SubHeading"
              sx={{
                textAlign: 'center',
                mb: '32px',
                mt: '20px',
                fontSize: '15px',
                fontWeight: '600'
              }}>
              {error}
            </Typography>
          )}
          {data && data.length > 0 && (
            <>
              <Typography
                component={'h4'}
                className="SubHeading"
                sx={{
                  textAlign: 'center',
                  mb: '32px',
                  mt: '20px',
                  fontSize: '15px',
                  fontWeight: '600'
                }}></Typography>
              <InnerTable data={data} />
            </>
          )}
        </Container>
      </Box>
    </>
  );
}
