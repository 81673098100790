import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import axios from "axios";

import {
    Button,
    TextField,
    Grid
} from '@material-ui/core';
import defaultImage from 'assets/images/defaultImage.png';

function getModalStyle() {
    const top = 42;
    const left = 45;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function SimpleModal(props) {
    const classes = useStyles();
    const [values, setValues] = React.useState({
        modalStyle: getModalStyle(),
        open: false,
        // Data from user for create new item
        file: null,
        fileSrc: defaultImage,
        productName: "",
        category: "",
        vendorCompanyName: "",
        description: "",
        price: 0,

        fileIsValid: true,
        fileIsUpdated: true,
        productNameIsValid: true,
        categoryIsValid: true,
        vendorCompanyNameIsValid: true,
        descriptionIsValid: true,
        priceIsValid: true,

        itemSuccessfullyCreated: false,
        itemCreatingFailed: false
    });

    const handleOpen = () => {
        setValues({ ...values, ['open']: true });
    };

    const handleClose = () => {
        setValues({
            ...values,
            ['open']: false,
            ['file']: null,
            ['fileSrc']: defaultImage,
            ['productName']: "",
            ['category']: "",
            ['vendorCompanyName']: "",
            ['description']: "",
            ['price']: 0,
            ['fileIsValid']: true,
            ['fileIsUpdated']: true,
            ['productNameIsValid']: true,
            ['categoryIsValid']: true,
            ['vendorCompanyNameIsValid']: true,
            ['descriptionIsValid']: true,
            ['priceIsValid']: true,
            ['itemSuccessfullyCreated']: false,
            ['itemCreatingFailed']: false
        });
    };

    // For input file
    const hiddenFileInput = React.useRef(null);
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleOnFileChange = (e) => {
        let file = values.file;
        let fileSrc = values.fileSrc;
        let fileIsValid = isFileValid(e.target.files[0]);

        try {
            if (fileIsValid) {
                file = e.target.files[0];
                console.log(file.type);
                fileSrc = URL.createObjectURL(file);
            }
        } catch (err) {
            console.log(err);
        }

        setValues({
            ...values,
            ['file']: file,
            ['fileSrc']: fileSrc,
            ['fileIsValid']: fileIsValid
        });
    }

    const onChange = variableName => event => {
        setValues({ ...values, [variableName]: event.target.value });
    };

    async function onClickCreateItem() {
        let itemSuccessfullyCreated = false;
        let itemCreatingFailed = false;

        if (isAllDataFromUserIsValid()) {
            let itemIsCreated = await createItem();
            console.log("itemIsCreated = " + itemIsCreated);

            if (itemIsCreated) {
                itemSuccessfullyCreated = true;
            } else {
                itemCreatingFailed = true;
            }

            setValues({
                ...values,
                ["itemSuccessfullyCreated"]: itemSuccessfullyCreated,
                ["itemCreatingFailed"]: itemCreatingFailed
            });

            if (itemSuccessfullyCreated) {
                setTimeout(() => {
                    document.location.reload();
                }, 5000);
            }
        }
    }

    function isAllDataFromUserIsValid() {
        let fileIsValid = true;
        let fileIsUpdated = true;
        let productNameIsValid = true;
        let categoryIsValid = true;
        let vendorCompanyNameIsValid = true;
        let descriptionIsValid = true;
        let priceIsValid = true;

        if (!isFileValid(values.file)) {
            fileIsValid = false;
        } else {
            fileIsValid = true;
        }

        if (values.file == null) {
            fileIsUpdated = false;
        } else {
            fileIsUpdated = true;
        }

        if (values.productName.length == 0) {
            productNameIsValid = false;
        } else {
            productNameIsValid = true;
        }

        if (values.category.length == 0) {
            categoryIsValid = false;
        } else {
            categoryIsValid = true;
        }

        if (values.vendorCompanyName.length == 0) {
            vendorCompanyNameIsValid = false;
        } else {
            vendorCompanyNameIsValid = true;
        }

        if (values.description.length == 0) {
            descriptionIsValid = false;
        } else {
            descriptionIsValid = true;
        }

        if (values.price <= 0) {
            priceIsValid = false;
        } else {
            priceIsValid = true;
        }

        setValues({
            ...values,
            ["fileIsValid"]: fileIsValid,
            ["fileIsUpdated"]: fileIsUpdated,
            ["productNameIsValid"]: productNameIsValid,
            ["categoryIsValid"]: categoryIsValid,
            ["vendorCompanyNameIsValid"]: vendorCompanyNameIsValid,
            ["descriptionIsValid"]: descriptionIsValid,
            ["priceIsValid"]: priceIsValid
        });

        if (
            fileIsValid == true &&
            fileIsUpdated == true &&
            productNameIsValid == true &&
            categoryIsValid == true &&
            vendorCompanyNameIsValid == true &&
            descriptionIsValid == true &&
            priceIsValid == true
        ) {
            return true;
        } else {
            return false;
        }
    }

    function isFileValid(file) {
        if (file == null || file.size > 5000000 || !file.type.includes("image")) {
            return false;
        } else {
            return true;
        }
    }

    function isStringValid(string, pattern) {

    }

    // return true if created OR false if not
    async function createItem() {
        const URL = process.env.REACT_APP_SAFELOAN_API_URL + '/Inventory/storeAsset';

        const formData = new FormData();
        formData.append("vaultAccountId", localStorage.getItem("vaultAccountId"));
        formData.append("file", values.file);
        formData.append("productName", values.productName);
        formData.append("category", values.category);
        formData.append("vendorCompanyName", values.vendorCompanyName);
        formData.append("description", values.description);
        formData.append("price", values.price);

        const jwtToken = localStorage.getItem('jwtToken');
        const config = {
            headers: { Authorization: `Bearer ${jwtToken}` }
        };

        const response = await axios.post(URL, formData, config);
        if (response.status == 201) {
            return true;
        }
        if (response.status == 500) {
            return false;
        }
    }

    const body = (
        <div style={values.modalStyle} className={classes.paper}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <img onClick={handleClick} src={values.fileSrc} style={{ maxWidth: "100%", height: "15em", border: "2px solid #555", cursor: 'pointer' }}></img>
                    <input type="file" onChange={handleOnFileChange} style={{ display: 'none' }} ref={hiddenFileInput}></input>
                    <p hidden={values.fileIsUpdated} style={{ color: "red", marginTop: "1%" }}>Please add a picture of your item</p>
                    <p hidden={values.fileIsValid} style={{ color: "red", marginTop: "1%" }}>Make sure this is an IMAGE with size less than 5mb</p>
                </Grid>

                <Grid item xs={6}>
                    <select
                        style={{
                            marginTop: "1%",
                            appearance: "menulist",
                            width: "29%",
                            height: "4.2em",
                            appearance: "menulist",
                            borderRadius: "5px 5px 5px 5px",
                            borderWidth: "1px 1px 1px 1px",
                            borderColor: "#D3D3D3",
                        }}
                        onChange={onChange("category")}>
                        <option value="">Category</option>
                        <option value="Watches">Watches</option>
                        <option value="High_jewelry">High Jewelry</option>
                        <option value="Fine_jewelry">Fine Jewelry</option>
                        <option value="BAGS">Bags</option>
                        <option value="Painting">Painting</option>
                        <option value="Sculpture">Sculpture</option>
                        <option value="Wine">Wine</option>
                    </select>

                    <TextField
                        style={{
                            marginTop: "2%",
                            marginLeft: "2%",
                            width: "69%"
                        }}
                        placeholder="Company Vendor Name"
                        variant="outlined"
                        fullWidth
                        type="text"
                        onChange={onChange("vendorCompanyName")}>
                    </TextField>
                    <p hidden={values.categoryIsValid && values.vendorCompanyNameIsValid} style={{ color: "red", marginTop: "1%" }}>Select please category and write vendor company name</p>

                    <TextField
                        style={{ marginTop: "5%" }}
                        placeholder="Product name"
                        variant="outlined"
                        fullWidth
                        type="text"
                        onChange={onChange("productName")}
                    ></TextField>
                    <p hidden={values.productNameIsValid} style={{ color: "red", marginTop: "1%" }}>Write please product name</p>

                    <TextField
                        id="outlined-number"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{
                            marginTop: "5%",
                            width: "100%"
                        }}
                        fullWidth
                        variant="outlined"
                        placeholder="Starting price"
                        onChange={onChange("price")}
                    ></TextField>
                    <p hidden={values.priceIsValid} style={{ color: "red", marginTop: "1%" }}>Rate please your item. Value should be more than 0</p>
                </Grid>
            </Grid>

            <TextField
                style={{
                    marginTop: "3%",
                    width: "100%"
                }}
                fullWidth
                id="outlined-multiline-static"
                multiline
                rows="10"
                variant="outlined"
                value={values.description}
                placeholder="Provide a detail description of your item"
                onChange={onChange("description")}
            >
            </TextField>
            <p hidden={values.descriptionIsValid} style={{ color: "red", marginTop: "1%" }}>Describe please your item</p>

            <Grid container spacing={1}>
                <Grid item xs={9}>
                    <h3 hidden={!values.itemSuccessfullyCreated} style={{ marginTop: "1%", color: "lightgreen" }}>Item Successfully Created !</h3>
                    <h3 hidden={!values.itemCreatingFailed} style={{ marginTop: "1%", color: "red" }}>Process had been failed. Try again later</h3>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        style={{
                            marginTop: "5%",
                            float: "right"
                        }}
                        onClick={onClickCreateItem}
                    >Create Item</Button>
                </Grid>
            </Grid>
        </div>
    );

    return (
        <div>
            <Button
                color="primary"
                size="large"
                variant="outlined"
                onClick={handleOpen}
            >Add New Item</Button>

            <Modal
                open={values.open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}
