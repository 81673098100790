import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import jwt_decode from 'jwt-decode';

import { ThemeProvider } from '@material-ui/styles';

import { ClimbingBoxLoader } from 'react-spinners';

import MuiTheme from './theme';

// Layout Blueprints

import { LeftSidebar, MinimalLayout } from './layout-blueprints';

import Buttons from './pages/Buttons';
import AssetAllocation from 'pages/AssetAllocation';
import CustomerReferral from './pages/CustomerReferral';
import FinancialSummary from './pages/FinancialSummary';
import UserManagement from 'pages/UserManagement';
import LogIn from 'pages/LogIn';
import ChangePassword from 'pages/ChangePassword';
import LenderPortal from './pages/LenderPortal';
import CastodianDashboard from './pages/CastodianDashboard';
import CollateralManagement from 'pages/CollateralManagement';
import MintingAsset from 'pages/MintingAsset';
import ListAsset from 'pages/ListAsset';
import MintingConfirmation from 'pages/MintingConfirmation';
import MintingManagement from 'pages/MintingManagement';
import MintingSubmit from 'pages/MintingSubmit';
import FraudTable from 'pages/FraudTable';


const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={'#5383ff'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Please wait while we load the page
          </div>
        </div>
      </Fragment>
    );
  };

  function getStartingPafe() {
    const jwtToken = localStorage.getItem('jwtToken');

    const decodedToken = jwt_decode(jwtToken);
    const decodedTokenAsString = JSON.stringify(decodedToken);

    if (decodedTokenAsString.includes('MINT_MANAGEMENT_PAGE_PERMISSION')) {
      return '/MintingManagement';
    } else if (
      decodedTokenAsString.includes('USER_MANGEMENT_PAGE_PERMISSION')
    ) {
      return '/UserManagement';
    } else if (
      decodedTokenAsString.includes('FINANCIAL_ACTIVITIES_PAGE_PERMISSION')
    ) {
      return '/FinancialSummary';
    } else if (
      decodedTokenAsString.includes('REFFERAL_NEW_CUSTOMER_PAGE_PERMISSION')
    ) {
      return '/CustomerReferral';
    } else if (
      decodedTokenAsString.includes('ASSET_ALLOCATION_PAGE_PERMISSION')
    ) {
      return '/AssetAllocation';
    } else if (decodedTokenAsString.includes('LENDER_PORTAL_PAGE_PERMISSION')) {
      return '/LenderPortal';
    } else if (
      decodedTokenAsString.includes('CASTODIAN_DASHBOARD_PAGE_PERMISSION')
    ) {
      return '/CastodianDashboard';
    } else if (
      decodedTokenAsString.includes('COLLATERAL_MANAGEMENT_PAGE_PERMISSION')
    ) {
      return '/CollateralManagement';
    } else if (decodedTokenAsString.includes('CREATE_MINT_PAGE_PERMISSION')) {
      return '/MintingAsset';
    }
  }

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from="/" to="/MintingManagement" />
            <Route path={['/MintingManagement']}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route
                      path="/MintingManagement"
                      component={MintingManagement}
                    />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>

            <Route path={['/LogIn', '/ChangePassword']}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/LogIn" component={LogIn} />
                    <Route path="/ChangePassword" component={ChangePassword} />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>

            <Route
              path={[
                '/Buttons',
                '/CustomerReferral',
                '/FinancialSummary',
                '/AssetAllocation',
                '/UserManagement',
                '/LenderPortal',
                '/CastodianDashboard',
                '/CollateralManagement',
                '/MintingAsset',
                '/ListAsset',
                '/MintingConfirmation',
                '/MintingManagement',
                '/MintingSubmit',
                '/FraudTable'
              ]}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/Buttons" component={Buttons} />
                    <Route
                      path="/CustomerReferral"
                      component={CustomerReferral}
                    />
                    <Route
                      path="/AssetAllocation"
                      component={AssetAllocation}
                    />
                    <Route
                      path="/FinancialSummary"
                      component={FinancialSummary}
                    />
                    <Route path="/UserManagement" component={UserManagement} />
                    <Route path="/LenderPortal" component={LenderPortal} />
                    <Route
                      path="/CastodianDashboard"
                      component={CastodianDashboard}
                    />
                    <Route
                      path="/CollateralManagement"
                      component={CollateralManagement}
                    />
                    <Route path="/MintingAsset" component={MintingAsset} />
                    <Route path="/ListAsset" component={ListAsset} />
                    <Route
                      path="/MintingConfirmation"
                      component={MintingConfirmation}
                    />
                    <Route
                      path="/MintingManagement"
                      component={MintingManagement}
                    />
                    <Route path="/MintingSubmit" component={MintingSubmit} />
                    <Route path="/FraudTable" component={FraudTable} />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
