import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function MySignaturePad(props) {
    const [values, setValues] = React.useState({
        signatureURL: null,
        closeModal: false,
        isSaveClicked: false
    });

    React.useEffect(() => {
        if (props.onChange) {
            props.onChange(values);
        }
    }, [values.signatureURL, values.closeModal])

    let sigCanvas = useRef({})

    function сlear() {
        sigCanvas.current.clear();
        setValues({ ...values, ['signatureURL']: null, ['isSaveClicked']: false });
    }

    function save() {
        setValues({ ...values, ['signatureURL']: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"), ['isSaveClicked']: true });
    }

    function close() {
        setValues({ ...values, ['closeModal']: true });
    }

    return (
        <div style={{ width: "50%", height: "50%" }}>
            <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                    <SignatureCanvas
                        canvasProps={{ width: "500%" , height: "300%", className: 'sigCanvas' }}
                        ref={sigCanvas}
                        backgroundColor='#F4F4F4'
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                    <button onClick={() => { сlear() }}>Clear</button>
                    <button style={{ color: (values.isSaveClicked === false ? 'blue' : 'green') }} onClick={save}>Save</button>
                    <button onClick={close}>Close</button>
                </div>
            </div>
        </div>
    )
}
