import React, { Fragment, useEffect } from 'react';
import { PageTitle } from '../../layout-components';
import { ExampleWrapperSimple } from '../../layout-components';

import { ApexChartsBar } from '../../components';
// import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// import '../FraudTable/';

import {
  TableBody,
  Table,
  TableCell,
  Badge,
  List,
  ListItem,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Divider,
  Tabs,
  Tab,
  Checkbox,
  Grid,
  Modal,
  Tooltip,
  Card
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import { withStyles } from '@material-ui/core/styles';
import pic1 from 'assets/images/mintingPagePresentation/1.png';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Submit from 'components/Submit';

const sectionWrapper = {
  OpenseaLinkStyle: {
    '& a': {
      color: '#0b00b7',
      '& svg': { fontSize: '15px', ml: '8px', mt: '-3px' }
    }
  }
};

// const styles = {
//   p: {
//     textAlign: 'center'
//     //fontSize: "125%"
//   }
// };

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#3D4977',
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

export default function MintingManagement(props) {
  const [values, setValues] = React.useState({
    isNFTsHidden: false,
    isTabHidden: true,
    tabValue: 0,
    isTab1Hidden: true,
    isTab2Hidden: true,
    isTab3Hidden: true,

    tabValueForSort: 2,
    isNftStatusOpen: false,
    isCollectionStatusOpen: false,
    nftCollections: [],
    chosenNftStatusesIds:
      props.location.state == null
        ? []
        : props.location.state.chosenNftStatusesIds,
    chosenCollectionsIds:
      props.location.state == null
        ? []
        : props.location.state.chosenCollectionsIds,
    nftStatuses: [],
    chosenCollectionsIdsDisabled:
      props.location.state == null
        ? true
        : props.location.state.chosenCollectionsIdsDisabled,
    chosenNftStatusIdsDisabled:
      props.location.state == null
        ? true
        : props.location.state.chosenNftStatusIdsDisabled,
    NFTs: [],
    NftDetails: null,

    isOpenSubmitModal: false,
    dataFromRowFunction: { collaterals: [] },

    // For Submit component
    relevantEntityId: 'Hardcoded',
    collateralMovementId: 'Hardcoded',
    physicalNftsQuantity: 0,
    pureDigitalNftsQuantity: 0,

    //Pagination
    page: 0,
    rowsPerPage: 10,
    //Sorting
    lastSortingVariableName: ''
  });

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    // const classes = styles();

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          {false && (
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          )}
          <TableCell align="left">
            <p>
              {' '}
              {row.productName}{' '}
              <p hidden={row.imageBlockChainUrl == null}>
                {' '}
                <img src={row.imageBlockChainUrl} width="60" height="60" />
              </p>{' '}
            </p>{' '}
          </TableCell>
          <TableCell align="left" sx={sectionWrapper}>
            <a href={row.openseaURL} target="_blank">
              {row.contractName}
            </a>
            <OpenInNewIcon
              sx={{
                fontSize: '15px',
                mt: '-2px',
                ml: '3px',
                color: '#0b00b7'
              }}
            />
          </TableCell>
          {/*           
          <TableCell align="left">
            {new Date(row.createdDate).toLocaleDateString('en-US')}{' '}
            {new Date(row.createdDate).toLocaleTimeString('en-US')}
          </TableCell> */}

          <TableCell align="left">
            <a href={row.tokenURI} target="_blank">
              TokenURI
            </a>
            <OpenInNewIcon
              sx={{
                fontSize: '15px',
                mt: '-2px',
                ml: '3px',
                color: '#0b00b7'
              }}
            />
          </TableCell>
          <TableCell align="left">{row.tokenId}</TableCell>
          <TableCell align="left">{row.description}</TableCell>
          {/* <TableCell align="left">
                        <Button
                            hidden={!row.nftType == "PHYSICAL" || row.nftRequestState == "ALLOCATED" || row.nftRequestState == "REDEEMED"}
                            color="secondary"
                            variant="contained"
                            onClick={() => onClickApprovalButton(row)}
                        >Approval Is Required</Button>
                    </TableCell> */}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Detailed
                </Typography>
                <PerfectScrollbar
                  className="scroll-area-sm mb-2"
                  style={{ textAlign: 'left' }}>
                  <p>
                    <b>NFT Currency:</b> {row.nftCurrency}
                  </p>
                  <p>
                    <b>Inventory Id:</b> {row.asset?.itemGuid}
                  </p>
                  <p>
                    <b>Item SerialNumber:</b> {row.asset?.itemSerialNumber}
                  </p>
                  <p>
                    <b>Marketplace:</b> {row.marketplaceId}
                  </p>
                  <p>
                    <b>Contract:</b> {row.contractAddress}
                  </p>
                  <p>
                    <b>Token:</b> {row.tokenURI}
                  </p>
                  <p hidden={row.marketPlaceURL == null}>
                    <b>Link to NFT:</b>{' '}
                    <a
                      style={{ color: 'blue' }}
                      href={row.marketPlaceURL}
                      target="_blank">
                      <img
                        src={row.imageBlockChainUrl}
                        width="60"
                        height="60"
                      />
                    </a>
                  </p>
                  <p>
                    <b>Listing method:</b> {row.listingMethod}
                  </p>
                  <p>
                    <b>price:</b> {row.itemPrice}
                  </p>
                  <p>
                    <b>Duration in days:</b> {row.period}
                  </p>
                  <p>
                    <b>Expiration:</b> {row.endingDatePeriod}
                  </p>
                </PerfectScrollbar>
              </Box>
            </Collapse>
          </TableCell>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                <Typography variant="h6" gutterBottom component="div">
                  Transaction History (Coming soon)
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  function onClickApprovalButton(row) {
    setValues({
      ...values,
      ['isOpenSubmitModal']: true,
      ['relevantEntityId']: row.id
    });
  }

  const handleChangeTabForSort = (event, newValue) => {
    console.log('handleChangeTabForSort' + newValue);
    setValues({ ...values, ['tabValueForSort']: newValue });
  };

  function onClickBack() {
    setValues({
      ...values,
      ['isTabHidden']: true,
      ['isTab1Hidden']: true,
      ['isTab2Hidden']: true,
      ['isTab3Hidden']: true,
      ['isNFTsHidden']: false,
      ['tabValue']: 0
    });
  }

  React.useEffect(() => {
    const setup = async () => {
      const jwtToken = localStorage.getItem('jwtToken');
      const config = {
        headers: { Authorization: `Bearer ${jwtToken}` }
      };

      const URL =
        process.env.REACT_APP_SAFELOAN_API_URL +
        '/PartnerAdmin/getAllCollections';
      const nftCollections = (await axios.get(URL, config)).data;

      const URL2 =
        process.env.REACT_APP_SAFELOAN_API_URL + '/MintingAsset/getNFTStatuses';
      const nftStatuses = (await axios.get(URL2, config)).data;

      const URL3 =
        process.env.REACT_APP_SAFELOAN_API_URL +
        '/MintingAsset/getNftsQuantity/PHYSICAL';
      const physicalNftsQuantity = await (await axios.get(URL3, config)).data;

      const URL4 =
        process.env.REACT_APP_SAFELOAN_API_URL +
        '/MintingAsset/getNftsQuantity/PURE_DIGITAL';
      const pureDigitalNftsQuantity = await (await axios.get(URL4, config))
        .data;

      let nftCollectionsIds = [];
      for (let i = 0; i < nftCollections.length; i++) {
        nftCollectionsIds.push(nftCollections[i].id);
      }

      let nftStatusesIds = [];
      for (let i = 0; i < nftStatuses.length; i++) {
        nftStatusesIds.push(nftStatuses[i].id);
      }

      var nftType = 'PURE_DIGITAL';
      if (values.tabValueForSort == 0) {
        nftType = 'PHYSICAL';
      } else if (values.tabValueForSort == 2) {
        nftType = 'PURE_DIGITAL';
      } else {
        alert('error');
      }

      const URL5 =
        process.env.REACT_APP_SAFELOAN_API_URL +
        '/MintingAsset/getFilteredNFTs';
      const formData = new FormData();
      formData.append('nftType', nftType);
      if (props.location.state == null) {
        formData.append('chosenNftStatusesIds', nftStatusesIds);
        formData.append('chosenCollectionsIds', nftCollectionsIds);
      } else {
        nftStatusesIds = values.chosenNftStatusesIds;
        nftCollectionsIds = values.chosenCollectionsIds;
        formData.append('chosenNftStatusesIds', values.chosenNftStatusesIds);
        formData.append('chosenCollectionsIds', values.chosenCollectionsIds);
      }
      const NFTsRes = await (await axios.post(URL5, formData, config)).data;
      const NFTs = NFTsRes.map(n => {
        if (!n.imageBlockChainUrl.startsWith('data:image/svg+xml;utf8,'))
          return n;
        const urls = n.imageBlockChainUrl.split('data:image/svg+xml;utf8,');
        const base64Content = btoa(encodeURIComponent(urls[1]));
        n.imageBlockChainUrl = `data:image/svg+xml;base64,${base64Content}`;
        return n;
      });

      setValues({
        ...values,
        ['nftCollections']: nftCollections,
        ['nftStatuses']: nftStatuses,
        ['physicalNftsQuantity']: physicalNftsQuantity,
        ['pureDigitalNftsQuantity']: pureDigitalNftsQuantity,
        ['chosenCollectionsIds']: nftCollectionsIds,
        ['chosenNftStatusesIds']: nftStatusesIds,
        ['NFTs']: NFTs
      });
    };

    setup();
  }, []);

  function onClickMoreInformation(nftRequestId) {
    const URL =
      process.env.REACT_APP_SAFELOAN_API_URL +
      '/MintingAsset/getNftDetails/' +
      nftRequestId;

    const jwtToken = localStorage.getItem('jwtToken');
    const config = {
      headers: { Authorization: `Bearer ${jwtToken}` }
    };

    axios
      .get(URL, config)
      .then(res => {
        if (res.data != null) {
          setValues({
            ...values,
            ['NftDetails']: res.data,
            ['isTabHidden']: false,
            ['isTab1Hidden']: false,
            ['isTab2Hidden']: true,
            ['isTab3Hidden']: true,
            ['isNFTsHidden']: true,
            ['tabValue']: 0
          });
        }
      })
      .catch(function(error) {
        console.log('Error !!!!!!! ' + error);
        if (
          error == 'Error: Request failed with status code 400' ||
          error == 'Error: Request failed with status code 401' ||
          error == 'Error: Request failed with status code 403' ||
          error == 'Error: Request failed with status code 407' ||
          error == 'Error: Request failed with status code 500'
        ) {
          alert('Something wet wrong.');
        } else {
          alert(
            'Please make sure your internet connection stable. If yes, write to support team.'
          );
        }
      });
  }

  function onClickSearch() {
    const URL =
      process.env.REACT_APP_SAFELOAN_API_URL + '/MintingAsset/getFilteredNFTs';

    var nftType = 'PHYSICAL';
    console.log(
      'onClickSearch values.tabValueForSort' + values.tabValueForSort
    );
    if (values.tabValueForSort == 0) {
      nftType = 'PHYSICAL';
    } else if (values.tabValueForSort == 2) {
      nftType = 'PURE_DIGITAL';
    } else {
      alert('error');
    }
    const formData = new FormData();
    formData.append('nftType', nftType);
    console.log(
      'onClickSearch: ' + JSON.stringify(values.chosenNftStatusesIds)
    );
    console.log('nftType: ' + nftType);
    formData.append('chosenNftStatusesIds', values.chosenNftStatusesIds);
    formData.append('chosenCollectionsIds', values.chosenCollectionsIds);

    const jwtToken = localStorage.getItem('jwtToken');
    const config = {
      headers: { Authorization: `Bearer ${jwtToken}` }
    };

    axios
      .post(URL, formData, config)
      .then(res => {
        setValues({ ...values, ['NFTs']: res.data });
      })
      .catch(function(error) {
        console.log('Error !!!!!!! ' + error);

        if (
          error == 'Error: Request failed with status code 400' ||
          error == 'Error: Request failed with status code 401' ||
          error == 'Error: Request failed with status code 403' ||
          error == 'Error: Request failed with status code 407' ||
          error == 'Error: Request failed with status code 500'
        ) {
          alert('Something wet wrong.');
        } else {
          alert(
            'Please make sure your internet connection stable. If yes, write to support team.'
          );
        }
      });
  }

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    nested: {
      paddingLeft: theme.spacing(4)
    }
  }));

  const classes = useStyles();

  const handleOpenList = value => {
    let isNftStatusOpen = values.isNftStatusOpen;
    let isCollectionStatusOpen = values.isCollectionStatusOpen;

    if (value === 'isNftStatusOpen') {
      isNftStatusOpen = !isNftStatusOpen;
    }
    if (value === 'isCollectionStatusOpen') {
      isCollectionStatusOpen = !isCollectionStatusOpen;
    }

    setValues({
      ...values,
      ['isNftStatusOpen']: isNftStatusOpen,
      ['isCollectionStatusOpen']: isCollectionStatusOpen
    });
  };

  let history = useHistory();

  const onChangeChosenNftStatuses = value => event => {
    console.log(
      'onChangeChosenNftStatuses: ' +
        JSON.stringify(values.chosenNftStatusesIds)
    );
    var data = values.chosenNftStatusesIds;

    var removeMod = false;
    for (let i = 0; i < data.length; i++) {
      if (data[i] == value) {
        data.splice(i, 1);
        removeMod = true;
      }
    }
    if (!removeMod) {
      data.push(value);
    }

    console.log('onChangeChosenNftStatuses : ' + value);
    console.log('onChangeChosenNftStatuses: ' + JSON.stringify(data));

    setValues({ ...values, ['chosenNftStatusesIds']: data });
  };

  const onChangeChosenCollections = value => event => {
    var chosenCollectionsIds = values.chosenCollectionsIds;

    var removeMod = false;
    for (let i = 0; i < chosenCollectionsIds.length; i++) {
      if (chosenCollectionsIds[i] == value) {
        chosenCollectionsIds.splice(i, 1);
        removeMod = true;
      }
    }
    if (removeMod == false) {
      chosenCollectionsIds.push(value);
    }

    setValues({ ...values, ['chosenCollectionsIds']: chosenCollectionsIds });
  };

  function getStringifiedNftStatuses() {
    let data = JSON.stringify(values.chosenNftStatusesIds);
    return data;
  }

  function getStringifiedCollectionIds() {
    return JSON.stringify(values.chosenCollectionsIds);
  }

  const onChangeAllChosenCollectionId = event => {
    var chosenCollectionsIds = [];
    var chosenCollectionsIdsDisabled = false;

    if (event.target.checked) {
      for (let i = 0; i < values.nftCollections.length; i++) {
        chosenCollectionsIds.push(values.nftCollections[i].id);
      }
      chosenCollectionsIdsDisabled = true;
    }

    setValues({
      ...values,
      ['chosenCollectionsIds']: chosenCollectionsIds,
      ['chosenCollectionsIdsDisabled']: chosenCollectionsIdsDisabled
    });
  };
  const onChangeAllChosenNftStatusId = event => {
    var data = [];
    console.log(
      'onChangeAllChosenNftStatusId: values.nftStatuses ' +
        JSON.stringify(values.nftStatuses)
    );
    var chosenNftStatusIdsDisabled = false;

    if (event.target.checked) {
      for (let i = 0; i < values.nftStatuses.length; i++) {
        data.push(values.nftStatuses[i].id);
      }
      chosenNftStatusIdsDisabled = true;
    }
    console.log('onChangeAllChosenNftStatusId: ' + JSON.stringify(data));
    setValues({
      ...values,
      ['chosenNftStatusesIds']: data,
      ['chosenNftStatusIdsDisabled']: chosenNftStatusIdsDisabled
    });
  };

  const handleChangePage = (event, newPage) => {
    setValues({ ...values, ['page']: newPage });
  };

  const handleChangeRowsPerPage = event => {
    setValues({
      ...values,
      ['rowsPerPage']: parseInt(event.target.value, 10),
      ['page']: 0
    });
  };

  function sortNfts(variableName) {
    const NFTs = values.NFTs;
    var lastSortingVariableName = values.lastSortingVariableName;
    const isAscSorting = values.lastSortingVariableName != variableName;
    const isBigInt = variableName == 'tokenId';
    NFTs.sort(function(a, b) {
      if (isBigInt)
        return isAscSorting
          ? Number(BigInt(a[variableName]) - BigInt(b[variableName]))
          : Number(BigInt(b[variableName]) - BigInt(a[variableName]));

      let aString = a[variableName];
      let bString = b[variableName];

      return isAscSorting
        ? aString.localeCompare(bString)
        : bString.localeCompare(aString);
    });

    setValues({
      ...values,
      ['NFTs']: NFTs,
      ['lastSortingVariableName']: isAscSorting ? variableName : ''
    });
  }

  return (
    <div>
      <PageTitle titleHeading="NFT Inventory" titleDescription="" />

   

      <div style={{ marginBottom: '5%' }}>
        {/* <Tabs
                    value={values.tabValueForSort}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChangeTabForSort}
                    aria-label="disabled tabs example"
                >
                      <Tab label="MY DIGITAL NFT" /> */}
        <span className="badge badge-primary">
          overall found nft : {values.pureDigitalNftsQuantity}
        </span>
        {/* <Tab label="MY PHYSICAL LINKED NFT" />
                    <span className="badge badge-primary">{values.physicalNftsQuantity}</span>
                   */}
        {/* </Tabs>  */}

        <Grid container spacing={4}>
          <Grid item xs={3}>
            <List>
              <ListItem
                button
                onClick={() => handleOpenList('isNftStatusOpen')}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="NFT STATE" />
                {values.isNftStatusOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={values.isNftStatusOpen}
                timeout="auto"
                unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested}>
                    <Checkbox
                      onChange={onChangeAllChosenNftStatusId}
                      checked={
                        values.chosenNftStatusesIds.length ==
                        values.nftStatuses.length
                      }
                    />
                    ALL
                  </ListItem>
                  <TableBody>
                    {values.nftStatuses.map(row => (
                      <ListItem button className={classes.nested}>
                        <Checkbox
                          disabled={values.chosenNftStatusIdsDisabled}
                          onChange={onChangeChosenNftStatuses(row.id)}
                          checked={getStringifiedNftStatuses().includes(row.id)}
                        />
                        {row.description}
                      </ListItem>
                    ))}
                  </TableBody>
                </List>
              </Collapse>
            </List>
          </Grid>

          <Grid item xs={3}>
            <List>
              <ListItem
                button
                onClick={() => handleOpenList('isCollectionStatusOpen')}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="COLLECTION" />
                {values.isCollectionStatusOpen ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItem>
              <Collapse
                in={values.isCollectionStatusOpen}
                timeout="auto"
                unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested}>
                    <Checkbox
                      onChange={onChangeAllChosenCollectionId}
                      checked={
                        values.chosenCollectionsIds.length ==
                        values.nftCollections.length
                      }
                    />
                    ALL
                  </ListItem>
                  {values.nftCollections.map(row => (
                    <ListItem button className={classes.nested}>
                      <Checkbox
                        disabled={values.chosenCollectionsIdsDisabled}
                        onChange={onChangeChosenCollections(row.id)}
                        checked={getStringifiedCollectionIds().includes(row.id)}
                      />
                      {row.description}
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </List>
          </Grid>

          <Grid item xs={3}>
            <Button
              color="primary"
              size="large"
              variant="outlined"
              onClick={onClickSearch}>
              Search
            </Button>
          </Grid>

          <Grid item xs={3}>
            <Button
              hidden={values.tabValueForSort == 0 ? false : true}
              color="secondary"
              size="large"
              variant="outlined"
              onClick={() => history.push('/MintingAsset')}>
              Create Physical NFT
            </Button>

            <Button
              hidden={values.tabValueForSort == 0 ? true : false}
              color="secondary"
              size="large"
              variant="outlined"
              onClick={() => history.push('/ListAsset')}>
              Mint NFT
            </Button>
          </Grid>
        </Grid>
      </div>

      <div>
        <ExampleWrapperSimple
          sectionHeading={
            values.isNFTsHidden === false
              ? 'NFTs search results'
              : 'No NFTs Founds'
          }>
          <Fragment>
            <TableContainer className="mb-4" component={Paper}>
              <Table
                aria-label="collapsible table"
                hidden={values.isNFTsHidden}>
                <TableHead>
                  <TableRow color="secondary">
                    {false && (
                      <StyledTableCell align="left">
                        <TableSortLabel
                          style={{ color: 'white' }}></TableSortLabel>
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="left">Nft</StyledTableCell>

                    <StyledTableCell align="left">
                      <TableSortLabel
                        direction={
                          values.lastSortingVariableName == '' ||
                          values.lastSortingVariableName != 'contractName'
                            ? 'asc'
                            : 'desc'
                        }
                        style={{ color: 'white' }}
                        onClick={() => sortNfts('contractName')}>
                        Collection
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell align="left">TokenURI</StyledTableCell>

                    <StyledTableCell align="left">
                      <TableSortLabel
                        direction={
                          values.lastSortingVariableName == '' ||
                          values.lastSortingVariableName != 'tokenId'
                            ? 'asc'
                            : 'desc'
                        }
                        style={{ color: 'white' }}
                        onClick={() => sortNfts('tokenId')}>
                        TokenID
                      </TableSortLabel>
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <TableSortLabel style={{ color: 'white' }}>
                        Description
                      </TableSortLabel>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(values.rowsPerPage > 0
                    ? values.NFTs.slice(
                        values.page * values.rowsPerPage,
                        values.page * values.rowsPerPage + values.rowsPerPage
                      )
                    : values.NFTs
                  ).map(row => (
                    <Row key={row.id} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={values.NFTs.length}
              page={values.page}
              onPageChange={handleChangePage}
              rowsPerPage={values.rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Fragment>
        </ExampleWrapperSimple>
      </div>

      <Modal
        open={values.isOpenSubmitModal}
        onClose={() => setValues({ ...values, ['isOpenSubmitModal']: false })}
        style={{
          //position: "absolute",
          height: '75%',
          width: 'auto',
          left: '20%',
          right: '20%',
          bottom: '5%'
        }}>
        <Submit
          relevantEntityId={values.relevantEntityId}
          collateralMovementState="ALLOCATED"
          chosenNftStatusesIds={values.chosenNftStatusesIds}
          chosenCollectionsIds={values.chosenCollectionsIds}
          chosenCollectionsIdsDisabled={
            values.chosenCollectionsIds.length == values.nftCollections.length
              ? true
              : false
          }
          chosenNftStatusIdsDisabled={
            values.chosenNftStatusesIds.length == values.nftStatuses.length
              ? true
              : false
          }
          fromMintingManagement={true}
        />
      </Modal>
    </div>
  );
}
