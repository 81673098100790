import React from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import jwt_decode from 'jwt-decode';

import PrecioLogoDark from 'assets/images/logoCyberw3.png';
import { auth } from '../../config/firebaseConfig';

import {
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  onAuthStateChanged
} from 'firebase/auth';

import { Grid, Button } from '@material-ui/core';

export default function LogIn() {
  const provider = new GoogleAuthProvider();

  const [values, setValues] = React.useState({
    username: '',
    password: '',
    partnerId: '',

    // Validation
    showUsernameValidationErrorMessage: false,
    showPasswordValidationErrorMessage: false,
    showPartnerIdValidationErrorMessage: false,

    passwordHidden: true,
    partnerIdHidden: true
  });

  const styles = {
    label: {
      fontSize: '150%'
    },
    input: {
      width: '50%',
      float: 'right'
    },
    validationMessage: {
      color: 'red',
      textAlign: 'right'
    }
  };
  const signInWithGoogle = async () => {
    console.log('auth', auth);
    try {
      await signInWithPopup(auth, provider);

      // Print token to test the middlewares later on via HTTP client
      // console.log(await auth.currentUser.getIdToken(true));

      const { claims } = await auth.currentUser.getIdTokenResult(true);
      if (claims.role === 'admin') history.push('/admin');
    } catch (error) {
      console.log(error);
    }
  };
  onAuthStateChanged(auth, user => {
    if (user) {
      localStorage.setItem('jwtToken', user.accessToken);
      history.push('/MintingManagement');
      history.go('/MintingManagement');
      // localStorage.setItem('partnerId', res.data.partnerId);
      // User is signed in
      // Call your service or perform other actions here
    } else {
      localStorage.clear('jwtToken');
      // User is signed out
      // Redirect to login page or handle authentication flow
    }
  });
  const signInWithEmail = async () => {
    try {
      const email = values.username;
      const password = values.password;
      await signInWithEmailAndPassword(auth, email, password);

      // Print token to test the middlewares later on via HTTP client
      // console.log(await auth.currentUser.getIdToken(true));

      const { claims } = await auth.currentUser.getIdTokenResult(true);

      if (!claims.email_verified) {
        // Change the redirect URL to env var if you want
        await sendEmailVerification(auth.currentUser, {
          url: 'http://localhost:3000'
        });
        setMsg(
          'Please verify your email before signing in. We have sent you another verification email'
        );
        await signOut(auth);
        return;
      }

      console.log('Signed in as: ', claims.email);

      //   if (claims.role === 'admin') router.push('/admin');
    } catch (err) {
      console.log('Unexpected error: ', err);
    }
  };
  const onChangeInputs = variableName => event => {
    setValues({ ...values, [variableName]: event.target.value });
  };

  async function onClickSignIn() {
    let validationOk = await isValidationOk();

    if (validationOk === true) {
      sendSignInRequest();
    }
  }

  function isValidationOk() {
    let showUsernameValidationErrorMessage = false;
    let showPasswordValidationErrorMessage = false;
    let showPartnerIdValidationErrorMessage = false;

    if (values.username.length > 0) {
      showUsernameValidationErrorMessage = false;
    } else {
      showUsernameValidationErrorMessage = true;
    }

    if (values.password.length > 0) {
      showPasswordValidationErrorMessage = false;
    } else {
      showPasswordValidationErrorMessage = true;
    }

    if (values.partnerId.length > 0) {
      showPartnerIdValidationErrorMessage = false;
    } else {
      showPartnerIdValidationErrorMessage = true;
    }

    setValues({
      ...values,
      ['showUsernameValidationErrorMessage']: showUsernameValidationErrorMessage,
      ['showPasswordValidationErrorMessage']: showPasswordValidationErrorMessage,
      ['showPartnerIdValidationErrorMessage']: showPartnerIdValidationErrorMessage
    });

    if (
      showUsernameValidationErrorMessage === false &&
      showPasswordValidationErrorMessage === false &&
      showPartnerIdValidationErrorMessage === false
    ) {
      return true;
    } else {
      return false;
    }
  }

  let history = useHistory();

  async function sendSignInRequest() {
    const URL = process.env.REACT_APP_SAFELOAN_API_URL + '/PartnerAdmin/signIn';

    const formData = new FormData();
    formData.append('username', values.username);
    formData.append('password', values.password);
    formData.append('partnerId', values.partnerId);

    axios
      .post(URL, formData)
      .then(res => {
        console.log('Data from SendSignpRequest' + res.data);
        localStorage.setItem('jwtToken', res.data.jwttoken);
        localStorage.setItem('partnerId', res.data.partnerId);

        const jwtToken = localStorage.getItem('jwtToken');

        if (jwtToken != null || jwtToken != undefined) {
          const decodedToken = jwt_decode(jwtToken);
          const decodedTokenAsString = JSON.stringify(decodedToken);

          if (
            decodedTokenAsString.includes('MINT_MANAGEMENT_PAGE_PERMISSION')
          ) {
            history.push('/MintingManagement');
            history.go('/MintingManagement');
          } else if (
            decodedTokenAsString.includes('USER_MANGEMENT_PAGE_PERMISSION')
          ) {
            history.push('/UserManagement');
            history.go('/UserManagement');
          } else if (
            decodedTokenAsString.includes(
              'FINANCIAL_ACTIVITIES_PAGE_PERMISSION'
            )
          ) {
            history.push('/FinancialSummary');
            history.go('/FinancialSummary');
          } else if (
            decodedTokenAsString.includes(
              'REFFERAL_NEW_CUSTOMER_PAGE_PERMISSION'
            )
          ) {
            history.push('/CustomerReferral');
            history.go('/CustomerReferral');
          } else if (
            decodedTokenAsString.includes('ASSET_ALLOCATION_PAGE_PERMISSION')
          ) {
            history.push('/AssetAllocation');
            history.go('/AssetAllocation');
          }
        }
      })
      .catch(function(error) {
        console.log('Error !!!!!!! ' + error);
        if (error == 'Error: Request failed with status code 400') {
          alert(
            'Please, make sure that your username, password and partner id correct'
          );
        } else if (
          error == 'Error: Request failed with status code 401' ||
          error == 'Error: Request failed with status code 403' ||
          error == 'Error: Request failed with status code 407'
        ) {
          alert('Sorry, you are denied');
        } else if (error == 'Error: Network Error') {
          alert('Please make sure your internet connection stable.');
        } else {
          alert(
            'Something wet wrong. Please try again or write to support team'
          );
          console.log('!!!!!!' + error);
        }
      });
  }

  function onClickEye(variableName) {
    let passwordHidden = values.passwordHidden;

    if (variableName === 'passwordHidden') {
      passwordHidden = !passwordHidden;
    }

    setValues({ ...values, ['passwordHidden']: passwordHidden });
  }

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <img
            style={{ width: '120px', height: '120px' }}
            src={PrecioLogoDark}
          />
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <div></div>
      <Grid container spacing={0}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <div>
            <label style={styles.label}>Username</label>
            <input
              onChange={onChangeInputs('username')}
              style={styles.input}></input>
            <p
              hidden={!values.showUsernameValidationErrorMessage}
              style={styles.validationMessage}>
              Username cant be empty
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <div>
            <label style={styles.label}>Password</label>
            <input
              type={values.passwordHidden ? 'password' : 'text'}
              onChange={onChangeInputs('password')}
              style={styles.input}></input>
            <p
              hidden={!values.showPasswordValidationErrorMessage}
              style={styles.validationMessage}>
              Current Password cant be empty
            </p>
          </div>
        </Grid>
        <Grid item xs={4}>
          <VisibilityIcon
            hidden={!values.passwordHidden}
            style={{ marginLeft: '1%', cursor: 'pointer' }}
            onClick={() => onClickEye('passwordHidden')}
          />
          <VisibilityOffIcon
            hidden={values.passwordHidden}
            style={{ marginLeft: '1%', cursor: 'pointer' }}
            onClick={() => onClickEye('passwordHidden')}
          />
        </Grid>
      </Grid>
   
      <Grid container spacing={0}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Button
            onClick={signInWithEmail}
            style={{ float: 'right', marginTop: '1%' }}
            size="large"
            variant="outlined">
            Sign In
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
