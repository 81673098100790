import React from "react";
import FormsWizardBasic from '../../components/FormsWizard/FormsWizardBasic';
import Submit from "components/Submit";

export default function MintingSubmit(props) {
    const [values, setValues] = React.useState({
        relevantEntityId: localStorage.getItem("relevantEntityId")
    });

    console.log("relevantEntityId = " + values.relevantEntityId);

    return (
        <div>
            <Submit relevantEntityId={values.relevantEntityId} />
            <FormsWizardBasic activeStep={2} />
        </div>
    )
}
