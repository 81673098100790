import React, { Fragment } from 'react';
import clsx from 'clsx';
import { PageTitle } from '../../layout-components';
import { AppBar, Box, Typography, Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ExampleWrapperSimple } from '../../layout-components';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'



import {
  Grid,
  Input,
  Card,
  Button,
  TableCell,
  InputAdornment,
  TextField,
 
  FormControl,
  Checkbox,
  FormHelperText,

  
  CardContent,
  InputLabel,

  FormControlLabel,
  TableRow,
  TableBody,

  TableHead,
  Table,
  MenuItem
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import RegularTables1Example1 from '../../components/RegularTables1/RegularTables1Example1';
import { withStyles } from '@material-ui/core/styles';
import { CheckBox } from '@material-ui/icons';
import { ta } from 'date-fns/locale';



function createData(loanid, date, amount, period, colatteral, castodianLocation, ltv, aiScore, IntrestRate, apply) {
  return { loanid, date, amount, period, colatteral, castodianLocation, ltv, aiScore, IntrestRate, apply };
}

const rows = [
  createData('123456787', '2021-05-10', '$250\,000', 12, 'Gold', 'Lyon Brinks', '55%', 'AA+', '3.6%', true),
  createData('452342455', '2021-05-10', '$150000', 36, 'Gold', 'Frankfurt BG', '65%', 'AA', '4.1%', true),
  createData('342134635', '2021-05-10', '$400\,000', 24, 'Gold', 'Zurich Airport BGS', '75%', 'A', '', false),
  createData('654642375', '2021-05-10', '$100\,000', 60, 'Silver', 'Vienna 2 BGS branch', '75%', 'A', '5.7%', true),
  createData('896342114', '2021-05-11', '$50\,000', 60, 'Gold', 'Vienna 1 BGS branch', '75%', 'A', '', false),
  // createData('896323163', '2021-05-11', '$51\,000', 72, 'Gold', 'Roissy Charles de Gaulle', '75%', 'A', '', false),
  // createData('896323162', '2021-05-11', '$51\,000', 48, 'Gold', 'Roissy Charles de Gaulle', '75%', 'A', '', false),
  // createData('896323132', '2021-05-11', '$51\,000', 36, 'Gold', 'Brussels Airport BGS', '75%', 'A', '', false),

];
const loantype = [
  {
    value: 'Zurich Airport BGS',
    label: 'Zurich Airport BGS'
  },
  {
    value: 'Roissy Charles de Gaulle',
    label: 'Roissy Charles de Gaulle'
  }
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: 200
  }
}));

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '6px',
    '& > div': {
      maxWidth: 40,
      height: '4px',
      borderRadius: '25px',
      width: '100%',
      backgroundColor: '#000'
    }
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: theme.palette.primary[900],
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1
    }
  }
}))(props => <Tab disableRipple {...props} />);

const LenderPortal = () => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const [values, setValues] = React.useState({
    TabValue: 0,
    Period: 12,
    LoanRequest: 250000,
    Platinum: 0,
    Gold: 50,
    Silver: 10,
    LoanType: 'France',
    PlatinumSelected: false,
    GoldSelected: true,
    SilverSelected: true

  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };



  return (
    <Fragment>
      <PageTitle
        titleHeading="Lender Portal"
        titleDescription=""
      />

      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        className="d-flex align-items-center">
        <Container maxWidth="xl">
          <div className="pt-5 pb-4">
            
            <AppBar position="static"  style={{ background: '#2E3B55' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Submit bids">
                <Tab label="Pending requests" />
                <Tab label="Approved" />
                <Tab label="Rejected" />
                <Tab label="Loan managment" />
              </Tabs>
            </AppBar>
          </div>

          <TabPanel value={values.TabValue} index={0}>
          
            <table style={{ width: '90%' }}>
              <tr>
                <td>
                
                  <Dropdown>
                    <Dropdown.Toggle variant="Secondary" id="dropdown-basic" >
                      Collateral Location
                      </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item >Lion</Dropdown.Item>
                      <Dropdown.Item >Vien 1</Dropdown.Item>
                      <Dropdown.Item >Vien 2</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="Secondary" id="dropdown-basic" >
                      Period
                      </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item >12</Dropdown.Item>
                      <Dropdown.Item >24</Dropdown.Item>
                      <Dropdown.Item >36</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </td>
                <td> <div style={{ width: '200px' }}>
                  <FormControl className="">

                    <InputLabel htmlFor="input-with-icon-adornment">
                      From Amount
                                    </InputLabel>
                    <Input
                      fullWidth
                      id="input-with-icon-adornment"
                      startAdornment={
                        <InputAdornment position="start">
                          $
                                        </InputAdornment>
                      }
                    />

                  </FormControl>
                </div></td>
                <td>
                  <div style={{ width: '200px' }}>
                    <FormControl >
                      <InputLabel htmlFor="input-with-icon-adornment">
                        To Amount
                                    </InputLabel>
                      <Input
                        fullWidth
                        id="input-with-icon-adornment"
                        startAdornment={
                          <InputAdornment position="start">
                            $
                                        </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                </td>
                <td></td>
                <td></td>
                <td>
                <Button
                      color="secondary"
                      size="large"
                      variant="contained"
                      className="mb-5">
                      Search Loans
                    </Button>
                </td>
              </tr>
            </table>
            <Card className="mb-4">
              <div className="">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>

                      <TableCell >Loan ID</TableCell>
                      <TableCell >Issue Date</TableCell>
                      <TableCell >Collateral Location</TableCell>
                      <TableCell >Collateral Value</TableCell>
                      <TableCell >Period (months)</TableCell>
                      <TableCell >Collateral </TableCell>

                      <TableCell >LTV</TableCell>
                      <TableCell >Ai Score</TableCell>
                      <TableCell >IntrestRate</TableCell>
                      <TableCell >Apply</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <TableRow >
                        <TableCell >
                          {row.loanid}
                        </TableCell>
                        <TableCell >{row.date}</TableCell>
                        <TableCell >{row.castodianLocation}</TableCell>

                        <TableCell >  {row.amount}</TableCell>
                        <TableCell > {row.period}</TableCell>
                        <TableCell > {row.colatteral}</TableCell>

                        <TableCell >  {row.ltv}</TableCell>
                        <TableCell >  {row.aiScore}</TableCell>

                        <TableCell >

                          <FormControl
                            className={clsx(
                              classes.margin,
                              classes.withoutLabel,
                              classes.textField
                            )}>

                            <Input
                              id="loan-request-weight"
                              value={row.IntrestRate}
                              onChange={handleChange('Period')}
                              endAdornment={
                                <InputAdornment position="end">%</InputAdornment>
                              }
                              aria-describedby="standard-weight-helper-text"
                              inputProps={{
                                'aria-label': 'weight'
                              }}
                            />

                          </FormControl>

                        </TableCell>
                        <TableCell > <Checkbox className="align-self-center mr-3" value={row.apply} /> {row.apply} </TableCell>
                      </TableRow>

                    ))}
                  </TableBody>
                </Table>
              </div>
              <div className="text-black-50 d-block" style={{ textAlign: 'right' }}  >
              <Button
                      color="secondary"
                      size="large"
                      variant="contained"
                      className="mb-5">
                      Submit Loans
                    </Button>
                </div>
            </Card>
          </TabPanel>
          <TabPanel value={values.TabValue} index={1}>



          </TabPanel>
        </Container>
      </Grid>


    </Fragment>
  );
};
export default LenderPortal;
