import React, { Fragment } from 'react';

import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputLabel,
  Checkbox,
  Input

} from '@material-ui/core';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('gold bar', 250, 'Ounce', true, 4.0),
  createData('silver bar', 500, 'Gram', true, 6.0),
  createData('platinum', 22, 'Kilo', true,4.3),
  
];

export default function LivePreviewExample() {
  return (
    <Fragment>
      <TableContainer className="mb-4" component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Eligible assets</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Units </TableCell>
              <TableCell align="right">Use as Collateral</TableCell>
              <TableCell align="right">Collateral amount </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.calories}</TableCell>
                <TableCell align="right">{row.fat}</TableCell>
                <TableCell align="right"> <Checkbox className="align-self-center mr-3" /> {row.carbs}</TableCell>
                <TableCell align="right">
                <Input htmlFor="input-with-icon-adornment">
                {row.protein}
                          </Input>
                  </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    
     
      <TableContainer className="mb-4" component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow >
              <TableCell align="right">Loan request $ </TableCell>
            </TableRow >
            <TableRow >
              <TableCell align="right">Loan Type </TableCell>
            </TableRow >
            <TableRow >
              <TableCell align="right">Monthly Payment </TableCell>
            </TableRow >
            <TableRow >
              <TableCell align="right">** estimate, exact payment will be offered by lender </TableCell>
            </TableRow >
          </TableBody>
          </Table>
          </TableContainer>
    </Fragment>
  );
}
