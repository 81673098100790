import React, { Fragment } from 'react';
import { PageTitle } from '../../layout-components';
import PropTypes from 'prop-types';



import {
  Grid,
  Box,
  Tabs,
  Tab,
  Container,
  MenuItem,
  AppBar,
  Button,
  Typography,
  Card,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import RegularTables1Example1 from '../../components/RegularTables1/RegularTables1Example1';
import { withStyles } from '@material-ui/core/styles';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '6px',
    '& > div': {
      maxWidth: 40,
      height: '4px',
      borderRadius: '25px',
      width: '100%',
      backgroundColor: '#000'
    }
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: theme.palette.primary[900],
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1
    }
  }
}))(props => <Tab disableRipple {...props} />);

function createData(loanid, date, amount, period, colatteral, castodianLocation) {
  return { loanid, date, amount, period, colatteral, castodianLocation };
}

const rows = [
  createData('123456787', '2021-02-01', '$250\,000', 12, 'Gold', 'Lyon Brinks'),
  createData('452342455', '2021-02-10', '$150000', 36, 'Gold', 'Frankfurt BG'),
  createData('342134635', '2021-02-25', '$400\,000', 24, 'Gold', 'Zurich Airport BGS'),
  createData('654642375', '2021-04-03', '$100\,000', 60, 'Silver', 'Vienna 2 BGS branch'),
  createData('896342114', '2021-04-03', '$50\,000', 60, 'Gold', 'Vienna 1 BGS branch'),
  createData('896323163', '2021-04-03', '$51\,000', 72, 'Gold', 'Roissy Charles de Gaulle'),
  createData('896323162', '2021-04-03', '$51\,000', 48, 'Gold', 'Roissy Charles de Gaulle'),
  createData('896323132', '2021-05-01', '$51\,000', 36, 'Gold', 'Brussels Airport BGS'),
  createData('896323322', '2021-05-03', '$51\,000', 36, 'Gold', 'Zurich Airport BGS'),
  createData('896323132', '2021-05-04', '$51\,000', 36, 'Gold', 'Zurich Airport BGS'),
  createData('896323329', '2021-05-05', '$51\,000', 48, 'Gold', 'Roissy Charles de Gaulle')
];

const columns = [
  createData('col1', 'loanid'),
  createData('col2', 'date'),
  createData('col3', 'amount'),
  createData('col4', 'period'),
  createData('col5', 'colatteral'),
  createData('col6', 'castodianFee'),
  createData('col7', 'castodianLocation')

];
const CastodianDashboard = () => {
  const [value, setValue] = React.useState(0);
  const [values, setValues] = React.useState({
    TabValue: 0,
    Period: 12,
    LoanRequest: 250000,
    Platinum: 0,
    Gold: 50,
    Silver: 10,
    LoanType: 'France',
    PlatinumSelected: false,
    GoldSelected: true,
    SilverSelected: true

  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };


  return (
    <Fragment>
      <PageTitle
        titleHeading="Collateral Inventory Portal"
        titleDescription=""
      />
      
  
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        className="d-flex align-items-center">
        <Container maxWidth="xl">
          <div className="pt-5 pb-4">
         
            {/* <StyledTabs
              value={values.TabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}>
              <StyledTab label="Active" />
              <StyledTab label="Finished" />
              <StyledTab label="Total" />
            </StyledTabs> */}
            <AppBar position="static"  style={{ background: '#2E3B55' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Active">
                <Tab label="Pending requests" />
                <Tab label="Finished" />
                <Tab label="Total" />
                
              </Tabs>
            </AppBar>
          </div>
          <TabPanel value={values.TabValue} index={0}>
            <Card className="mb-4">
              <div className="">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>

                      <TableCell >related ID</TableCell>
                      <TableCell >Issue Date</TableCell>
                      <TableCell >Collateral Value</TableCell>
                      <TableCell >Period (months)</TableCell>
                      <TableCell >Collateral </TableCell>
                      <TableCell >Storage Location</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <TableRow >
                        <TableCell >
                          {row.loanid}
                        </TableCell>
                        <TableCell >{row.date}</TableCell>
                        <TableCell >  {row.amount}</TableCell>
                        <TableCell > {row.period}</TableCell>
                        <TableCell > {row.colatteral}</TableCell>
                        <TableCell >  {row.castodianLocation}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Card>
          </TabPanel>
          <TabPanel value={values.TabValue} index={1}>
          </TabPanel>
          <TabPanel value={values.TabValue} index={1}>
          </TabPanel>
          <TabPanel value={values.TabValue} index={2}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>

                  <TableCell align="right">Loan ID</TableCell>
                  <TableCell align="right">Issue Date</TableCell>
                  <TableCell align="right">Loan Amount</TableCell>
                  <TableCell align="right">Period (months)</TableCell>
                  <TableCell align="right">Collateral </TableCell>
                  <TableCell align="right">Castodian Fee</TableCell>
                  <TableCell align="right">Collateral Location</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <TableRow >
                    <TableCell >
                      {row.loanid}
                    </TableCell>
                    <TableCell align="right">{row.date}</TableCell>
                    <TableCell align="right">  {row.amount}</TableCell>
                    <TableCell align="right"> {row.period}</TableCell>
                    <TableCell align="right"> {row.colatteral}</TableCell>
                    <TableCell align="right">  {row.castodianFee}</TableCell>
                    <TableCell align="right">  {row.castodianLocation}</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TabPanel>
        </Container>
      </Grid>
    
    
    </Fragment>
  );
};
export default CastodianDashboard;
